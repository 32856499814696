<template>
  <div>
    <div class="content-body mx-2">
      <div class="component-section no-code">
        <div class="container welcome-page">
          <div class="row row-sm pt-5 justify-content-center align-items-center">
              <div class="col-lg-12">
                  <div class="card rounded-5 pt-5" style="background: none !important;">
                      <div class="card-body p-3 p-lg-3">
                          <div class="row">
                              <div class="text-center col-md-12 ">
                                  <h2>Welcome to <br/>  <br/>
                                    Nityadhan Finance Private Limited <br/> <br/>
                                      <img src="/static/img/logo.png" alt="Logo" />
                                  </h2>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      
      </div>
      
      </div>
    </div>
  </div>
</template>
<style>

.welcome-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 371px;
}
</style>

<script>

export default {
  name: "WelcomePage",
  components: {
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  mounted() {
    this.$storeService.commit("setTitle", "Welcome");
  },
};
</script>
