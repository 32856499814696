<template>
    <div class="content-body">
        <div class="worker_serch_warp">
            <div class="row d-flex justify-content-between">
                <div class="col-md-2 pr-2">
                    <h2 class="content-title">
                        <a @click="$router.go(-1)" style="cursor: pointer"><i class="fas fa-angle-left mr-2"></i><span
                                v-if="!this.id">Add Branch</span> <span v-else>Edit Branch</span> </a>
                    </h2>
                </div>
            </div>
        </div>
        <div class="component-section no-code">
            <div class="row-sm wb-row-gap">
                <Form @submit="save" class="columns column is-multiline is-12" data-vv-scope="formAddBranch"
                    ref="addEditBranch">
                    <div class="col-lg-12">
                        <div class="card rounded-5">
                            <div class="card-body p-3 p-lg-3">
                                <div class="row">
                                    <div class="col-lg-4 mb-4">
                                        <label> Name <span class="text-danger">*</span> </label>
                                        <Field name="name" v-model="branch.name" class="form-control"
                                            rules="required:name" :validateOnInput="true" type="text" placeholder="Name"
                                            autocapitalize="false" />
                                        <ErrorMessage name="name" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> City <span class="text-danger">*</span> </label>
                                        <Field name="city" v-model="branch.city" class="form-control"
                                            rules="required:city" :validateOnInput="true" type="text" placeholder="City"
                                            autocapitalize="false" />
                                        <ErrorMessage name="city" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> Address <span class="text-danger">*</span> </label>
                                        <Field name="address" v-model="branch.address" class="form-control"
                                            rules="required:address" :validateOnInput="true" type="text"
                                            placeholder="Address" autocapitalize="false" />
                                        <ErrorMessage name="address" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4" v-if="hasFullAccess">
                                        <label> Branch Code <span class="text-danger">*</span> </label>
                                        <Field name="branch_prefix" v-model="branch.branch_prefix" class="form-control"
                                            rules="required:branch" :validateOnInput="true" type="text"
                                            placeholder="Branch Code" autocapitalize="false" />
                                        <ErrorMessage name="branch_prefix" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> Status <span class="text-danger">*</span> </label>
                                        <Field v-slot="{ field }" name="status" rules="required:status,true"
                                            :validateOnInput="true" v-model="branch.status">
                                            <Multiselect v-bind="field" ref="status_multiselect" mode="single"
                                                trackBy="title" label="title" valueProp="value"
                                                placeholder="Select Status" class="form-control" v-model="branch.status"
                                                :options="statusOption" :searchable="true" />
                                        </Field>
                                        <ErrorMessage name="status" class="validation-msg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5 mb-3">
                            <div class="col-12 text-center">
                                <button type="button" class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                                    @click="$router.go(-1)">
                                    Cancel
                                </button>
                                <button type="submit" id="save-btn" class="btn btn-brand-01   wb-save-btn">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    name: "AddEditBranch",
    components: {
        Multiselect, Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            id: this.$route.params.id,
            branch: {
                name: "",
                city: "",
                status: "",
                branch_prefix: "",
            },
            statusOption: [{
                "title": "Active", "value": "ACTIVE",
            }, {
                "title": "Inactive", "value": "INACTIVE",
            }]
        };
    },
    computed:{
        hasFullAccess(){
            return this.$storeService.getters.getHasFullAccess;
        }
    },
    mounted() {
        if (this.id) {
            this.getDetail();
        }
    },
    methods: {
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },
        getDetail() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "branch/" + this.id,
                })
                .then((res) => {
                    this.branch = res.info;
                })
                .catch(() => { });
        },
        save() {
            var method = "POST";
            var action = "/branch";
            if (this.id) {
                method = "PUT";
                action = "branch/" + this.id;
            }
            this.$api
                .webRequest({
                    _method: method,
                    _action: action,
                    _body: this.branch,
                    _buttonId: "save-btn",
                    _hide_loader: true,
                })
                .then((res) => {
                    this.emitter.emit("update-branch", res);
                    this.onClearForm("addEditBranch");
                    this.$toast.success(res.message, { position: "top-right" });
                    this.$router.go(-1);
                })
                .catch((e) => {
                    this.$toast.error(e.message, { position: "top-right" });
                });

        },
    },
};
</script>

<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>