import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import api from "./services/WebService";
import "./services/ValidationRules";
import sharedService from "./services/ShareService";
import helperService from "./services/HelperService"
import storeService from "./services/StoreService"
import Notifications from "@kyvg/vue3-notification";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import VueNumeric from '@handcrafted-market/vue3-numeric';
import mitt from 'mitt';
const emitter = mitt();

const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$helperService = helperService;
app.config.globalProperties.$storeService = storeService;
app.config.globalProperties.$api = api;
app.config.globalProperties.$sharedService = sharedService;
app.use(ToastPlugin);
app.use(router);
app.use(Notifications);
app.component("vue-numeric", VueNumeric)

app.mount("#app");