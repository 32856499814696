<template>
    <div>
        <div class="content-body">
            <div class="component-section no-code">
                <div class="row d-flex justify-content-between">
                    <div class="col-md-5 pr-2">
                        <h2 class="content-title">
                            <a @click="$router.go(-1)" style="cursor: pointer"><i
                                    class="fas fa-angle-left mr-2"></i><span>Asset Renewal Details</span> </a>
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="container-fluid">
                        <div class="worker_serch_warp">
                            <div class="row">
                                <div class="col-lg-12 pt-3" v-if="alertLIst.length > 0">
                                    <div class="card rounded-2" style="border-radius: 10px;">
                                        <div style="border-bottom: 1px solid #ccc;" class="card-header pb-2 pt-3">
                                            <h4 class="text-bolder">Alerts</h4>
                                        </div>
                                        <div class="row">
                                            <div class="card-body">
                                                <div class="card">
                                                    <div class="card-body ">
                                                        <div class="row">
                                                            <div class="col-lg-5 mx-auto" v-for="item in alertLIst"
                                                                :key="item.id"
                                                                style="border: 1px solid; margin-bottom: 10px; border-radius: 10px;">
                                                                <div class="row">
                                                                    <div class="col-5  pt-2"
                                                                        v-if="item.type == 'AMC RENEWAL'">
                                                                        <div class="red-box">
                                                                            <span> {{ $helperService.getTitleCase(item.type)
                                                                            }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5  pt-2"
                                                                        v-if="item.type == 'INSURANCE RENEWAL'">
                                                                        <div class="red-box">
                                                                            <span> {{ $helperService.getTitleCase(item.type)
                                                                            }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-7 pt-2">
                                                                        <h5> {{
                                                                            (item.remaining_days)
                                                                        }}
                                                                        </h5>
                                                                    </div>
                                                                    <div class="col-5 pt-2">
                                                                        <h5>Name :</h5>
                                                                    </div>
                                                                    <div class="col-7  pt-2">
                                                                        <h5>{{ item.name }} </h5>
                                                                    </div>
                                                                    <div class="col-5 pt-2">
                                                                        <h5>Purchase Date :</h5>
                                                                    </div>
                                                                    <div class="col-7  pt-2">
                                                                        <h5>{{
                                                                            $helperService.getFormattedDateOnly(item.purchase_date)
                                                                        }} </h5>
                                                                    </div>
                                                                    <div class="col-5 pt-2">
                                                                        <h5>Provider :</h5>
                                                                    </div>
                                                                    <div class="col-7  pt-2">
                                                                        <h5>{{ item.provider }} </h5>
                                                                    </div>
                                                                    <div class="col-5 pt-2">
                                                                        <h5>Amount :</h5>
                                                                    </div>
                                                                    <div class="col-7  pt-2">
                                                                        <h5>{{ $helperService.getFormattedCurrency(item.amount)}} </h5>
                                                                    </div>
                                                                    <div class="col-5 pt-2">
                                                                        <h5>Contact Number :</h5>
                                                                    </div>
                                                                    <div class="col-7  pt-2">
                                                                        <h5>{{ item.contact_no }} </h5>
                                                                    </div>
                                                                    <div class="col-5 pt-2">
                                                                        <h5>Branch :</h5>
                                                                    </div>
                                                                    <div class="col-7  pt-2">
                                                                        <h5>{{ item?.branch?.name }} </h5>
                                                                    </div>
                                                                    <div class="col-5 pt-2">
                                                                        <h5>Renewal Date :</h5>
                                                                    </div>
                                                                    <div class="col-7  pt-2">
                                                                        <h5>{{
                                                                            $helperService.getFormattedDateOnly(item.renewal_date)
                                                                        }} </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 pt-3" v-else>
                                    <div class="card rounded-2" style="border-radius: 10px;">
                                        <div style="border-bottom: 1px solid #ccc;" class="card-header pb-2 pt-3">
                                            <h4 class="text-bolder">Alerts</h4>
                                        </div>
                                        <div class="row">
                                            <div class="card-body">
                                                <div class="card">
                                                    <div class="card-body ">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                 <h5 class="text-center">Sorry, There is no record found.</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

export default {
    name: "AssetAlert",
    components: {

    },

    data() {
        return {
            loader: false,
            alertLIst: [],
            showAlertButton: false,
        };
    },

    mounted() {
        this.$storeService.commit("setTitle", "Asset Management");
        this.getAssetAlert();
    },
    methods: {

        getAssetAlert() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "asset-alert",
                })
                .then((res) => {
                    this.alertLIst = res.list;
                    // this.alertLIst = [];

                })
                .catch(() => { });
        },

        // On Delete AssetManagement 


    },
};
</script>
  
  