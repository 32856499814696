<template>
    <div class="signin-panel pt-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <!-- <div class="m-3 text-center">
              <img src="/static/img/login-logo.png">
            </div> -->
            <div class="modal fade wb-modal-wrapper" id="termAndCondition" tabindex="-1" role="dialog"
            aria-labelledby="termAndCondition" aria-hidden="true" style="pointer-events: none">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div class="modal-content" id="termAndConditionContent">
                    <!-- <a class="close mr-3 mt-3 text-right" @click="closeTermAndConditionModal()" aria-label="Close">
                        <img src="/static/img/close-icon.svg" width="20" />
                    </a> -->
                    <!-- <div class="modal-header justify-content-center" style="border-bottom: 1px solid #ccc;">
                        <h5 class="font-weight-bolder">Repay</h5>
                    </div> -->
                    <div class="modal-body form-style pt-2">
                        <div class="row pt-2">
                            <div class="card card-body rounded-20 p-0 overflow-y-auto " >
                                <div class="signin-sidebar-body">
                                    <h4 class="mb-1" style="border-bottom: 1px solid #cccccc; line-height: 2">Search Loan
                                        Application</h4>
                                    <Form @submit="onSearchLoanApplication" ref="searchLoanApplication" class="signin-form">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="">
                                                        <label> Loan Application No <span class="text-danger">*</span> </label>
                                                        <Field name="loan_application_id" v-model="loan_application_no"
                                                            rules="required:loan application no" class="form-control"
                                                            id="username" :validateOnInput="true" type="text"
                                                            placeholder="Loan Application No" autocapitalize="false" />
                                                    </div>
                                                    <ErrorMessage name="loan_application_id" class="validation-msg" />
                                                </div>
                                            </div>
        
                                            <div class="col-md-2 ">
                                                <div class="form-group d-flex mg-b-0 pt-4">
                                                    <button class="btn btn-brand-01 btn-uppercase flex-fill" type="submit"
                                                        id="search-loan">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
        
                                        </div>
                                    </Form>
                                </div>
                                <div class="signin-sidebar-body " v-if="hasApplicationInfo">
        
                                    <h5 class="mb-1" style="border-bottom: 1px solid #cccccc; line-height: 2">Loan Application
                                        Detail</h5>
                                    <Form @submit="onSubmitAmountPay" ref="onSubmitAmountPay">
                                        <div class="row pt-2">
                                            <div class="col-lg-6 mb-4">
                                                <label> Customer Name </label>
                                                <Field name="customer_name" v-model="application.user.name" class="form-control"
                                                    :validateOnInput="true" :disabled="true" type="text"
                                                    placeholder="Customer Name" autocapitalize="false" />
                                            </div>
                                            <div class="col-lg-6 mb-4">
                                                <label> Loan Amount </label>
                                                <Field name="loan_amount" v-model="application.total_loan_amount"
                                                    class="form-control" :validateOnInput="true" :disabled="true" type="text"
                                                    placeholder="Loan Amount" autocapitalize="false" />
        
                                            </div>
                                            <div class="col-lg-6 mb-4">
                                                <label> Amount Due </label>
                                                <Field name="amount_due" v-model="application.total_due_amount"
                                                    class="form-control" :validateOnInput="true" :disabled="true" type="text"
                                                    placeholder="Amount Due" autocapitalize="false" />
                                            </div>
                                            <div class="col-sm-6 mb-3 pt-2">
                                                <label> Transaction Type <span class="text-danger">*</span> </label>
                                                <div class="">
                                                    <span class="mr-3">
                                                        <input class="" v-model="trans_type" type="radio" value="INSTALLMENT"
                                                            id="installment_amount" style="width: 20px; height: auto" />
                                                        <label class="form-check-label" for="installment_amount">Installment
                                                            Amount </label>
                                                    </span>
                                                    <span>
                                                        <input class="" v-model="trans_type" type="radio"
                                                            value="ADVANCE_PAYMENT" id="advance_amount"
                                                            style="width: 20px; height: auto" />
                                                        <label class="form-check-label" for="advance_amount">Advance Amount
                                                        </label>
                                                    </span>
                                                </div>
        
                                                <ErrorMessage name="gender" class="validation-msg" />
                                            </div>
                                            <!-- <div class="col-lg-6">
                                                <label> UPI/VPA No <span class="text-danger">*</span> </label>
                                                <Field name="upi_no" v-model="upi_no" class="form-control" rules="required:upi"
                                                    :validateOnInput="true" type="text" placeholder="VPA No"
                                                    autocapitalize="false" />
                                                <ErrorMessage name="upi_no" class="validation-msg" />
                                            </div> -->
                                            <div class="col-lg-6 mb-4">
                                                <label> Amount To Be Pay <span class="text-danger">*</span> </label>
                                                <Field name="amount_pay" v-model="payable_amount" class="form-control"
                                                    rules="required:amount|numeric" :validateOnInput="true" type="text"
                                                    placeholder="Amount Pay" autocapitalize="false" />
                                                <ErrorMessage name="amount_pay" class="validation-msg" />
                                            </div>
        
                                            <div class="col-lg-12" v-show="payable_amount !== ''">
                                                <div class="form-group d-flex justify-content-end  mg-b-0 pt-4">
                                                    <button :disabled="hasDisable" @click="handleShowAndHide()"
                                                        class="btn btn-brand-01 btn-uppercase" type="button" id="pay-amount">
                                                        <span class="spinner-border spinner-color mx-1" v-if="loader"
                                                            style="width:1rem !important;height:1rem !important" role="status">
                                                        </span> Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <div class="signin-sidebar-body " v-if="hideForm">
                                    <div class=" d-flex flex-row" style="border-top: 1px solid #cccccc; line-height: 2">
                                        <img src="/static/img/upi.svg" alt="" style="width: 100px !important;">
                                        <h5 class="mb-1 font-weight-bold" style="
                                        padding-top: 37px !important;
                                    ">Pay by any UPI App
                                        </h5>
                                    </div>
                                    <div class="row pt-4">
                                        <div class="col-sm-12 col-lg-12 col-xl-12 pb-3">
                                            <!-- <label> Payment By Using <span class="text-danger">*</span> </label> -->
                                            <button class="btn btn-brand-01 btn-uppercase mx-3"
                                                @click="paymentMethodVPA('PAY_BY_VPA')" id="pay_by_vpa">Pay by any UPI
                                                App</button>
                                            <button class="btn btn-brand-01 btn-uppercase mx-3"
                                                @click="paymentMethodSCAN('PAY_BY_SCAN')" id="pay_by_scan"> Scan qr
                                                code</button>
        
                                        </div>
        
                                        <div class="col-lg-12 mb-4 d-flex flex-column"
                                            v-show="payment_method === 'PAY_BY_VPA' || payment_method === 'PAY_BY_SCAN'">
                                            <h6> Amount Due : <span class="bolder">
                                                    {{ $helperService.getFormattedCurrency(application.total_due_amount)
                                                    }}</span>
                                            </h6>
                                            <h6> Amount Paying : <span class="bolder">
                                                    {{ $helperService.getFormattedCurrency(payable_amount) }}</span> </h6>
                                        </div>
        
                                        <div class="col-sm-12 col-lg-12 col-xl-12" v-show="payment_method === 'PAY_BY_VPA'">
                                            <label> UPI ID <span class="text-danger">*</span> </label>
                                            <Field name="upi_no" v-model="upi_no" class="form-control" rules="required:upi"
                                                :validateOnInput="true" type="text" placeholder="UPI ID"
                                                autocapitalize="false" />
                                            <ErrorMessage name="upi_no" class="validation-msg" />
                                        </div>
                                        <div class="col-xl-6 mx-auto pt-4 text-center"
                                            v-show="payment_method === 'PAY_BY_SCAN' && showQrCode">
                                            <qrcode-vue :value="value" :size="size" level="H" :render-as="renderAs" />
                                            <br>
                                            <span class="bolder"> Qr Code Expire Time : {{ remaining_time }}</span>
                                        </div>
        
                                        <div class="col-lg-12" v-show="payment_method === 'PAY_BY_VPA'">
                                            <div class="form-group d-flex justify-content-end  mg-b-0 pt-4">
                                                <button :disabled="hasDisable" @click="onSubmitAmountPay()"
                                                    class="btn btn-brand-01 btn-uppercase" type="button" id="pay_vpa">
                                                    Pay
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>


                    
                </div>
            </div>
        </div>

        <div class="modal fade wb-modal-wrapper" id="onOpenNotesModel" tabindex="-1" role="dialog"
            aria-labelledby="onOpenNotesModel" aria-hidden="true" style="pointer-events: none">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                <div class="modal-content">
                    <a class="close mr-3 mt-3 text-right" @click="closeNotesModel()" aria-label="Close">
                        <img src="/static/img/close-icon.svg" width="20" />
                    </a>

                    <div class="modal-header justify-content-center">
                        <h5 class="modal-title font-22">
                            <span>Confirmation</span>
                        </h5>
                    </div>

                    <div class="modal-body form-style pb-0 px-lg-5">
                        <div class="row">
                            <div class="col-xl-12 ">
                                <h5>{{ messages }}</h5>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer border-0 justify-content-center mb-3 mx-3">
                        <button id="cancel-btn" @click="closeNotesModel()" type="button"
                            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- signin-sidebar -->
    </div>
    <!-- signin-panel -->
</template>
<style>
.border-all {
    border: 3px solid #000
}

.opacity {
    opacity: 0.3;
    position: relative;
}

.loader {
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: absolute;
    text-indent: -9999em;
    animation: mulShdSpin 1.1s infinite ease;
    transform: translateZ(0);
    top: 50%;
}

@keyframes mulShdSpin {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #000, 1.8em -1.8em 0 0em rgba(0, 255, 255, 0.2), 2.5em 0em 0 0em rgba(0, 32, 91, 0.2), 1.75em 1.75em 0 0em rgba(0, 32, 91, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 32, 91, 0.7), 1.8em -1.8em 0 0em #000, 2.5em 0em 0 0em rgba(0, 32, 91, 0.2), 1.75em 1.75em 0 0em rgba(0, 32, 91, 0.2), 0em 2.5em 0 0em rgba(0, 32, 91, 0.2), -1.8em 1.8em 0 0em rgba(0, 32, 91, 0.2), -2.6em 0em 0 0em rgba(0, 32, 91, 0.2), -1.8em -1.8em 0 0em rgba(0, 32, 91, 0.5);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 32, 91, 0.5), 1.8em -1.8em 0 0em rgba(0, 32, 91, 0.7), 2.5em 0em 0 0em #000, 1.75em 1.75em 0 0em rgba(0, 32, 91, 0.2), 0em 2.5em 0 0em rgba(0, 32, 91, 0.2), -1.8em 1.8em 0 0em rgba(0, 32, 91, 0.2), -2.6em 0em 0 0em rgba(0, 32, 91, 0.2), -1.8em -1.8em 0 0em rgba(0, 32, 91, 0.2);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 32, 91, 0.2), 1.8em -1.8em 0 0em rgba(0, 32, 91, 0.5), 2.5em 0em 0 0em rgba(0, 32, 91, 0.7), 1.75em 1.75em 0 0em #000, 0em 2.5em 0 0em rgba(0, 32, 91, 0.2), -1.8em 1.8em 0 0em rgba(0, 32, 91, 0.2), -2.6em 0em 0 0em rgba(0, 32, 91, 0.2), -1.8em -1.8em 0 0em rgba(0, 32, 91, 0.2);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 32, 91, 0.2), 1.8em -1.8em 0 0em rgba(0, 32, 91, 0.2), 2.5em 0em 0 0em rgba(0, 32, 91, 0.5), 1.75em 1.75em 0 0em rgba(0, 32, 91, 0.7), 0em 2.5em 0 0em #000, -1.8em 1.8em 0 0em rgba(0, 32, 91, 0.2), -2.6em 0em 0 0em rgba(0, 32, 91, 0.2), -1.8em -1.8em 0 0em rgba(0, 32, 91, 0.2);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 32, 91, 0.2), 1.8em -1.8em 0 0em rgba(0, 32, 91, 0.2), 2.5em 0em 0 0em rgba(0, 32, 91, 0.2), 1.75em 1.75em 0 0em rgba(0, 32, 91, 0.5), 0em 2.5em 0 0em rgba(0, 32, 91, 0.7), -1.8em 1.8em 0 0em #000, -2.6em 0em 0 0em rgba(0, 32, 91, 0.2), -1.8em -1.8em 0 0em rgba(0, 32, 91, 0.2);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 32, 91, 0.2), 1.8em -1.8em 0 0em rgba(0, 32, 91, 0.2), 2.5em 0em 0 0em rgba(0, 32, 91, 0.2), 1.75em 1.75em 0 0em rgba(0, 32, 91, 0.2), 0em 2.5em 0 0em rgba(0, 32, 91, 0.5), -1.8em 1.8em 0 0em rgba(0, 32, 91, 0.7), -2.6em 0em 0 0em #000, -1.8em -1.8em 0 0em rgba(0, 32, 91, 0.2);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 32, 91, 0.2), 1.8em -1.8em 0 0em rgba(0, 32, 91, 0.2), 2.5em 0em 0 0em rgba(0, 32, 91, 0.2), 1.75em 1.75em 0 0em rgba(0, 32, 91, 0.2), 0em 2.5em 0 0em rgba(0, 32, 91, 0.2), -1.8em 1.8em 0 0em rgba(0, 32, 91, 0.5), -2.6em 0em 0 0em rgba(0, 32, 91, 0.7), -1.8em -1.8em 0 0em #000;
    }
}
</style>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import QrcodeVue from 'qrcode.vue'
import moment from "moment";
export default {
    name: "RePayments",
    components: {
        Form,
        Field,
        ErrorMessage,
        QrcodeVue
    },
    data() {
        return {
            value: '', // The value that the QR code will represent
            size: 200, // The size of the QR code
            renderAs: "canvas",

            mobile_number: "",
            password: "",
            passwordFieldType: "password",
            hasApplicationInfo: false,
            application: {
                user: {}
            },
            loan_application_id: "2887036d-59cb-475c-870d-270949c61484",
            payable_amount: "",
            trans_type: "INSTALLMENT",
            upi_no: "",
            messages: "",
            payment_status: false,
            hasDisable: false,
            loader: false,
            payment_method: "",
            hideForm: false,
            showQrCode: false,
            expire_time: "",
            remaining_time: "",
        };
    },
    watch: {

    },
    mounted() {
        window.$("#termAndCondition").modal("show");
    },
    methods: {
        handleShowAndHide() {
            this.hasApplicationInfo = false;
            this.hideForm = true;
        },
        paymentMethodVPA(e) {
            this.payment_method = e;
        },
        paymentMethodSCAN(e) {
            this.payment_method = e;
            this.onGenerateQrIntent();
        },
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },
        OpenNotesModel() {
            window.$('#termAndConditionContent').css({"opacity":"0.1"});
            window.$('#onOpenNotesModel').modal('show');
        },
        closeNotesModel() {
            this.hasApplicationInfo = false;
            window.location.href = "https://nityadhanfinance.com";
            window.$('#onOpenNotesModel').modal('hide');
        },
        onSearchLoanApplication() {
            this.hasApplicationInfo = false;
            this.errorMessage = "";
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "search-loan",
                    _body: { keyword: this.loan_application_no },
                    _buttonId: "search-loan",
                })
                .then((res) => {
                    if (res) {
                        this.hasApplicationInfo = true;
                        this.loan_application_no = res?.info?.application_id;
                        this.loan_application_id = res?.info?.uuid;
                        this.application = res?.info;
                        // console.log("response data", res);
                    }
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: 'top-right'
                    });
                });
        },
        onCheckPaymentStatus(ref_no, order_no) {
            // console.log("ref_no,order_no", ref_no, order_no);
            let url = "loan/" + this.loan_application_id + "/payment/" + order_no + "/" + ref_no;
            if (ref_no === null) {
                url = "loan/" + this.loan_application_id + "/payment/" + order_no;
            }
            this.$api
                .webRequest({
                    _method: "get",
                    _action: url,
                })
                .then((res) => {
                    // console.log("the loged value is", res);
                    if (res?.status == true) {
                        this.payment_status = res?.state;
                        this.messages = res?.message;
                        // this.OpenNotesModel();
                    }
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: 'top-right'
                    });
                });
        },
        onGenerateQrIntent() {
            var self = this;
            var obj = {
                payment_mode: "UPI", trans_type: this.trans_type, amount: this.payable_amount, payment_date: moment(String(new Date())).format("Y-MM-DD")
            };
            this.loader = true;
            this.hasDisable = true;
            let url = `loan/${this.loan_application_id}/qr-payment`;
            this.$api
                .webRequest({
                    _method: "POST",
                    _action: url,
                    _buttonId: "pay_by_scan",
                    _body: obj,
                })
                .then((res) => {
                    // console.log("the logged value is", res);
                    if (res?.req != null) {
                        // this.expire_time = moment().utc(res?.expired_at).format();
                        var time =  moment(res?.expired_at).format('YYYY-MMM-DD h:mm');
                        var gmtDateTime = moment.utc(time, "YYYY-MM-DD HH")
                        this.expire_time = gmtDateTime.local().format('YYYY-MMM-DD h:mm A');
                        this.value = res?.req;
                        this.showQrCode = true;
                        this.startFiveMinuteCounter();
                        var interval = setInterval(function () {
                            self.onCheckPaymentStatus(null, res?.order_no);
                            if (self.payment_status === "SUCCESS" || self.payment_status === "EXPIRED" || self.payment_status === "FAILURE" || self.payment_status === "REJECTED" || self.payment_status === "FAILED") {
                                if (interval) {
                                    clearInterval(interval);
                                }
                                self.OpenNotesModel();
                            }
                        }, 5000)
                    }

                    // console.log(this.expire_time, res?.expired_at,gmtDateTime);
                    // if (res?.status == true) {
                    //     this.payment_status = res?.status;
                    //     this.messages = res?.message;
                    //     this.OpenNotesModel();
                    // }
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: 'top-right'
                    });
                });
        },
        onSubmitAmountPay() {
            if (this.upi_no === "") {
                this.$toast.error("Please enter UPI/VPA no.", {
                    position: 'top-right'
                });
                return false;
            }
            var self = this;
            var obj = {
                payment_mode: "UPI", trans_type: this.trans_type, amount: this.payable_amount, payment_date: moment(String(new Date())).format("Y-MM-DD"),
                vpa: this.upi_no
            };
            // console.log("sended object is ", obj);

            this.$api
                .webRequest({
                    _method: "POST",
                    _action: "loan/" + this.loan_application_id + "/web/payment",
                    _buttonId: "pay_vpa",
                    _body: obj,
                })
                .then((res) => {
                    if (res) {
                        this.hasDisable = true;
                        this.loader = true;
                        const interval = setInterval(() => {
                            self.onCheckPaymentStatus(res?.ref_no, res?.order_no);
                            if (self.payment_status === "SUCCESS" || self.payment_status === "EXPIRED" || self.payment_status === "FAILURE" || self.payment_status === "REJECTED" || self.payment_status === "FAILED") {
                                // console.log("calling ");
                                    clearInterval(interval);
                                self.OpenNotesModel();
                            }
                        }, 5000);
                    }
                })
                .catch((e) => {
                    this.hasDisable = false;
                    this.loader = false;
                    this.$toast.error(e.message, {
                        position: 'top-right'
                    });

                });
        },


        startFiveMinuteCounter() {
            let timeLeft = 5 * 60; // 5 minutes in seconds
            const timerId = setInterval(() => {
                if (timeLeft > 0) {
                    this.remaining_time = `${Math.floor(timeLeft / 60)}:${timeLeft % 60}`;
                    // console.log(`Time remaining: ${Math.floor(timeLeft / 60)}:${timeLeft % 60}`);
                    timeLeft--;
                    if(this.payment_status === "SUCCESS"){
                        clearInterval(timerId);
                    }
                } else {
                    this.remaining_time = "0:00";
                    // console.log('The 5-minute counter has stopped.');
                    this.onGenerateQrIntent()
                    clearInterval(timerId);
                }
            }, 1000);
        }

    },
};
</script>