<template>
    <div class="content-body">
        <div class="worker_serch_warp">
            <div class="row d-flex justify-content-between">
                <div class="col-md-4 pr-2">
                    <h2 class="content-title">
                        <a @click="$router.go(-1)" style="cursor: pointer"><i class="fas fa-angle-left mr-2"></i>
                            <!-- <span  v-if="!this.id">Add Payment</span> -->
                                 <span >Add Payment</span> 
                                </a>
                    </h2>
                </div>
            </div>
        </div>
        <div class="component-section no-code">
            <div class="row-sm wb-row-gap">
                <Form @submit="save" class="columns column is-multiline is-12" data-vv-scope="formAddExpenseCategory"
                    ref="addEditPayment">
                    <div class="col-lg-12">
                        <div class="card rounded-5">
                            <div class="card-body p-3 p-lg-3">
                                <div class="row">
                                    <div class="col-lg-4 mb-4">
                                        <label> Amount <span class="text-danger">*</span> </label>
                                        <Field name="amount" v-model="payment.amount" class="form-control"
                                            rules="required:amount" :validateOnInput="true" type="text" placeholder="Amount"
                                            autocapitalize="false" />
                                        <ErrorMessage name="amount" class="validation-msg" />
                                    </div>


                                    <div class="col-lg-3 mb-4">
                                        <label>Payment Date
                                            <span class="text-danger">*</span>
                                        </label>
                                        <Field name="payment_date" class="form-control" v-slot="{ date }" rules="required:payment date,true"
                                        id="payment_date" v-model="payment.payment_date">
                                        <VueDatePicker v-bind="date" placeholder="Select Payment Date " style="height: 46px; !important"
                                            v-model="payment.payment_date" :type="boolean" :format="'dd/MM/yyyy'" :default="true"
                                            :enable-time-picker="false">
                                        </VueDatePicker>
                                        </Field>
                                        <ErrorMessage name="payment_date" class="validation-msg" />
                                    </div>
 
                                    <div class="col-lg-4 mb-4">
                                        <label>Transaction Type <span class="text-danger">*</span> </label>
                                        <Field v-slot="{ field }" name="trans_type" rules="required:transaction type,true"
                                            :validateOnInput="true" v-model="payment.trans_type">
                                            <Multiselect v-bind="field" ref="trans_type_multiselect" mode="single"
                                                trackBy="title" label="title" valueProp="value"
                                                placeholder="Select Transaction Type" class="form-control"
                                                v-model="payment.trans_type" :options="transactionOption"
                                                :searchable="true" />
                                        </Field>
                                        <ErrorMessage name="trans_type" class="validation-msg" />
                                    </div>

                                    <div class="col-lg-4 mb-4">
                                        <label>Payment Mode <span class="text-danger">*</span> </label>
                                        <Field v-slot="{ field }" name="payment_mode" rules="required:payment mode,true"
                                            :validateOnInput="true" v-model="payment.payment_mode">
                                            <Multiselect v-bind="field" ref="payment_mode_multiselect" mode="single"
                                                trackBy="title" label="title" valueProp="value"
                                                placeholder="Select Payment Mode" class="form-control"
                                                v-model="payment.payment_mode" :options="paymentModeOption"
                                                :searchable="true" />
                                        </Field>
                                        <ErrorMessage name="payment_mode" class="validation-msg" />
                                    </div>

                                    <div class="col-lg-4 mb-4">
                                        <label> UTR Number 
                                            <!-- <span class="text-danger">*</span>  -->
                                        </label>
                                        <Field name="utr_number" v-model="payment.utr_number" class="form-control"
                                           :validateOnInput="true" type="text"
                                            placeholder="UTR Number" autocapitalize="false" />
                                        <ErrorMessage name="utr_number" class="validation-msg" />
                                    </div>

                                    <div class="col-lg-4 mb-4">
                                        <label> Upload Bill Photo</label>
                                        <input type="file" value="" class="form-control"
                                            @change="handleImageUpload($event)" />
                                            <img src="" style="width: 70px;" class="img-fluid" id="preview">
                                    </div>

                                    <div class="col-lg-4 mb-4">
                                        <label> Comment <span class="text-danger">*</span> </label>
                                        <Field v-slot="{ field }" name="comment" v-model="payment.comment"
                                            rules="required:comment" :validateOnInput="true" type="text"
                                            autocapitalize="false">
                                            <textarea v-bind="field" placeholder="Comment" class="form-control"
                                                v-model="payment.comment"></textarea>
                                        </Field>
                                        <ErrorMessage name="comment" class="validation-msg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5 mb-3">
                            <div class="col-12 text-center">
                                <button type="button" class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                                    @click="$router.go(-1)">
                                    Cancel
                                </button>
                                <button type="submit" id="save-btn" class="btn btn-brand-01   wb-save-btn">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    name: "AddEditPayment",
    components: {
        Multiselect, Form,
        Field,VueDatePicker,
        ErrorMessage,
    },
    data() {
        return {
            id: this.$route.params.id,
            payment: {
                name: "",
                payment_mode: "",
                status: "",
                payment_date:""
            },
            transactionOption: [{
                "title": "Installment", "value": "INSTALLMENT",
            }, {
                "title": "Advance Payment", "value": "ADVANCE_PAYMENT",
            }, {
                "title": "Settlement Amount", "value": "SETTLEMENT_AMOUNT",
            }],
            paymentModeOption: [{
                "title": "Net Banking", "value": "NET_BANKING",
            }, {
                "title": "Cash", "value": "CASH",
            }, {
                "title": "Cheque", "value": "CHEQUE",
            }, {
                "title": "UPI", "value": "UPI",
            }],
        };
    },
    mounted() {
    },
    methods: {
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },



        save() {
            this.payment.payment_date = moment(String(this.payment.payment_date)).format(
                    "Y-MM-DD"
            );

            // if(this.payment){
            //     console.log(this.payment);
            //     return true;
            // }
            this.$api
                .webRequest({
                    _method: "POST",
                    _action: "loan/" + this.id + "/payment",
                    _body: this.payment,
                    _buttonId: "save-btn",
                    _hide_loader: true,
                })
                .then((res) => {
                    if (this.file) {
                        this.uploadFile(this.file,res.trx_ref_id);
                    } else {
                        this.onClearForm("addEditPayment");
                        this.$toast.success(res.message, { position: "top-right" });
                        this.$router.go(-1);
                    }
                })
                .catch((e) => {
                    this.$toast.error(e.message, { position: "top-right" });
                });

        },

        uploadFile(file,id) {
            this.$api
                .uploadImageAPI({
                    _action: "loan/"+this.id+"/payment/"+ id,
                    _file: file,
                    _key: "image",
                })
                .then((res) => {
                    this.$toast.success(res.message, { position: "top-right" });
                    this.$router.go(-1);
                });
        },
        handleImageUpload(e) {
            let files = e.target.files;
            let reader = new FileReader();

            reader.readAsDataURL(files[0]);
            reader.onload = (evt) => {
                let img = new Image();
                img.onload = () => {
                    if (
                        files[0].type.toLowerCase() != "image/jpeg" &&
                        files[0].type.toLowerCase() != "image/png"
                    ) {
                        alert("Invalid file formate, please use jpeg or png file.");
                        return;
                    }

                    this.file = files[0];


                    var tmppath = URL.createObjectURL(files[0]);
                    document.getElementById("preview").src = tmppath;
                };

                img.src = evt.target.result;
            };
        },
    },
};
</script>

<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>