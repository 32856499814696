<template>
  <div v-show="isShowModal">
    <div class="modal fade wb-modal-wrapper" id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="changePass"
      aria-hidden="true" style="pointer-events: none">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a class="close mr-3 mt-3 text-right" @click="closeModal()" aria-label="Close">
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>{{ title }}</span>
            </h5>
          </div>
          <div class="modal-body form-style pt-0 px-lg-5">
            <div class="row">
              <div class="col-lg-12 text-center">
                <h5>{{ body }}</h5>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button id="cancel-btn" @click="closeModal()" type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
              {{ leftButton }}
            </button>
            <button type="submit" class="btn btn-brand-01 wb-save-btn" @click="onClick()" id="update-password">
              {{ rightButton }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AlertMessage",
  data() {
    return {
      isShowModal: false,
      showLoader: false,
      rightButton: "Yes, Delete",
      leftButton: "No",
      title: "Alert",
      body: "Are you sure you want to delete",
      obj: {},
    };
  },

  methods: {
    showModal(title, body, obj) {
      window.$("#confirmationModal").modal("show");
      this.title = title;
      this.body = body;
      this.obj = obj;
      this.isShowModal = true;
    },
    closeModal() {
      window.$("#confirmationModal").modal("hide");
      this.isShowModal = false;
      this.removeLoader();
    },
    onClick() {
      this.$emit("remove", this.obj);
      this.showLoader = true;
    },
    removeLoader() {
      this.showLoader = false;
    },
  }
};
</script>