<template>
    <div class="container-fluid">
        <div class="worker_serch_warp">
            <div class="row d-flex justify-content-between">
                <div class="col-md-4 pr-2 mx-2">
                    <h2 class="content-title">
                        <a @click="$router.go(-1)" style="cursor: pointer"><i class="fas fa-angle-left mr-2"></i> <span>View
                                business offer</span> </a>
                    </h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 mx-3">
                <div class="card rounded-2" style="border-radius: 10px;">
                    <div style="border-bottom: 1px solid #ccc;" class="card-header pb-2 pt-3">
                        <h4 class="text-bolder">Basic Details</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-8">

                                <div class="row">
                                    <div class="col-lg-4">
                                        <h5 class="label-text">Customer</h5>
                                    </div>
                                    <div class="col-lg-8">
                                        <h5 class="text-bolder">{{ offers?.user?.name }}</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <h5 class="label-text">Business Name</h5>
                                    </div>
                                    <div class="col-lg-8">
                                        <h5 class="text-bolder">{{ offers?.title }}</h5>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-4">
                                        <h5 class="label-text">Mobile Number</h5>
                                    </div>
                                    <div class="col-lg-8">
                                        <h5 class="text-bolder">{{ offers?.mobile_number }}</h5>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-4">
                                        <h5 class="label-text">Address</h5>
                                    </div>
                                    <div class="col-lg-8">
                                        <h5 class="text-bolder">{{ offers?.address ? offers?.address : "-" }}</h5>
                                    </div>
                                </div>

                               

                                <div class="row">
                                    <div class="col-lg-4">
                                        <h5 class="label-text">Category</h5>
                                    </div>
                                    <div class="col-lg-8">
                                        <h5 class="text-bolder"><span style="color: #5CC82A;">{{ offers?.category?.name
                                        }}</span></h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <h5 class="label-text">Term & Conditions</h5>
                                    </div>
                                    <div class="col-lg-8">
                                        <h5 class="text-bolder">{{ offers?.terms_conditions }}</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <h5 class="label-text">Offer</h5>
                                    </div>
                                    <div class="col-lg-8">
                                        <h5 class="text-bolder" v-if="offers.offer_type == 'FIXED'"> <span
                                                class=" badge-secondry" style="color: #5CC82A;font-size: 20px;">
                                                {{ $helperService.getFormattedCurrency(offers?.offer_value) }}
                                                <!-- <i class="fa fa-info-circle" v-if="offers?.terms_conditions != null"
                                                    style="cursor: pointer;" :title="offers?.terms_conditions"
                                                    @click="onOpenTermAndConditionDocument(offers?.terms_conditions)"></i> -->
                                                </span>
                                        </h5>
                                        <h5 style="color: #5CC82A;font-size: 20px;" v-else>{{ offers?.offer_value + "%" }}
                                            <!-- <i
                                                class="fa fa-info-circle" v-if="offers?.terms_conditions != null"
                                                style="cursor: pointer;" :title="offers?.terms_conditions"
                                                @click="onOpenTermAndConditionDocument(offers?.terms_conditions)"></i> -->
                                            </h5>
                                    </div>
                                </div>

                                <!-- <div class="row">
                                    <div class="col-lg-4">
                                        <h5 class="label-text">Customer </h5>
                                        <h5 class="label-text">Business Name</h5>
                                        <h5 class="label-text">Mobile Number</h5>
                                        <h5 class="label-text">Address</h5>
                                        <h5 class="label-text">Term & Condition</h5>
                                        <h5 class="label-text">Category</h5>
                                        <h5 class="label-text">Offer</h5>
                                    </div>
                                    <div class="col-lg-8">
                                        <h5 class="text-bolder">{{ offers?.user?.name }}</h5>
                                        <h5 class="text-bolder">{{ offers?.title }}</h5>
                                        <h5 class="text-bolder">{{ offers?.mobile_number }}</h5>

                                        <h5 class="text-bolder">{{ offers?.address ? offers?.address : "-" }}</h5>

                                        <h5 class="text-bolder"><span style="color: #5CC82A;">{{ offers?.category?.name
                                        }}</span></h5>
                                        <h5 class="text-bolder" v-if="offers.offer_type == 'FIXED'"> <span
                                                class=" badge-secondry" style="color: #5CC82A;font-size: 20px;">
                                                {{ $helperService.getFormattedCurrency(offers?.offer_value) }}
                                               <i class="fa fa-info-circle" v-if="offers?.terms_conditions != null"
                                                    style="cursor: pointer;" :title="offers?.terms_conditions"
                                                    @click="onOpenTermAndConditionDocument(offers?.terms_conditions)"></i> x
                                                </span>
                                        </h5>
                                        <h5 style="color: #5CC82A;font-size: 20px;" v-else>{{ offers?.offer_value + "%" }}
                                             <i
                                                class="fa fa-info-circle" v-if="offers?.terms_conditions != null"
                                                style="cursor: pointer;" :title="offers?.terms_conditions"
                                                @click="onOpenTermAndConditionDocument(offers?.terms_conditions)"></i> 
                                            </h5>
                                        <h5 class="text-bolder">{{ offers?.terms_conditions }}</h5>

                                    </div>
                                </div> -->
                            </div>
                            <div class="col-lg-4" style="cursor:pointer">
                                <div class="">
                                    <h5 class="text-bolder">Photo</h5>
                                    <img v-on:click="openImage(offers?.image_url, 0)"
                                        :src="offers.image_url ? offers.image_url : '/static/img/placeholder.png'"
                                        class="img-rounded" style="witdh:100px;height:100px" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12  mx-3 pt-3"
                v-if="offers.business_status == 'APPROVED' && offers?.approved_by_user != null">
                <div class="card rounded-2" style="border-radius: 10px;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3">
                                <h5 class="label-text">Approved Name</h5>
                                <h5 class="label-text">Approved Date</h5>
                            </div>
                            <div class="col-lg-9">
                                <h5 class="text-bolder">{{ offers?.approved_by_user?.name }}</h5>
                                <h5 class="text-bolder">{{ $helperService.getFormattedDate(offers?.approved_at) }}
                                </h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-12  mx-3 pt-3"
                v-if="offers.business_status == 'REJECTED' && offers?.rejected_by_user != null">
                <div class="card rounded-2" style="border-radius: 10px;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3">
                                <h5 class="label-text">Rejected Name</h5>
                                <h5 class="label-text">Rejected Date</h5>
                                <h5 class="label-text">Rejected reason</h5>
                            </div>
                            <div class="col-lg-9">
                                <h5 class="text-bolder">{{ offers.rejected_by_user?.name }}</h5>
                                <h5 class="text-bolder">{{ $helperService.getFormattedDate(offers.rejected_at) }}
                                </h5>
                                <h5 class="text-bolder">{{ offers.narration }}</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-12 mx-3 pt-3" v-if="offers.business_status == 'PENDING' && access == 1">
                <div class="row">
                    <div class="col-lg-12 d-flex justify-content-center">
                        <button id="cancel-btn" type="button" @click="onUpdateStatus('REJECTED')"
                            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
                            Reject
                        </button>
                        <button type="submit" @click="onUpdateStatus('APPROVED')"
                            style="background-color: #5CC82A !important;" class="btn btn-brand-01 wb-save-btn"
                            id="status-btn">
                            Approve
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade wb-modal-wrapper" id="termAndCondition" tabindex="-1" role="dialog"
        aria-labelledby="termAndCondition" aria-hidden="true" style="pointer-events: none">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <a class="close mr-3 mt-3 text-right" @click="closeTermAndConditionModal()" aria-label="Close">
                    <img src="/static/img/close-icon.svg" width="20" />
                </a>
                <div class="modal-header justify-content-center" style="border-bottom: 1px solid #ccc;">
                    <h5 class="font-weight-bolder">Term & Conditions</h5>
                </div>
                <div class="modal-body form-style pt-2">
                    <div class="row pt-2">
                        <div class="col-lg-12">
                            <ul class="b">
                                <li>
                                    <h5>{{ termAndCondition }}</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="modal fade wb-modal-wrapper" id="confirmationModal" tabindex="-1" role="dialog"
        aria-labelledby="confirmationModal" aria-hidden="true" style="pointer-events: none">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <a class="close mr-3 mt-3 text-right" @click="closeModal()" aria-label="Close">
                    <img src="/static/img/close-icon.svg" width="20" />
                </a>
                <div class="modal-header justify-content-center">
                    <h5 class="modal-title font-22">
                        <span>Confirmation</span>
                    </h5>
                </div>
                <div class="modal-body form-style pb-0 px-lg-5">
                    <h5>Are you sure,you want to update status?</h5>
                </div>
                <div class="modal-footer border-0 justify-content-center mb-3">
                    <button id="cancel-btn" @click="closeModal()" type="button"
                        class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
                        No
                    </button>
                    <button type="submit" @click="onUpdateOfferStatus()" class="btn btn-brand-01 wb-save-btn"
                        id="status-btn">
                        Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade wb-modal-wrapper" id="openRejectNarration" tabindex="-1" role="dialog"
        aria-labelledby="openRejectNarration" aria-hidden="true" style="pointer-events: none">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <a class="close mr-3 mt-3 text-right" @click="closeNarrationModal()" aria-label="Close">
                    <img src="/static/img/close-icon.svg" width="20" />
                </a>
                <div class="modal-header justify-content-center">
                    <h5 class="modal-title font-22">
                        <span>Reason</span>
                    </h5>
                </div>
                <Form @submit="onAddRejectNarration" class="columns column is-multiline is-12" ref="onAddRejectNarration">
                    <div class="modal-body form-style pb-0 px-lg-5">
                        <div class="signin-form">
                            <div class="form-group">
                                <label>Reason
                                    <span class="text-danger">*</span>
                                </label>
                                <Field v-slot="{ field }" name="level" rules="required:reason,true" :validateOnInput="true"
                                    v-model="narration">
                                    <textarea v-bind="field" rows="5" cols="5" valueProp="id"
                                        placeholder="Enter Reject Reason" class="form-control" v-model="narration" />
                                </Field>
                                <ErrorMessage name="level" class="validation-msg" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-0 justify-content-center mb-3">
                        <button id="cancel-btn" @click="closeNarrationModal()" type="button"
                            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
                            Cancel
                        </button>
                        <button type="submit" class="btn btn-danger wb-cancel-btn" id="status-btn">
                            Reject
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    <vue-easy-lightbox :visible="visibleRef" :imgs="showableImage" :index="indexRef" @hide="onHide"></vue-easy-lightbox>
</template>
<style>
.multiselect-tags-search {
    top: -1px !important;
}
ul.b {list-style-type: square;}
</style>
<script>
import { ref } from "vue";
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js";
import "vue-easy-lightbox/external-css/vue-easy-lightbox.css";
import "@vueform/multiselect/themes/default.css"
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    name: "AddEditoffers",
    components: {
        Form,
        Field,
        ErrorMessage,
        VueEasyLightbox
    },
    data() {
        return {
            id: this.$route.params.id,
            access: this.$route.params.access,
            offers: {
                image_url: ""
            },
            narration: "",
            reason_type: "",
            application_status: "",
            showableImage: "",
            visibleRef: ref(false),
            indexRef: ref(0),
            termAndCondition: ""
        };
    },

    computed: {
        branchList() {
            return this.$storeService.getters.getBranches;
        },
    },
    mounted() {
        if (this.id) {
            this.getOffersDetails();
            this.$storeService.commit("setTitle", "Business Offers Details");
        }
    },
    methods: {
        onOpenTermAndConditionDocument(item) {
            this.termAndCondition = item;
            window.$("#termAndCondition").modal("show");
        },
        closeTermAndConditionModal() {
            this.termAndCondition = "";
            window.$('#termAndCondition').modal('hide');
        },
        onUplaodDocument() {
            window.$("#confirmationModal").modal("show");
        },
        closeModal() {
            window.$('#confirmationModal').modal('hide');
        },
        onNarrtion() {
            window.$("#openRejectNarration").modal("show");
        },
        closeNarrationModal() {
            this.narration = null;
            this.reason_type = null;
            window.$('#openRejectNarration').modal('hide');
            this.onClearForm("onAddRejectNarration");
        },
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },
        onCheckUserTire() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "select/loan-type",
                    _body: { email: this.offers.email, mobile_number: this.offers.mobile_number }
                })
                .then((res) => {
                    this.loanType = res.list;
                })
                .catch(() => { });
        },
        getOffersDetails() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "offer/" + this.id,
                })
                .then((res) => {
                    this.offers = res.info;
                    this.offers.image_url = res?.info?.photos[0].photo;
                })
                .catch(() => { });
        },
        save() {
            var method = "POST";
            var action = "/loan";
            if (this.id) {
                method = "PUT";
                action = "loan/" + this.id;
            }
            this.$api
                .webRequest({
                    _method: method,
                    _action: action,
                    _body: this.offers,
                    _buttonId: "save-btn",
                    _hide_loader: true,
                })
                .then((res) => {
                    this.onClearForm("addEditoffers");
                    this.$toast.success(res.message, { position: "top-right" });
                    this.$router.go(-1);
                })
                .catch((e) => {
                    this.$toast.error(e.message, { position: "top-right" });
                });

        },
        onUploadkycDetails() {
            var loan_doc_id = this.kyc.loan_type_id
            console.log(loan_doc_id, this.kycDcoument, this.id);

            this.$api.uploadImageAPI({
                _action: "loan/" + this.id + "/upload/document/" + loan_doc_id,
                _key: "image",
                _file: this.kycDcoument,
            })
                .then(res => {
                    this.$toast.success(res.message, { position: "top-right" });
                    this.closeModal();
                    this.getOffersDetails();
                })
                .catch(e => {
                    this.$toast.error(e.message, { position: "top-right" });
                });
        },
        onUpdateStatus(status) {
            this.application_status = status;
            if (status == "REJECTED") {
                this.reason_type = status;
                window.$("#openRejectNarration").modal("show");
                return false;
            } else {
                this.onUplaodDocument();
            }
        },
        onUpdateOfferStatus() {
            this.$api.webRequest({
                _method: "POST",
                _action: "offers/status",
                _buttonId: "status-btn",
                _body: { status: this.application_status    , business_ids: [this.id] }
            }).then((res) => {
                this.onClearForm("onAddRejectNarration");
                this.closeModal();
                this.getOffersDetails();
                this.$toast.success(res.message, { position: "top-right" });
            }).catch((e) => {
                this.$toast.error(e.message, { position: "top-right" });
            })
        },
        onAddRejectNarration() {
            var button_id = "status-btn";
            this.$api.webRequest({
                _method: "POST",
                _action: "offers/status",
                _buttonId: button_id,
                _body: { status: this.reason_type, narration: this.narration, business_ids: [this.id] }
            }).then((res) => {
                this.onClearForm("onAddRejectNarration");
                this.closeNarrationModal();
                this.$toast.success(res.message, { position: "top-right" });
                this.getOffersDetails();
            }).catch((e) => {
                this.$toast.error(e.message, { position: "top-right" });
            })
        },
        download(url, filename) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.href = url;
            link.download = filename;
            link.click();
        },
        selectFile(e) {
            e.stopImmediatePropagation();
            window.$("#selectFile").click();
        },
        onDocumentChange(e) {
            let files = e.target.files;
            // console.log("files data:", );
            if (files.length > 0) {
                this.fileName = files[0].name;
                this.kycDcoument = files[0];
            } else {
                this.fileName = null;
                this.kycDcoument = null;
            }
        },
        openImage(image, index) {
            this.showableImage = image;
            this.indexRef = index;
            this.visibleRef = true;
        },
        onHide() {
            this.visibleRef = false;
        },
    },
};
</script>

<style>.multiselect-tags-search {
    top: -1px !important;
}</style>