<template>
  <div class="signin-panel">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div class="m-3 text-center">
            <img src="/static/img/login-logo.png">
          </div>
          <div class="card card-body rounded-20 p-0">
            <div class="signin-sidebar-body">
              <h4 class="signin-title mb-0">Welcome to the Admin!</h4>
              <Form @submit="onLogin" ref="loginForm" class="signin-form">
                <div class="form-group">
                  <div class="login-field">
                    <Field name="Username" v-model="mobile_number" rules="required|phone|numeric" class="form-control"
                      id="username" :validateOnInput="true" type="text" placeholder="Mobile Number"
                      autocapitalize="false" />
                  </div>
                  <ErrorMessage name="Username" class="validation-msg" />
                </div>

                <div class="form-group">
                  <div class="login-field">
                    <Field name="Password" :type="passwordFieldType" id="Password" v-model="password" class="form-control"
                      rules="required|password" :validateOnInput="true" placeholder="Password" autocapitalize="false" />

                    <i id="button" alt="" class="show-icon fa fa-eye-slash" style=" top:15px !important"></i>
                  </div>
                  <ErrorMessage name="Password" class="validation-msg" />
                </div>



                <div class="form-group d-flex mg-b-0">
                  <button class="btn btn-brand-01 btn-uppercase flex-fill" type="submit" id="save-btn">
                    Login
                  </button>
                </div>
              </Form>
              <div class="row">
                <div class="col-lg-12 text-center">
                  <div class="wb-forgotPass">

                    <a style="cursor:pointer" v-on:click="onForgetPassword()">Forgot Password?</a>

                  </div>
                </div>
              </div>
            </div>
            <!-- signin-sidebar-body -->
          </div>
        </div>
      </div>
    </div>
    <!-- signin-sidebar -->
  </div>
  <!-- signin-panel -->
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery"
export default {
  name: "PortalLogin",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      mobile_number: "",
      password: "",
      passwordFieldType: "password",
      image: "/static/img/password.svg",
    };
  },
  mounted() {
    $(document).ready(function () {
      $(".show-icon").on("click", function (event) {
        event.preventDefault();
        console.log("type : ", $(this).parent().find("input").attr("type"));
        var input = $(this).parent().find("input");
        if (input.attr("type") == "text") {
          input.attr("type", "password");
          $(this).parent().find("i").removeClass("fa-eye").addClass("fa-eye-slash");
        } else if (input.attr("type") == "password") {
          input.attr("type", "text");
          $(this).parent().find("i").removeClass("fa-eye-slash").addClass("fa-eye");
        }
      });
    });

  },
  methods: {
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    onLogin() {
      this.errorMessage = "";
      this.$api
        .getAccesstoken({
          _action: "login",
          _buttonId: "save-btn",
          _body: { mobile_number: this.mobile_number, password: this.password },
          _hide_loader: true,
        })
        .then((res) => {
          if (res && res.token) {
            localStorage.setItem("access_token", res.token);
            this.$toast.success(res.message, { position: "top-right" });
            this.onGetMe();
            this.onClearForm("loginForm");
          }

        })
        .catch((e) => {
          console.log(e ,"Message");
          this.$toast.error(e.message, {
            position: 'top-right'
          });
        });
    },
    onSwitchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/static/img/password.svg" ? "/static/img/password.svg" : "/static/img/password.svg";
    },
    onForgetPassword() {
      this.$router.push("/forget-password");
    },
    onGetMe() {
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "me",
          _hide_loader: true,
          _buttonId: "save-btn",
        }).then((res) => {
          localStorage.setItem("my_user_id", res.info.id);
          localStorage.setItem("user_type", res.info.user_type);
          console.log("user_type", res.info.user_type);
          this.$storeService.commit("setUserType", res.info.user_type);
          if(res.info.roles.length > 0){
            localStorage.setItem("roles", JSON.stringify(res.info.roles));
          }
          if (res.info.user_type) {
            let isAdminUser = false;
            if (res.info.user_type == "SUPER_ADMIN" || res.info.user_type == "PARTNER") {
              isAdminUser = true;
            }

            this.$storeService.commit("hasFullAccess", isAdminUser);
          }

          // if(res.info.user_type){
          //   this.$storeService.commit("setUserType", res.info.user_type);
          // }
          // setDashBoardBranches set Dashboard branch
          if (res.info.branches && res.info.branches.length > 0) {
            this.$storeService.commit("setBranches", res.info.branches);
            var allBranches = [...res.info.branches];
            var allBranchObj = {
              "name": "All Branch",
              "branch_id": ""
            };
            var flag = false;
            allBranches.forEach((obj) => {
              if (obj.name !== "All Branch") {
                flag = true;
              }
            });
            if (flag) {
              allBranches.unshift(allBranchObj);
            }
            this.$storeService.commit("setAllBranches", allBranches);
            localStorage.setItem("branch_id", res.info.branches[0].uuid);
          }
          if (res.info.user_type == "SUPER_ADMIN" || res.info.user_type == "PARTNER") {
            if (res.info.branches && res.info.branches.length > 0) {
              var officeBranches = [...res.info.branches];
              var officeObj = {
                "name": "Corporate",
                "branch_id": ""
              };
              var officeExpense = false;
              officeBranches.forEach((obj) => {
                if (obj.name !== "Corporate") {
                  officeExpense = true;
                }
              });
              if (officeExpense) {
                officeBranches.unshift(officeObj);
              }
              this.$storeService.commit("setOfficeExpensesBranch", officeBranches);
              localStorage.setItem('branch_numeric_id', res.info.branches[0].branch_id);
            }
          }
          if (res.info.user_type == "SUPER_ADMIN" || res.info.user_type == "PARTNER") {
            if (res.info.branches) {
              var dashBoardBranch = [...res.info.branches];
              var dashboardObj = {
                "name": "All Branch",
                "branch_id": ""
              };
              var dashboard = false;
              dashBoardBranch.forEach((obj) => {
                if (obj.name !== "All Branch") {
                  dashboard = true;
                }
              });
              if (dashboard) {
                dashBoardBranch.unshift(dashboardObj);
              }
              this.$storeService.commit("setDashBoardBranches", dashBoardBranch);
            }

          } else {
            let dashBoardBranch = [...res.info.branches];
            this.$storeService.commit("setDashBoardBranches", dashBoardBranch);
          }
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$router.push("/welcome-page");
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
  },
};
</script>

