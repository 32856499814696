<template>
    <div>
        <div class="content-body">
            <div class="component-section no-code">
                <div class="row d-flex justify-content-between">
                <div class="col-md-2 pr-2">
                    <h2 class="content-title">
                        <a @click="$router.go(-1)" style="cursor: pointer"><i class="fas fa-angle-left mr-2"></i>
                                 <span>BroadCast History</span> </a>
                    </h2>
                </div>
            </div>
                <div class="row">
                    <div class="container-fluid">
                        <div class="worker_serch_warp">
                            <div class="row">
                                <div class="col-lg-4 mb-2">
                                    <div class="search-form position-relative">
                                        <input type="text" class="form-control" v-model="filterObj.keyword"
                                            v-on:keyup="getList(1)" placeholder="Search" />
                                        <div class="input-group-prepend position-absolute">
                                            <button class="" type="button" id="button-addon1">
                                                <img src="/static/img/search.svg" width="15" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 mb-2 text-right">
                                    <a @click="getList(1)" class="btn btn-brand-01 mr-2">Search
                                    </a>
                                    <a @click="reset()" class="btn btn-brand-03 mr-2">Reset
                                    </a>
                                    <a @click="addIncenTive()" class="btn btn-brand-02" v-if="access.can_create == 1">+ Add
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card rounded-5">
                            <div class="card-body p-3 p-lg-3">
                                <div class="table-responsive">
                                    <table class="table table-primary mg-b-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="sorting" @click="sorting($event, 'created_at')">
                                                    Send By
                                                </th>
                                                <th scope="col" class="sorting" @click="sorting($event, 'title')">
                                                    Send To
                                                </th>
                                                <th scope="col" class="sorting" @click="sorting($event, 'message')">
                                                    Date
                                                </th>
                                                <th scope="col" class="sorting" @click="sorting($event, 'message')">
                                                    Time
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loader">
                                                <td colspan="5" class="text-center">
                                                    <div class="spinner-border spinner-color" role="status">
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-for="item in list" :key="item.id">
                                                <td>{{ item?.added_by?.name }}</td>
                                                <td>{{ item?.user?.name }}</td>
                                                <td> <span v-if="item.created_at">{{    
                                                    $helperService.getFormattedDate(item.created_at) }}</span></td>
                                                <td><span v-if="item.created_at">{{
                                                    $helperService.getFormattedTime(item.created_at) }}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <ErrorComponent @retry="getList(1)" ref="errorComponent" />
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 mt-5 text-center">
                                        <div class="page-bottom-pagination text-right">
                                            <Pagination @page-change="pageChange"
                                                @items-per-page-change="itemsPerPageChange" ref="rolePagination" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import $ from "jquery";
export default {
    name: "ManageIncenTive",
    components: {
        Pagination,
        ErrorComponent,
    },

    data() {
        return {
            loader: false,
            currentPage: 1,
            totalPage: 0,
            itemsPerPage: 10,
            list: [],
            access: {
                can_read: 1,
                can_create: 1,
                can_update: 1,
                can_print: 1,
                can_delete: 1,
            },
            filterObj: {
                keyword: "",
                offset: 1,
                branch_ids:"",
                sort_by: "created_at",
                sort_order: "desc",
                row_per_page: 10,
                status: ""
            },
            id: this.$route.params.id,
        };
    },
    mounted() {
        this.$storeService.commit("setTitle", "History");
        this.getList(1);
    },
    methods: {
        reset() {
            this.filterObj = {
                keyword: "",
                offset: 1,
                sort_by: "created_at",
                sort_order: "desc",
                row_per_page: 10,
                status: ""
            }
        },
        pageChange(page) {
            this.getList(page);
        },
        itemsPerPageChange() {
            this.itemsPerPage = this.$refs.rolePagination.itemsPerPage;
            this.getList(1);
        },

        retry() {
            this.getList(1);
        },
        addIncenTive() {
            this.$router.push("/add-broadcast");
        },
        getList(page, sortBy, orderBy) {
            this.filterObj.offset = page - 1;
            this.currentPage = page;
            this.filterObj.row_per_page = this.itemsPerPage;

            if (sortBy) {
                this.filterObj.sort_by = sortBy;
            }
            if (orderBy) {
                this.filterObj.sort_order = orderBy;
            }

            this.list = [];
            this.loader = true;
            var method = "GET";
            this.$api
                .webRequest({
                    _method: method,
                    _action: "/notifications/" + this.id + "/history",
                    _body: this.filterObj,
                })
                .then((res) => {
                    this.loader = false;
                    this.list = res.list;
                    this.access = res.access;
                    if (page == 1) {
                        if (res.count != null && res.count > 0) {
                            if (this.$refs.rolePagination) {
                                this.$refs.rolePagination.setTotalCount(res.count);
                            }
                        } else {
                            if (this.$refs.rolePagination) {
                                this.$refs.rolePagination.setTotalCount(0);
                            }
                        }
                    }
                    if (!res.list || res.list.length <= 0) {
                        if (this.$refs.errorComponent) {
                            this.$refs.errorComponent.setTitle("Sorry, There is no record found.",true);
                        }
                    } else {
                        if (this.$refs.errorComponent) {
                            if (this.$refs.errorComponent.isShowMessage()) {
                                this.$refs.errorComponent.setTitle();
                            }
                        }
                    }
                    if (this.$refs.rolePagination) {
                        this.$refs.rolePagination.currentPage = this.currentPage;
                    }
                })
                .catch((e) => {
                    this.$toast.error(e.message, { position: "top-right" });
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setTitle();
                    }
                });
        },
        sorting(e, sortBy) {
            var orderBy = "ASC";

            var className = "sorting_asc";
            var classes = e.target.className;
            if (classes.includes("sorting_asc")) {
                className = "sorting_desc";
            }

            $(".sorting_asc").removeClass("sorting_asc");
            $(".sorting_desc").removeClass("sorting_desc");

            if (className == "sorting_asc") {
                $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
                orderBy = "ASC";
            } else if (className == "sorting_desc") {
                $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
                orderBy = "DESC";
            }
            this.getList(1, sortBy, orderBy);
        },



    },
};
</script>
  
  