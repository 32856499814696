<template>
  <div class="content-body">
    <div class="component-section no-code">
      <div class="container-fluid px-0">
        <div class="row">
          <div class="col-lg-12">
            <div class="worker_serch_warp">
              <div class="row d-flex justify-content-between">
                <div class="col-md-5 pr-2">
                  <h2 class="content-title">
                    <a @click="$router.go(-1)" style="cursor: pointer"
                      ><i class="fas fa-angle-left mr-2"></i
                      ><span v-if="!this.id">Add Lead Management</span>
                      <span v-else>Edit Lead Management</span>
                    </a>
                  </h2>
                </div>
              </div>
            </div>
            <ul
              id="myTab"
              role="tablist"
              class="nav nav-tabs worker_tabs"
              v-if="this.id"
            >
              <li class="nav-item">
                <a
                  id="tab-1"
                  @click="chnageTab('DETAIL')"
                  data-toggle="tab"
                  href="#tab_1"
                  role="tab"
                  aria-controls="tab_1"
                  aria-selected="true"
                  class="nav-link active"
                  >Detail</a
                >
              </li>
              <li class="nav-item">
                <a
                  id="tab-2"
                  @click="chnageTab('COMMENT')"
                  data-toggle="tab"
                  href="#tab_2"
                  role="tab"
                  aria-controls="tab_2"
                  aria-selected="false"
                  class="nav-link"
                  >Comments</a
                >
              </li>
            </ul>
            <div id="myTabContent" class="tab-content">
              <div class="worker_serch_warp" v-if="id">
                <div class="row">
                  <div class="col-lg-12 mb-2 text-right">
                    <a
                      @click="this.$router.push('/add-loan-application/' + id)"
                      class="btn btn-brand-02"
                      >+ Add Application
                    </a>
                  </div>
                </div>
              </div>
              <div
                id="tab_1"
                role="tabpanel"
                aria-labelledby="tab-1"
                class="tab-pane fade active show"
              >
                <div class="component-section no-code" v-if="tab == 'DETAIL'">
                  <div class="row-sm wb-row-gap">
                    <Form
                      @submit="save"
                      class="columns column is-multiline is-12"
                      data-vv-scope="formAddleadManagement"
                      ref="addEditleadManagement"
                    >
                      <div class="col-lg-12">
                        <div class="card rounded-5">
                          <div class="card-body p-3 p-lg-3">
                            <div class="row">
                              <div class="col-lg-4 mb-4" v-if="hasFullAccess">
                                <label> Branch </label>
                                <Field
                                  v-slot="{ field }"
                                  name="branch_id"
                                  :validateOnInput="true"
                                  v-model="leadManagement.branch_id"
                                >
                                  <Multiselect
                                    v-bind="field"
                                    ref="branch_multiselect"
                                    mode="single"
                                    trackBy="name"
                                    rules="required"
                                    label="name"
                                    valueProp="branch_id"
                                    placeholder="Select Branch"
                                    class="form-control"
                                    v-model="leadManagement.branch_id"
                                    :options="branchList"
                                    :close-on-select="true"
                                    :createOption="true"
                                    :searchable="true"
                                  />
                                </Field>
                                <ErrorMessage
                                  name="branch_id"
                                  class="validation-msg"
                                />
                              </div>
                              <div class="col-lg-4 mb-4">
                                <label>
                                  Name <span class="text-danger">*</span>
                                </label>
                                <Field
                                  name="name"
                                  v-model="leadManagement.name"
                                  class="form-control"
                                  rules="required:name"
                                  :validateOnInput="true"
                                  type="text"
                                  placeholder="Name"
                                  autocapitalize="false"
                                />
                                <ErrorMessage
                                  name="name"
                                  class="validation-msg"
                                />
                              </div>
                              <div class="col-lg-4 mb-4">
                                <label>Email </label>
                                <Field
                                  name="email"
                                  v-model="leadManagement.email"
                                  class="form-control"
                                  rules="email"
                                  :validateOnInput="true"
                                  type="text"
                                  placeholder="Email"
                                  autocapitalize="false"
                                />
                                <ErrorMessage
                                  name="email"
                                  class="validation-msg"
                                />
                              </div>
                              <div class="col-lg-4 mb-4">
                                <label
                                  >Mobile Number
                                  <span class="text-danger">*</span>
                                </label>
                                <Field
                                  name="mobile_number"
                                  v-model="leadManagement.mobile_number"
                                  class="form-control"
                                  rules="required:mobile_number|phone|numeric"
                                  :validateOnInput="true"
                                  type="text"
                                  placeholder="Mobile Number"
                                  autocapitalize="false"
                                />
                                <ErrorMessage
                                  name="mobile_number"
                                  class="validation-msg"
                                />
                              </div>
                              <div class="col-lg-4 mb-4">
                                <label
                                  >Loan Amount
                                  <span class="text-danger">*</span>
                                </label>
                                <Field
                                  name="loan_amount"
                                  class="form-control"
                                  id="loan_amount"
                                  rules="required:loan amount|numeric|multiply"
                                  :validateOnInput="true"
                                  v-model="leadManagement.loan_amount"
                                  type="text"
                                  placeholder="Loan Amount"
                                  autocapitalize="false"
                                />
                                <ErrorMessage
                                  name="loan_amount"
                                  class="validation-msg"
                                />
                              </div>
                              <div class="col-lg-4 mb-4">
                                <label
                                  >Lead Status
                                  <span class="text-danger">*</span>
                                </label>
                                <Field
                                  v-slot="{ field }"
                                  name="lead_status"
                                  rules="required:lead status,true"
                                  :validateOnInput="true"
                                  v-model="leadManagement.lead_status"
                                >
                                  <Multiselect
                                    v-bind="field"
                                    ref="expenses_for_multiselect"
                                    mode="single"
                                    trackBy="title"
                                    label="title"
                                    valueProp="value"
                                    placeholder="Select Lead Status"
                                    class="form-control"
                                    v-model="leadManagement.lead_status"
                                    :options="leadStatusOption"
                                    :searchable="true"
                                  />
                                </Field>
                                <ErrorMessage
                                  name="lead_status"
                                  class="validation-msg"
                                />
                              </div>
                              <!-- <div class="col-lg-4 mb-4">
                                                                <label> Status <span class="text-danger">*</span> </label>
                                                                <Field v-slot="{ field }" name="status"
                                                                    rules="required:status,true" :validateOnInput="true"
                                                                    v-model="leadManagement.status">
                                                                    <Multiselect v-bind="field" ref="status_multiselect"
                                                                        mode="single" trackBy="title" rules="required:true"
                                                                        label="title" valueProp="value"
                                                                        placeholder="Select Status" class="form-control"
                                                                        v-model="leadManagement.status"
                                                                        :options="statusOption" :searchable="true" />
                                                                </Field>
                                                                <ErrorMessage name="status" class="validation-msg" />
                                                            </div> -->
                              <!-- <div class="col-lg-4 mb-4">
                                                                <label>Branch<span class="text-danger">*</span></label>
                                                                <Field v-slot="{ field }" name="branch"
                                                                    rules="required:branch,true" :validateOnInput="true"
                                                                    v-model="leadManagement.branch_id">
                                                                    <Multiselect v-bind="field" ref="branch_multiselect"
                                                                        mode="single" trackBy="name" label="name"
                                                                        valueProp="id" placeholder="Select Branch"
                                                                        class="form-control"
                                                                        v-model="leadManagement.branch_id"
                                                                        :options="branchList" :searchable="true"
                                                                        :createOption="true" />
                                                                </Field>
                                                                <ErrorMessage name="branch" class="validation-msg" />
                                                            </div> -->

                              <div class="col-lg-4 mb-4">
                                <label
                                  >Loan Type
                                  <span class="text-danger">*</span>
                                </label>
                                <Field
                                  v-slot="{ field }"
                                  name="level"
                                  rules="required:level,true"
                                  :validateOnInput="true"
                                  v-model="leadManagement.loan_type_id"
                                >
                                  <Multiselect
                                    v-bind="field"
                                    ref="level_multiselect"
                                    mode="single"
                                    @search-change="onCheckUserTire"
                                    trackBy="title"
                                    label="title"
                                    valueProp="value"
                                    placeholder="Select Loan Type"
                                    class="form-control"
                                    v-model="leadManagement.loan_type_id"
                                    :options="loanType"
                                    :searchable="true"
                                  />
                                </Field>
                                <ErrorMessage
                                  name="level"
                                  class="validation-msg"
                                />
                              </div>
                              <!-- <div class="col-lg-4 mb-4">
                                                                    <label>Followup Date
                                                                    </label>
                                                                    <Field name="followup_at" class="form-control"
                                                                        id="followup_at" v-model="leadManagement.followup_at"
                                                                        type="date" placeholder="Followup Date"
                                                                        autocapitalize="false" />
                                                                    <ErrorMessage name="followup_at" class="validation-msg" />
                                                                </div> -->
                              <div class="col-lg-4 mb-4" v-if="id == null">
                                <label> Comment </label>
                                <Field
                                  v-slot="{ field }"
                                  name="comment"
                                  v-model="leadManagement.comment"
                                  :validateOnInput="true"
                                  type="text"
                                  autocapitalize="false"
                                >
                                  <textarea
                                    v-bind="field"
                                    placeholder="Write your comment here..."
                                    class="form-control"
                                    v-model="leadManagement.comment"
                                  ></textarea>
                                </Field>
                                <ErrorMessage
                                  name="comment"
                                  class="validation-msg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-5 mb-3">
                          <div class="col-12 text-center">
                            <button
                              type="button"
                              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                              @click="$router.go(-1)"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              id="save-btn"
                              class="btn btn-brand-01 wb-save-btn"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div
                id="tab_2"
                role="tabpanel"
                aria-labelledby="tab-2"
                class="tab-pane fade"
              >
                <div class="card rounded-5" v-if="tab == 'COMMENT'">
                  <div class="card-body p-3 p-lg-3">
                    <div class="worker_serch_warp">
                      <div class="row">
                        <div class="col-lg-3 mb-2">
                          <div class="search-form position-relative">
                            <input
                              type="text"
                              class="form-control"
                              v-model="filterObj.keyword"
                              placeholder="Search"
                              @keyup="getList(1)"
                            />
                            <div class="input-group-prepend position-absolute">
                              <button class="" type="button" id="button-addon1">
                                <img src="/static/img/search.svg" width="15" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-2">
                          <a @click="getList(1)" class="btn btn-brand-01 mr-2"
                            >Search
                          </a>
                          <a @click="reset()" class="btn btn-brand-03 mr-2"
                            >Reset
                          </a>
                        </div>
                        <div class="col-lg-6 mb-2 text-right">
                          <a
                            @click="addCommentData()"
                            data-toggle="modal"
                            data-target="#addComment"
                            class="btn btn-brand-02"
                            >+ Add
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-primary mg-b-0">
                        <thead>
                          <tr>
                            <th scope="col">Added By</th>
                            <th
                              scope="col"
                              class="sorting"
                              @click="sorting($event, 'comment')"
                            >
                              Comment
                            </th>
                            <th scope="col">Created Date</th>
                            <th scope="col" class="">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in list" :key="item.id">
                            <td>{{ item.added_by.name }}</td>
                            <td>{{ item.comment }}</td>
                            <td>
                              {{
                                $helperService.getFormattedDate(item.created_at)
                              }}
                            </td>
                            <td>
                              <a
                                title="Edit"
                                class="mx-2"
                                style="cursor: pointer"
                                @click="editComment(item)"
                                data-toggle="modal"
                                data-target="#addComment"
                                ><img src="/static/img/edit-icon.svg" alt=""
                              /></a>
                              <a
                                title="View"
                                class="mx-2"
                                style="cursor: pointer"
                                @click="onDeleteEvent(item)"
                                ><img src="/static/img/delete-icon.svg" alt=""
                              /></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <ErrorComponent
                        @retry="getList(1)"
                        ref="errorComponent"
                      />
                    </div>
                    <div class="row">
                      <div class="col-lg-12 mt-5 text-center">
                        <div class="page-bottom-pagination text-right">
                          <Pagination
                            @page-change="pageChange"
                            @items-per-page-change="itemsPerPageChange"
                            ref="leadPagination"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ConfirmationModal @remove="onDeleteComment" ref="deleteComment" />
            <div
              class="modal fade"
              id="addComment"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      <span v-if="comment.id">Edit Comment</span>
                      <span v-else>Add Comment</span>
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row-sm wb-row-gap">
                      <Form
                        @submit="saveComment"
                        class="columns column is-multiline is-12"
                        data-vv-scope="formAddComment"
                        ref="addEditComment"
                      >
                        <div class="col-lg-12">
                          <div class="card rounded-5">
                            <div class="card-body p-0">
                              <div class="row">
                                <div class="col-lg-12">
                                  <label>
                                    Comment <span class="text-danger">*</span>
                                  </label>
                                  <Field
                                    v-slot="{ field }"
                                    name="comment"
                                    v-model="comment.comment"
                                    rules="required:comment"
                                    :validateOnInput="true"
                                    type="text"
                                    autocapitalize="false"
                                  >
                                    <textarea
                                      v-bind="field"
                                      placeholder="comment"
                                      class="form-control"
                                      v-model="comment.comment"
                                    ></textarea>
                                  </Field>
                                  <ErrorMessage
                                    name="comment"
                                    class="validation-msg"
                                  />
                                </div>
                                <div class="col-lg-12">
                                  <label>Followup Date </label>
                                  <Field
                                    name="followup_at"
                                    class="form-control"
                                    v-slot="{ date }"
                                    rules="required:follow-up date,true"
                                    id="followup_at"
                                    v-model="comment.followup_at"
                                  >
                                    <VueDatePicker
                                      v-bind="date"
                                      placeholder="Select Followup Date"
                                      style="height: 46px; !important"
                                      v-model="comment.followup_at"
                                      :type="boolean"
                                      :format="'dd/MM/yyyy'"
                                      :default="true"
                                      :enable-time-picker="false"
                                    >
                                    </VueDatePicker>
                                  </Field>
                                  <ErrorMessage
                                    name="followup_at"
                                    class="validation-msg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4 mb-2">
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                                data-dismiss="modal"
                                id="cancel-comment-btn"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                id="comment-btn"
                                class="btn btn-brand-01 wb-save-btn"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                  <!-- <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save</button>
                    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Form, Field, ErrorMessage } from "vee-validate";
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import $ from "jquery";
export default {
  name: "AddEditleadManagement",
  components: {
    Multiselect,
    Form,
    Field,
    ErrorMessage,
    Pagination,
    ErrorComponent,
    ConfirmationModal,
    VueDatePicker,
  },
  data() {
    return {
      id: this.$route.params.id,
      leadManagement: {
        name: "",
        branch_id: "",
        status: "",
        lead_status: "",
      },
      leadStatusOption: [
        {
          title: "Cold",
          value: "COLD",
        },
        {
          title: "Hot",
          value: "HOT",
        },
        {
          title: "Warm",
          value: "WARM",
        },
      ],
      statusOption: [
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Inactive",
          value: "INACTIVE",
        },
      ],
      tab: "DETAIL",
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      filterObj: {
        keyword: "",
        branch_ids: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
      },
      delete_id: "",
      comment: {
        comment: "",
        followup_at: "",
      },
      loanType: [],
    };
  },

  computed: {
    branchList() {
      return this.$storeService.getters.getBranches;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
  },
  mounted() {
    this.onCheckUserTire();
  },
  methods: {
    onCheckUserTire() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/loan-type",
        })
        .then((res) => {
          if (this.id) {
            this.getUserInfo();
          }
          // this.loanType = res.list;
          for (var i = 0; i < res.list.length; i++) {
            var obj = {
              title:
                res.list[i].title +
                " (" +
                this.$helperService.getTitleCase(res.list[i].type) +
                ")" +
                " - " +
                this.$helperService.getTitleCase(res.list[i].level),
              value: res.list[i].id,
            };
            this.loanType.push(obj);
          }
        })
        .catch(() => {});
    },
    chnageTab(tab) {
      this.tab = tab;
      if (tab == "COMMENT") {
        this.getList(0);
      } else {
        this.getUserInfo();
      }
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    getUserInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "lead/" + this.id,
        })
        .then((res) => {
          this.leadManagement = res.info;
        })
        .catch(() => {});
    },
    save() {
      var method = "POST";
      var action = "/lead";
      if (this.id) {
        method = "PUT";
        action = "lead/" + this.id;
      }
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: this.leadManagement,
          _buttonId: "save-btn",
          _hide_loader: true,
        })
        .then((res) => {
          this.onClearForm("addEditleadManagement");
          this.$toast.success(res.message, { position: "top-right" });
          this.$router.go(-1);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },

    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
      };
    },
    onDeleteEvent(item) {
      this.delete_id = item.id;
      this.$refs.deleteComment.showModal(
        "Confirmation",
        "Are you sure you want to delete?",
        item
      );
    },
    addCommentData() {
      this.comment.comment = "";
      this.comment.id = "";
    },
    editComment(item) {
      this.comment.comment = item.comment;
      this.comment.id = item.id;
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.leadPagination.itemsPerPage;
      this.getList(1);
    },

    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }

      this.list = [];
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "/lead/" + this.id + "/comments",
          _body: this.filterObj,
        })
        .then((res) => {
          this.list = res.list;
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.leadPagination) {
                this.$refs.leadPagination.setTotalCount(res.count);
              }
            } else {
              if (this.$refs.leadPagination) {
                this.$refs.leadPagination.setTotalCount(0);
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle(
                "Sorry, There is no record found.",
                true
              );
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle();
              }
            }
          }
          if (this.$refs.leadPagination) {
            this.$refs.leadPagination.currentPage = this.currentPage;
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";

      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },

    // On Delete Comment
    onDeleteComment() {
      var method = "DELETE";
      this.$api
        .webRequest({
          _method: method,
          _action: "/lead/" + this.id + "/comment/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteComment.closeModal();
            this.getList(1);
            this.$toast.success(res.message, {
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          this.$refs.deleteComment.removeLoader();
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },

    saveComment() {
      this.comment.followup_at = moment(
        String(this.comment.followup_at)
      ).format("Y-MM-DD");
      var method = "POST";
      var action = "lead/" + this.id + "/comment";
      if (this.comment.id) {
        method = "PUT";
        action = "lead/" + this.id + "/comment/" + this.comment.id;
      }
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: this.comment,
          _buttonId: "comment-btn",
          _hide_loader: true,
        })
        .then((res) => {
          this.onClearForm("addEditComment");
          this.$toast.success(res.message, { position: "top-right" });
          window.$("#cancel-comment-btn").click();
          this.getList(0);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
  },
};
</script>

<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
