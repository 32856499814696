<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="container-fluid px-0">
          <div class="row">
            <div class="col-lg-12">
              <ul id="myTab" role="tablist" class="nav nav-tabs worker_tabs">
                <li class="nav-item">
                  <a id="tab-1" @click="changeTab('PENDING')" data-toggle="tab" href="#tab_1" role="tab"
                    aria-controls="tab_1" aria-selected="true" class="nav-link active">Pending</a>
                </li>
                <li class="nav-item">
                  <a id="tab-2" @click="changeTab('APPROVED')" data-toggle="tab" href="#tab_2" role="tab"
                    aria-controls="tab_2" aria-selected="false" class="nav-link">Approved</a>
                </li>
                <li class="nav-item">
                  <a id="tab-3" @click="changeTab('REJECTED')" data-toggle="tab" href="#tab_3" role="tab"
                    aria-controls="tab_3" aria-selected="false" class="nav-link">Rejected</a>
                </li>
              </ul>
              <div id="myTabContent" class="tab-content">
                <div class="worker_serch_warp">
                  <div class="row">
                    <div class="col-lg-3 mb-2">
                      <div class="search-form position-relative">
                        <input type="text" class="form-control" v-model="filterObj.keyword" v-on:keyup="getList(1)"
                          placeholder="Search" />
                        <div class="input-group-prepend position-absolute">
                          <button class="" type="button" id="button-addon1">
                            <img src="/static/img/search.svg" width="15" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 mb-2" v-if="hasFullAccess">
                      <div class="add_btn_wrapper d-flex flex-row justify-content-end">
                        <Multiselect ref="branch_id_multiselect" name="branch_id" mode="single" trackBy="name"
                          label="name" valueProp="branch_id" placeholder="Select Branch" class="form-control"
                          v-model="filterObj.branch_ids" :options="branchList" searchable="true" />
                      </div>
                    </div>
                    <div class="col-lg-3 mb-2">
                      <div class="add_btn_wrapper d-flex flex-row justify-content-end">
                        <Multiselect ref="category_multiselect" name="category_id" mode="single" trackBy="name"
                          label="name" valueProp="id" placeholder="Select Branch" class="form-control"
                          v-model="filterObj.category_id" :options="categoryList" searchable="true" />
                      </div>
                    </div>
                    <div class="col-lg-3 mb-2 ">
                      <a @click="getList(1)" class="btn btn-brand-01 mr-2">Search
                      </a>
                      <a @click="reset()" class="btn btn-brand-03 mr-2">Reset </a>
                    </div>
                  </div>
                </div>
                <div class="card rounded-5">
                  <div class="card-body p-3 p-lg-3">
                    <div class="table-responsive">
                      <table class="table table-primary mg-b-0">
                        <thead>
                          <tr>
                            <th scope="col" class="sorting" @click="sorting($event, 'name')">
                              Branch
                            </th>
                            <th scope="col" class="sorting" @click="sorting($event, 'name')">
                              Customer
                            </th>
                            <th scope="col" class="sorting" @click="sorting($event, 'name')">
                              Business
                            </th>
                            <th scope="col" class="sorting" @click="sorting($event, 'email')">
                              Category
                            </th>
                            <th scope="col" class="sorting" @click="sorting($event, 'mobile_number')">
                              Mobile Number
                            </th>

                            <th scope="col" class="sorting" @click="sorting($event, 'trx_amount')"
                              v-if="business_offer_active_tab == 'APPROVED'">
                              Approved By
                            </th>

                            <th scope="col" class="sorting" @click="sorting($event, 'trx_amount')"
                              v-if="business_offer_active_tab == 'REJECTED'">
                              Rejected By
                            </th>
                            <th scope="col" class="sorting" @click="sorting($event, 'created_at')"
                              v-if="business_offer_active_tab == 'PENDING'">
                              Created At
                            </th>
                            <th scope="col" class="sorting" @click="sorting($event, 'created_at')"
                              v-if="business_offer_active_tab == 'APPROVED'">
                              Approved At
                            </th>
                            <th scope="col" class="sorting" @click="sorting($event, 'created_at')"
                              v-if="business_offer_active_tab == 'REJECTED'">
                              Rejected At
                            </th>
                            <th scope="col" class="">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="loader">
                            <td colspan="9" class="text-center">
                              <div class="spinner-border spinner-color" role="status">
                              </div>
                            </td>
                          </tr>
                          <tr v-for="item in list" :key="item.id">
                            <td>{{ item?.user?.branch?.name }}</td>
                            <td>{{ item?.user?.name }}</td>
                            <td>{{ item?.title }}</td>
                            <td>{{ item?.category?.name }}</td>
                            <td>{{ item.mobile_number }}</td>
                            <td v-if="business_offer_active_tab == 'APPROVED'">{{ item?.approved_by_user?.name }}</td>
                            <td v-if="business_offer_active_tab == 'REJECTED'">{{ item?.rejected_by_user?.name }}</td>
                            <td v-if="business_offer_active_tab == 'PENDING'">{{
                              $helperService.getFormattedDate(item.created_at) }}</td>
                            <td v-if="business_offer_active_tab == 'APPROVED'">{{
                              $helperService.getFormattedDate(item?.approved_at) }}</td>
                            <td v-if="business_offer_active_tab == 'REJECTED'">{{
                              $helperService.getFormattedDate(item?.rejected_at) }}</td>
                            <td>
                              <a title="View" class="mx-2" style="cursor: pointer" @click="view(item,access.can_update)"><img
                                  src="/static/img/eye-icon.svg" alt="" /></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <ErrorComponent @retry="changeTab(business_offer_active_tab)" ref="errorComponent" />
                    </div>
                    <div class="row">
                      <div class="col-lg-12 mt-5 text-center">
                        <div class="page-bottom-pagination text-right">
                          <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                            ref="rolePagination" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import $ from "jquery";
export default {
  name: "CustomerManagement",
  components: {
    Pagination,
    ErrorComponent,
    Multiselect
  },

  data() {
    return {
      loader: true,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
        branch_ids: "",
      },
      filterObj: {
        keyword: "",
        branch_ids: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        category_id:"",
        level: "",
        status: "",
      },
      delete_id: "",
      statusOption: [
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Inactive",
          value: "INACTIVE",
        },
      ],
      business_offer_active_tab: "PENDING",
      categoryList:[]
    };
  },
 computed:{
  branchList() {
      return this.$storeService.getters.getAllBranches;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
 },
  mounted() {
    this.business_offer_active_tab = localStorage.getItem("business_offer_active_tab")
      ? localStorage.getItem("business_offer_active_tab")
      : "PENDING";
    this.$storeService.commit("setTitle", "Business Offers");
    this.changeTab(this.business_offer_active_tab);
    this.onCategoryList();
  },
  methods: {
    changeTab(tab) {
      this.business_offer_active_tab = tab;
      localStorage.setItem("business_offer_active_tab", tab);
      if (tab == "PENDING") {
        window.$("#tab-1").click();
        this.getList(1);
      } else if (tab == "APPROVED") {
        window.$("#tab-2").click();
        this.getList(1);
      } else if (tab == "REJECTED") {
        window.$("#tab-3").click();
        this.getList(1);
      } else {
        window.$("#tab-1").click();
        this.getList(1);
      }
    },
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "",
        branch_ids: "",
        category_id:""
      };
      this.getList(1);
    },
    view(item,can_update) {
      this.$router.push("/view-business_offer/" + item.uuid + "/" + can_update);
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.rolePagination.itemsPerPage;
      this.getList(1);
    },
    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.business_status = this.business_offer_active_tab;
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }

      this.list = [];
      this.loader = true;

      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "offers",
          _body: this.filterObj,
        })
        .then((res) => {
          if(res.access.can_read == 0){
            return this.$router.go(-1);
          }
          this.loader = false;
          this.list = res.list;
          this.access = res?.access;
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(res.count);
              }
            } else {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(0);
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle("Sorry, There is no record found.",true);
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle();
              }
            }
          }
          if (this.$refs.rolePagination) {
            this.$refs.rolePagination.currentPage = this.currentPage;
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";

      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },
    onCategoryList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/business/categories",
        })
        .then((res) => {
          this.categoryList = res?.list;
        })
        .catch(() => { });
    },
  },
};
</script>

