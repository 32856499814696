<template>
  <div class="content-body">
    <div class="worker_serch_warp">
      <div class="row d-flex justify-content-between">
        <div class="col-md-2 pr-2">
          <h2 class="content-title">
            <a @click="$router.go(-1)" style="cursor: pointer"
              ><i class="fas fa-angle-left mr-2"></i
              ><span v-if="!this.id">Add Loan Type</span>
              <span v-else>Edit Loan Type</span>
            </a>
          </h2>
        </div>
      </div>
    </div>
    <div class="component-section no-code">
      <div class="row-sm wb-row-gap">
        <Form
          @submit="save"
          class="columns column is-multiline is-12"
          data-vv-scope="formAddLoanType"
          ref="addEditLoanType"
        >
          <div class="col-lg-12">
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <div class="row">
                  <!-- <div class="col-lg-4 mb-4">
                                        <label> Title <span class="text-danger">*</span> </label>
                                        <Field name="title" v-model="loanType.title" class="form-control"
                                            rules="required:title" :validateOnInput="true" type="text" placeholder="Title"
                                            autocapitalize="false" />
                                        <ErrorMessage name="title" class="validation-msg" />
                                    </div> -->
                  <!-- <div class="col-lg-4 mb-4">
                                        <label>Loan Type</label>
                                        <div class="d-md-flex mb-3">
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="yes" name="has_secured" :disabled="disable"
                                                    class="custom-control-input" v-model="loanType.has_secured" value="0" />
                                                <label class="custom-control-label" for="yes">UnSecured</label>
                                            </div>
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="no" name="has_secured" :disabled="disable"
                                                    class="custom-control-input" v-model="loanType.has_secured" value="1" />
                                                <label class="custom-control-label" for="no">Secured (Auto Loan)</label>
                                            </div>
                                        </div>

                                    </div> -->
                  <div class="col-lg-4 mb-4">
                    <label
                      >Loan Type
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      v-slot="{ field }"
                      name="type"
                      rules="required:loan type,true"
                      :validateOnInput="true"
                      v-model="loanType.type"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="type_multiselect"
                        mode="single"
                        trackBy="title"
                        label="title"
                        valueProp="value"
                        placeholder="Select Level"
                        class="form-control"
                        v-model="loanType.type"
                        :options="typeOption"
                        :searchable="true"
                        :createOption="true"
                      />
                    </Field>
                    <ErrorMessage name="type" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label
                      >Rate of Interest (%)<span class="text-danger">*</span>
                    </label>
                    <Field
                      name="emi_interest"
                      class="form-control"
                      rules="required:rate of interest|numeric|minValue:0|maxValue:100"
                      :validateOnInput="true"
                      v-model="loanType.emi_interest"
                      type="text"
                      placeholder="Rate of Interest"
                      autocapitalize="false"
                    />
                    <ErrorMessage name="emi_interest" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label
                      >Amortization Interest (%)<span class="text-danger"
                        >*</span
                      >
                    </label>
                    <Field
                      name="amortization_interest"
                      class="form-control"
                      rules="required:amortization interest|numeric|minValue:0|maxValue:100"
                      :validateOnInput="true"
                      v-model="loanType.amortization_interest"
                      type="text"
                      placeholder="Amortization Interest"
                      autocapitalize="false"
                    />
                    <ErrorMessage
                      name="amortization_interest"
                      class="validation-msg"
                    />
                  </div>

                  <div class="col-lg-4 mb-4">
                    <label
                      >Late Payment Interest (%)
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      name="late_payment_interest"
                      class="form-control"
                      rules="required:late payment interest|numeric|minValue:0|maxValue:100"
                      :validateOnInput="true"
                      v-model="loanType.late_payment_interest"
                      type="text"
                      placeholder="Late Payment Interest"
                      autocapitalize="false"
                    />
                    <ErrorMessage
                      name="late_payment_interest"
                      class="validation-msg"
                    />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label
                      >Processing Fee (%)<span class="text-danger">*</span>
                    </label>
                    <Field
                      name="processing_fee"
                      class="form-control"
                      rules="required:processing fee|numeric|minValue:0|maxValue:100"
                      :validateOnInput="true"
                      v-model="loanType.processing_fee"
                      type="text"
                      placeholder="Processing Fee"
                      autocapitalize="false"
                    />
                    <ErrorMessage
                      name="processing_fee"
                      class="validation-msg"
                    />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label>GST (%)<span class="text-danger">*</span> </label>
                    <Field
                      name="gst"
                      class="form-control"
                      rules="required:gst|numeric|minValue:0|maxValue:100"
                      :validateOnInput="true"
                      v-model="loanType.gst"
                      type="text"
                      placeholder="GST"
                      autocapitalize="false"
                    />
                    <ErrorMessage name="gst" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label
                      >No.Of Cycle
                      <span class="text-danger">*</span>
                    </label>

                    <Field
                      name="no_of_cycle"
                      class="form-control"
                      id="no_of_cycle"
                      rules="required:No.Of Cycle|numeric"
                      :validateOnInput="true"
                      v-model="loanType.no_of_cycle"
                      type="text"
                      placeholder="No.Of Cycle"
                      autocapitalize="false"
                    />
                    <ErrorMessage name="no_of_cycle" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label
                      >Level
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      v-slot="{ field }"
                      name="level"
                      rules="required:level,true"
                      :validateOnInput="true"
                      v-model="loanType.level"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="level_multiselect"
                        mode="single"
                        trackBy="title"
                        label="title"
                        valueProp="value"
                        placeholder="Select Level"
                        class="form-control"
                        v-model="loanType.level"
                        :options="levelOption"
                        :searchable="true"
                        :createOption="true"
                      />
                    </Field>
                    <ErrorMessage name="level" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label
                      >Payment Frequency
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      v-slot="{ data }"
                      name="payment_frequency"
                      rules="required:payment frequency,true"
                      :validateOnInput="true"
                      v-model="payment_frequency"
                    >
                      <Multiselect
                        v-bind="data"
                        ref="payment_frequency_multiselect"
                        mode="single"
                        trackBy="title"
                        label="title"
                        valueProp="value"
                        placeholder="Select Payment Frequency"
                        class="form-control"
                        v-model="payment_frequency"
                        :options="PaymentFrequencyList"
                        :searchable="true"
                        :createOption="true"
                      />
                    </Field>
                    <ErrorMessage
                      name="payment_frequency"
                      class="validation-msg"
                    />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label> Status <span class="text-danger">*</span> </label>
                    <Field
                      v-slot="{ field }"
                      name="status"
                      rules="required:status,true"
                      :validateOnInput="true"
                      v-model="loanType.status"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="status_multiselect"
                        mode="single"
                        trackBy="title"
                        rules="required:true"
                        label="title"
                        valueProp="value"
                        placeholder="Select Status"
                        class="form-control"
                        v-model="loanType.status"
                        :options="statusOption"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="status" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label
                      >Enable Renew after # of Cycle<span class="text-danger"
                        >*</span
                      >
                    </label>
                    <Field
                      name="no_of_cycle_for_renew"
                      class="form-control"
                      rules="required:enable renew after cycle of interest|numeric"
                      :validateOnInput="true"
                      v-model="loanType.no_of_cycle_for_renew"
                      type="text"
                      placeholder="Enable Renew after # of Cycle"
                      autocapitalize="false"
                    />
                    <ErrorMessage
                      name="no_of_cycle_for_renew"
                      class="validation-msg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card rounded-5 mt-3" v-if="this.id">
              <div class="row m-3">
                <div class="col-lg-6">
                  <h3>CheckList</h3>
                </div>

                <div
                  class="col-lg-6 text-right pb-3"
                  v-if="checklist_access.can_create == 1"
                >
                  <a @click="add('CHECKLIST')" class="btn btn-brand-02 mr-2"
                    >+ Add Checklist
                  </a>
                </div>

                <div class="table-responsive">
                  <table class="table table-primary mg-b-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'title')"
                        >
                          Title
                        </th>
                        <th scope="col" class="">Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="checklistList.length">
                      <tr v-for="item in checklistList" :key="item.id">
                        <td>{{ item.title }}</td>
                        <td>
                          <a
                            title="Edit"
                            class="mx-2"
                            style="cursor: pointer"
                            v-if="checklist_access.can_update == 1"
                            @click="edit('CHECKLIST', item)"
                            ><img src="/static/img/edit-icon.svg" alt=""
                          /></a>
                          <a
                            title="View"
                            class="mx-2"
                            style="cursor: pointer"
                            v-if="checklist_access.can_delete == 1"
                            @click="onDeleteEvent('CHECKLIST', item)"
                            ><img src="/static/img/delete-icon.svg" alt=""
                          /></a>
                        </td>
                      </tr>
                    </tbody>
                    <template v-else>
                      <div class="text-center m-5">
                        <h3>Sorry,record not found.</h3>
                      </div>
                    </template>
                  </table>
                </div>
              </div>
            </div>
            <div class="card rounded-5 mt-3" v-if="this.id">
              <div class="row m-3">
                <div class="col-lg-6">
                  <h3>Document</h3>
                </div>

                <div class="col-lg-6 text-right pb-3">
                  <a
                    @click="add('DOCUMENT')"
                    class="btn btn-brand-02 mr-2"
                    v-if="doc_access.can_create == 1"
                    >+ Add Document
                  </a>
                </div>

                <div class="table-responsive">
                  <table class="table table-primary mg-b-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'title')"
                        >
                          Title
                        </th>
                        <th scope="col" class="">Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="documentList.length">
                      <tr v-for="item in documentList" :key="item.id">
                        <td>{{ item.title }}</td>
                        <td>
                          <a
                            title="Edit"
                            class="mx-2"
                            style="cursor: pointer"
                            v-if="doc_access.can_update == 1"
                            @click="edit('DOCUMENT', item)"
                            ><img src="/static/img/edit-icon.svg" alt=""
                          /></a>
                          <a
                            title="View"
                            class="mx-2"
                            style="cursor: pointer"
                            v-if="doc_access.can_delete == 1"
                            @click="onDeleteEvent('DOCUMENT', item)"
                            ><img src="/static/img/delete-icon.svg" alt=""
                          /></a>
                        </td>
                      </tr>
                    </tbody>

                    <template v-else>
                      <div class="text-center m-5">
                        <h3>Sorry,record not found.</h3>
                      </div>
                    </template>
                  </table>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-3">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                  @click="$router.go(-1)"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  id="save-btn"
                  class="btn btn-brand-01 wb-save-btn"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>

    <div
      class="modal fade wb-modal-wrapper"
      id="addDocument"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addDocument"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="closeModal()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>{{ document_title }}</span>
            </h5>
          </div>
          <Form
            @submit="addDocument"
            class="columns column is-multiline is-12"
            ref="addDocument"
          >
            <div class="modal-body form-style pb-0 px-lg-5">
              <div class="signin-form">
                <div class="col-lg-12 mb-4">
                  <div class="form-group">
                    <label
                      >Title
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      name="loan_title"
                      v-model="title"
                      class="form-control"
                      rules="required:title"
                      :validateOnInput="true"
                      type="text"
                      placeholder="Title"
                      autocapitalize="false"
                    />
                    <ErrorMessage name="loan_title" class="validation-msg" />
                  </div>
                </div>

                <div class="col-lg-12 mb-4" v-if="type == 'CHECKLIST'">
                  <label>Is Location</label>
                  <div class="d-md-flex mb-3">
                    <div class="custom-control custom-radio mb-2 mr-3">
                      <input
                        type="radio"
                        id="is_locationyes"
                        name="is_location"
                        class="custom-control-input"
                        v-model="is_location"
                        :value="1"
                      />
                      <label class="custom-control-label" for="is_locationyes"
                        >Yes</label
                      >
                    </div>
                    <div class="custom-control custom-radio mb-2 mr-3">
                      <input
                        type="radio"
                        id="is_locationno"
                        name="is_location"
                        class="custom-control-input"
                        v-model="is_location"
                        :value="0"
                      />
                      <label class="custom-control-label" for="is_locationno"
                        >No</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mb-3">
              <button
                id="cancel-btn"
                @click="closeModal()"
                type="button"
                class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-brand-01 wb-save-btn"
                id="update-password"
              >
                Save
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <ConfirmationModal
      @remove="onDeleteLeadManagement"
      ref="deleteLeadManagement"
    />
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Form, Field, ErrorMessage } from "vee-validate";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
export default {
  name: "AddEditLoanType",
  components: {
    Multiselect,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      loanType: {
        title: "",
        level: "",
        type: "",
        processing_fee: "",
        gst: "",
        status: "",
        no_of_cycle: "",
        emi_interest: "",
        late_payment_interest: "",
        has_secured: 0,
        no_of_cycle_for_renew: "",
      },
      checklistList: [],
      documentList: [],
      levelOption: [
        {
          title: "Platinum",
          value: "PLATINUM",
        },
        {
          title: "Gold",
          value: "GOLD",
        },
        {
          title: "Silver",
          value: "SILVER",
        },
        {
          title: "Bronze",
          value: "BRONZE",
        },
      ],
      typeOption: [
        {
          title: "New Secured",
          value: "NEW_SECURED",
        },
        {
          title: "New Unsecured",
          value: "NEW_UNSECURED",
        },
        {
          title: "Renew Secured",
          value: "RENEW_SECURED",
        },
        {
          title: "Renew Unsecured",
          value: "RENEW_UNSECURED",
        },
      ],
      statusOption: [
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Inactive",
          value: "INACTIVE",
        },
      ],
      checklist_access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      doc_access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      type: "",
      title: "",
      is_location: 0,
      document_title: "",
      document_id: "",
      delete_id: "",
      disable: false,
      PaymentFrequencyList: [],
      payment_frequency: "",
    };
  },
  mounted() {
    if (this.id) {
      this.getUserInfo();
      this.disable = true;
    }
    this.getPaymentFrequency();
  },
  methods: {
    onDeleteEvent(type, item) {
      this.type = type;
      this.delete_id = item.uuid;
      this.$refs.deleteLeadManagement.showModal(
        "Confirmation",
        "Are you sure you want to delete?",
        item
      );
    },
    add(type) {
      this.type = type;
      this.title = "";
      this.is_location = 0;
      if (type == "CHECKLIST") {
        this.document_title = "Add Checklist";
      } else {
        this.document_title = "Add Document";
      }
      window.$("#addDocument").modal("show");
    },
    edit(type, item) {
      this.type = type;
      this.document_id = item.uuid;
      this.title = item.title;
      if (type == "CHECKLIST") {
        this.is_location = item.is_location;
        this.document_title = "Edit Checklist";
      } else {
        this.is_location = 0;
        this.document_title = "Edit Document";
      }
      window.$("#addDocument").modal("show");
    },
    closeModal() {
      window.$("#addDocument").modal("hide");
      this.onClearForm("addDocument");
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    addDocument() {
      console.log(this.title);
      console.log(this.is_location);
      var obj = {
        title: this.title,
        is_location: this.is_location,
      };
      var method = "";
      var action = "";
      if (this.type == "CHECKLIST") {
        method = "POST";
        action = "/loan-type/" + this.id + "/checklist";
        if (this.document_id) {
          method = "PUT";
          action = "loan-type/" + this.id + "/checklist/" + this.document_id;
        }
      } else {
        method = "POST";
        action = "/loan-type/" + this.id + "/document";
        if (this.document_id) {
          method = "PUT";
          action = "loan-type/" + this.id + "/document/" + this.document_id;
        }
      }
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: obj,
          _buttonId: "update-password",
          _hide_loader: true,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.closeModal();
          if (this.type == "CHECKLIST") {
            this.getCheckList();
          } else {
            this.getDocument();
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
      // console.log(this.checklistList);
      // console.log(this.documentList);
    },
    getUserInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan-type/" + this.id,
        })
        .then((res) => {
          //  this.loanType = res.info;
          this.loanType.emi_interest = res?.info?.emi_interest;
          this.loanType.has_secured = res?.info?.has_secured;
          this.loanType.late_payment_interest =
            res?.info?.late_payment_interest;
          this.loanType.level = res?.info?.level;
          this.loanType.no_of_cycle = res?.info?.no_of_cycle;
          this.loanType.status = res?.info?.status;
          this.loanType.type = res?.info?.type;
          this.loanType.processing_fee = res?.info?.processing_fee;
          this.loanType.gst = res?.info?.gst;
          this.payment_frequency = res?.info?.payment_frequency?.value;
          this.loanType.no_of_cycle_for_renew =
            res?.info?.no_of_cycle_for_renew;
          this.loanType.amortization_interest =
            res?.info?.amortization_interest;
          this.getCheckList();
          this.getDocument();
        })
        .catch(() => {});
    },
    getCheckList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan-type/" + this.id + "/checklists",
        })
        .then((res) => {
          if (res?.access?.can_update == 0) {
            return this.$router.go(-1);
          }
          this.checklistList = res.list;
          this.checklist_access = res?.access;
        })
        .catch(() => {});
    },
    getDocument() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan-type/" + this.id + "/documents",
        })
        .then((res) => {
          if (res?.access?.can_update == 0) {
            return this.$router.go(-1);
          }
          this.documentList = res.list;
          this.doc_access = res?.access;
        })
        .catch(() => {});
    },
    getPaymentFrequency() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/payment-frequencies",
        })
        .then((res) => {
          this.PaymentFrequencyList = res.list;
        })
        .catch(() => {});
    },
    save() {
      this.loanType.payment_frequency = this.payment_frequency;
      var method = "POST";
      var action = "/loan-type";
      if (this.id) {
        method = "PUT";
        action = "loan-type/" + this.id;
      }
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: this.loanType,
          _buttonId: "save-btn",
          _hide_loader: true,
        })
        .then((res) => {
          this.onClearForm("addEditLoanType");
          this.$toast.success(res.message, { position: "top-right" });
          this.$router.go(-1);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onDeleteLeadManagement() {
      var method = "DELETE";
      var action = "";
      if (this.type == "CHECKLIST") {
        action = "loan-type/" + this.id + "/checklist/" + this.delete_id;
      } else {
        action = "loan-type/" + this.id + "/document/" + this.delete_id;
      }
      this.$api
        .webRequest({
          _method: method,
          _action: action,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteLeadManagement.closeModal();
            this.getUserInfo();
            this.$toast.success(res.message, {
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          this.$refs.deleteLeadManagement.removeLoader();
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },
  },
};
</script>

<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
