<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="row row-sm">
          <div class="col-lg-12">
            <div class="worker_serch_warp">
              <div class="row">
                <div class="col-lg-2 mb-2">
                  <div class="search-form position-relative">
                    <input type="text" class="form-control" v-model="filterObj.keyword" placeholder="Search" @keyup="getList(1)" />
                    <div class="input-group-prepend position-absolute">
                      <button class="" type="button" id="button-addon1">
                        <img src="/static/img/search.svg" width="15" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 mb-2">
                  <div class="add_btn_wrapper d-flex flex-row justify-content-end">
                    <Multiselect ref="status_multiselect" name="status" mode="single" trackBy="title" label="title"
                      valueProp="value" placeholder="Select Status" class="form-control" v-model="filterObj.status"
                      :options="statusOption" :searchable="true" />
                  </div>
                </div>
                <div class="col-lg-2 mb-2" v-if="hasFullAccess">
                  <div class="add_btn_wrapper d-flex flex-row justify-content-end">
                    <Multiselect ref="branch_id_multiselect" name="branch_id" mode="single" trackBy="name"
                      label="name" valueProp="branch_id" placeholder="Select Branch" class="form-control"
                      v-model="filterObj.branch_ids" :options="branchList" searchable="true" />
                  </div>
                </div>
                <div class="col-lg-3 mb-2">
                  <a @click="getRoles(1)" class="btn btn-brand-01 mr-2">Search
                  </a>
                  <a @click="reset()" class="btn btn-brand-03 mr-2">Reset
                  </a>
                </div>
                <div class="col-lg-  mb-2 text-right">
                  <a @click="addRole()" class="btn btn-brand-02" v-if="access.can_create == 1">+ Add
                  </a>
                </div>
              </div>
            </div>
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <div class="table-responsive">
                  <table class="table table-primary mg-b-0">
                    <thead>
                      <tr>
                        <th scope="col" class="sorting" @click="sorting($event, 'role_name')">
                          Role Name
                        </th>
                        <th scope="col" class="sorting" @click="sorting($event, 'status')">
                          Status
                        </th>
                        <th scope="col" class="">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loader">
                        <td colspan="3" class="text-center">
                          <div class="spinner-border spinner-color" role="status">
                          </div>
                        </td>
                      </tr>
                      <tr v-else v-for="item in list" :key="item.id">
                        <td>{{ item.role }}</td>
                        <td> <span :style="item.status == 'ACTIVE' ? 'color:#5CC82A' : 'color: #DADADA'">{{
                          $helperService.getTitleCase(item.status) }}</span>
                        </td>
                        <td>
                          <a title="Edit" class="mx-2" style="cursor: pointer" @click="editRole(item)" v-if="access.can_update == 1 &&
                            item.is_editable != 'N' &&
                            my_role_id != item.id
                            "><img src="/static/img/edit-icon.svg" alt="" /></a>
                          <a title="View" class="mx-2" v-else style="cursor: pointer" @click="viewRole(item,'VIEW')"><img
                              src="/static/img/eye-icon.svg" alt="" /></a>
                          <a title="Delete" class="mx-2"
                            v-if="access.can_delete == 1 && item.is_editable != 'N' && my_role_id != item.id"
                            style="cursor: pointer" @click="onDeleteEvent(item.id)"><img src="/static/img/delete-icon.svg"
                              alt="" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ErrorComponent @retry="getRoles(1)" ref="errorComponent" />
                </div>
                <div class="row">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="rolePagination" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal @remove="onDeleteRole" ref="deleteRole" />
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import $ from "jquery";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
export default {
  name: "RoleManagement",
  components: {
    Pagination,
    ErrorComponent,
    ConfirmationModal, Multiselect
  },

  data() {
    return {
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      filterObj: {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "",
        branch_ids:""
      },
      delete_id: "",
      my_role_id: "",
      statusOption: [{
        "title": "Active", "value": "ACTIVE",
      }, {
        "title": "Inactive", "value": "INACTIVE",
      }],
      loader: false
    };
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getAllBranches;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    }
  },
  mounted() {
    this.$storeService.commit("setTitle", "Role Management");
    this.my_role_id = localStorage.getItem("my_role_id");
    this.getRoles(1);
  },
  methods: {
    onDeleteEvent(id) {
      this.delete_id = id;
      this.$refs.deleteRole.showModal("Confirmation", "Are you sure you want to delete?");
    },
    addRole() {
      this.$router.push("/add-role");
    },
    editRole(item) {
      this.$router.push("/edit-role/" + item.id);
    },
    viewRole(item,type) {
      this.$router.push("/view-role/" + item.id +"/"+type);
    },
    pageChange(page) {
      this.getRoles(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.rolePagination.itemsPerPage;
      this.getRoles(1);
    },
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "",
        branch_ids:"",
      }
      this.getList(1);
    },
    retry() {
      this.getRoles(1);
    },
    getRoles(page, sortBy, orderBy) {
      // this.filterObj.status = status;
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }
      this.loader = true;
      this.list = [];
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "/roles",
          _body: this.filterObj,
        })
        .then((res) => {
          this.loader = false;
          if(res.access.can_read == 0){
            return this.$router.go(-1);
          }
          this.list = res.list;
          this.access = res.access;
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(res.count);
              }
            } else {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(0);
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle("Sorry, There is no record found.",true);
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle();
              }
            }
          }
          if (this.$refs.rolePagination) {
            this.$refs.rolePagination.currentPage = this.currentPage;
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";

      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getRoles(1, sortBy, orderBy);
    },

    // On Delete Role 
    onDeleteRole() {
      var method = "DELETE";
      this.$api
        .webRequest({
          _method: method,
          _action: "role/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteRole.closeModal();
            this.getRoles(1);
            this.$toast.success(res.message, {
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          this.$refs.deleteRole.removeLoader();
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },

  },
};
</script>
