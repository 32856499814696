<template>
    <div class="worker_serch_warp">
        <div class="row d-flex justify-content-between">
            <div class="col-md-4 pr-2 mx-2">
                <h2 class="content-title">
                    <a @click="$router.go(-1)" style="cursor: pointer"><i class="fas fa-angle-left mr-2"></i>
                        <span v-if="status == 'PENDING'">Pending Expense Request</span>
                        <span v-if="status == 'REJECTED'">Rejected Expense Request</span>
                        <span v-if="status == 'APPROVED'">Approved Expense Request</span> </a>
                </h2>
            </div>
        </div>
    </div>
    <div class="component-section no-code">
        <div class="row mx-0">
            <div class="col-lg-12">
                <div class="card rounded-2" style="border-radius: 10px;">
                    <!-- <div style="border-bottom: 1px solid #ccc;" class="card-header pb-2 pt-3">
                        <h4 class="text-bolder">Basic Details</h4>
                    </div> -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3">
                                <h5 class="label-text">Name</h5>
                                <h5 class="label-text">Date</h5>
                                <h5 class="label-text">Expense</h5>
                                <h5 class="label-text">Amount</h5>
                            </div>
                            <div class="col-lg-6">
                                <h5 class="text-bolder">{{ loanApplication.employee?.name }}</h5>
                                <h5 class="text-bolder">{{ $helperService.getFormattedDate(loanApplication.created_at) }}
                                </h5>
                                <h5 class="text-bolder">{{ loanApplication?.expense_category?.name }}</h5>
                                <h5 class="text-bolder">{{ $helperService.getFormattedCurrency(loanApplication.amount) }}</h5>
                            </div>
                            <div class="col-lg-3" v-if="loanApplication.bill_img">
                                <img :src="loanApplication.bill_img ? loanApplication.bill_img : '/static/img/placeholder.png'"
                                    class="img-rounded" style="witdh:100px;height:100px" alt="">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-12 pt-3" v-if="status == 'PENDING'">
                <div class="card rounded-2" style="border-radius: 10px;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3">
                                <h5 class="label-text">Added Name</h5>
                                <h5 class="label-text">Added Date</h5>
                            </div>
                            <div class="col-lg-9">
                                <h5 class="text-bolder">{{ loanApplication.added_by?.name }}</h5>
                                <h5 class="text-bolder">{{ $helperService.getFormattedDate(loanApplication.created_at) }}
                                </h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-12 pt-3" v-if="status == 'APPROVED'">
                <div class="card rounded-2" style="border-radius: 10px;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3">
                                <h5 class="label-text">Approved Name</h5>
                                <h5 class="label-text">Approved Date</h5>
                            </div>
                            <div class="col-lg-9">
                                <h5 class="text-bolder">{{ loanApplication.approved_by_user?.name }}</h5>
                                <h5 class="text-bolder">{{ $helperService.getFormattedDate(loanApplication.approved_at) }}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 pt-3" v-if="status == 'REJECTED'">
                <div class="card rounded-2" style="border-radius: 10px;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3">
                                <h5 class="label-text">Rejected Name</h5>
                                <h5 class="label-text">Rejected Date</h5>
                                <h5 class="label-text">Rejected reason</h5>
                            </div>
                            <div class="col-lg-9">
                                <h5 class="text-bolder">{{ loanApplication.rejected_by_user?.name }}</h5>
                                <h5 class="text-bolder">{{ $helperService.getFormattedDate(loanApplication.rejected_at) }}
                                </h5>
                                <h5 class="text-bolder">{{ loanApplication.narration }}</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-12 pt-3" v-if="status == 'PENDING' && access == 1">
                <div class="row">
                    <div class="col-lg-12 d-flex justify-content-center">
                        <button id="cancel-btn" type="button" @click="onUpdateStatus('REJECTED')"
                            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
                            Reject
                        </button>
                        <button type="submit" @click="onUpdateStatus('APPROVED')" class="btn btn-brand-01 wb-save-btn">
                            Approve
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade wb-modal-wrapper" id="openRejectNarration" tabindex="-1" role="dialog"
        aria-labelledby="openRejectNarration" aria-hidden="true" style="pointer-events: none">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <a class="close mr-3 mt-3 text-right" @click="closeNarrationModal()" aria-label="Close">
                    <img src="/static/img/close-icon.svg" width="20" />
                </a>
                <div class="modal-header justify-content-center" v-if="reason_type == 'REJECTED'">
                    <h5 class="modal-title font-22">
                        <span>Reason</span>
                    </h5>
                </div>
                <div class="modal-header justify-content-center" v-if="reason_type == 'APPROVED'">
                    <h5 class="modal-title font-22">
                        <span>Approve</span>
                    </h5>
                </div>
                <div class="modal-body text-center" v-if="reason_type == 'APPROVED'">
                    <p>Are you sure you want to approve ?</p>
                </div>
                <Form @submit="onAddRejectNarration" class="columns column is-multiline is-12" ref="onAddRejectNarration"
                    v-if="reason_type == 'REJECTED'">
                    <div class="modal-body form-style pb-0 px-lg-5">
                        <div class="signin-form">
                            <div class="form-group">
                                <label>Reason
                                    <span class="text-danger">*</span>
                                </label>
                                <Field v-slot="{ field }" name="level" rules="required:reason,true" :validateOnInput="true"
                                    v-model="narration">
                                    <textarea v-bind="field" rows="5" cols="5" valueProp="id"
                                        placeholder="Enter Reject Reason" class="form-control" v-model="narration" />
                                </Field>
                                <ErrorMessage name="level" class="validation-msg" />
                            </div>
                        </div>
                    </div>
                </Form>
                <div class="modal-footer border-0 justify-content-center mb-3">
                    <button id="cancel-btn" @click="closeNarrationModal()" type="button"
                        class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-brand-01 wb-cancel-btn" id="status-btn"
                        v-if="reason_type == 'APPROVED'" @click="onAddRejectNarration">
                        Approve
                    </button>
                    <button type="submit" class="btn btn-danger wb-cancel-btn" id="status-btn"
                        v-if="reason_type == 'REJECTED'" @click="onAddRejectNarration">
                        Reject
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    name: "AddEditLoanApplication",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            id: this.$route.params.id,
            access: this.$route.params.access,
            loanApplication: {
            },
            narration: "",
            reason_type: "",
            status: [],
            url: "",
            title: ""
        };
    },
    mounted() {
        if (this.id) {
            this.getUserInfo();
        }
    },
    methods: {

        onNarrtion() {
            window.$("#openRejectNarration").modal("show");
        },
        closeNarrationModal() {
            this.narration = null;
            this.reason_type = null;
            window.$('#openRejectNarration').modal('hide');
            if (this.reason_type == 'REJECTED') {
                this.onClearForm("onAddRejectNarration");
            }
        },
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },
        getUserInfo() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "employee/expense/" + this.id,
                })
                .then((res) => {
                    this.loanApplication = res?.info;
                    this.status = this.loanApplication.status;
                })
                .catch(() => { });
        },

        onUpdateStatus(status) {
            this.reason_type = status;
            window.$("#openRejectNarration").modal("show");

        },
        onAddRejectNarration() {
            var button_id = "status-btn";
            this.$api.webRequest({
                _method: "PUT",
                _action: "employee/expense/" + this.id + "/status",
                _buttonId: button_id,
                _body: { status: this.reason_type, narration: this.narration }
            }).then((res) => {
                this.closeNarrationModal();
                this.$toast.success(res.message, { position: "top-right" });
                this.getUserInfo();
            }).catch((e) => {
                this.$toast.error(e.message, { position: "top-right" });
            })
        },
        download(url, title) {
            this.url = url;
            this.title = title;
            window.$("#viewModal").modal("show");
        },
        selectFile(e) {
            e.stopImmediatePropagation();
            window.$("#selectFile").click();
        },
        onDocumentChange(e) {
            let files = e.target.files;
            if (files.length > 0) {
                this.fileName = files[0].name;
                this.kycDcoument = files[0];
            } else {
                this.fileName = null;
                this.kycDcoument = null;
            }
        },
    },
};
</script>

<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>