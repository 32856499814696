<template>
    <div class="content-body">
        <div class="worker_serch_warp">
            <div class="row d-flex justify-content-between">
                <div class="col-md-2 pr-2">
                    <h2 class="content-title">
                        <a style="cursor: pointer">Manage Settings </a>
                    </h2>
                </div>
            </div>
        </div>
        <div class="component-section no-code">
            <div class="row-sm wb-row-gap">
                <Form @submit="save" class="columns column is-multiline is-12" data-vv-scope="formAddBranch"
                    ref="addEditBranch">
                    <div class="col-lg-12">
                        <div class="card rounded-5">
                            <div class="card-body p-3 p-lg-3">
                                <div class="row">
                                    <div class="col-lg-4 mb-4">
                                        <label> Case Handling Charge (IN Percent) <span class="text-danger">*</span> </label>
                                        <Field name="name" v-model="setting.case_handling_charge" class="form-control" rules="required:cash handling charge"
                                            :validateOnInput="true" type="text" placeholder="Name" autocapitalize="false" />
                                        <ErrorMessage name="name" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> Documentation Charge (IN Percent)<span class="text-danger">*</span> </label>
                                        <Field name="documentation_charge" v-model="setting.documentation_charge" class="form-control" rules="required:documentation charge"
                                            :validateOnInput="true" type="text" placeholder="Documentation Charge" autocapitalize="false" />
                                        <ErrorMessage name="documentation_charge" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> Postal Charge <span class="text-danger">*</span> </label>
                                        <Field name="postal_charge" v-model="setting.postal_charge" class="form-control" rules="required:postal charge"
                                            :validateOnInput="true" type="text" placeholder="Postal Charge" autocapitalize="false" />
                                        <ErrorMessage name="postal_charge" class="validation-msg" />
                                    </div>
                                    <!-- <div class="col-lg-4 mb-4">
                                        <label> Processing Fee (IN Percent) <span class="text-danger">*</span> </label>
                                        <Field name="processing_fee_percent" v-model="setting.processing_fee_percent" class="form-control" rules="required:processing fee percentage"
                                            :validateOnInput="true" type="text" placeholder="Processing Fee" autocapitalize="false" />
                                        <ErrorMessage name="processing_fee_percent" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> GST for Processing Fee (IN Percent) <span class="text-danger">*</span> </label>
                                        <Field name="processing_fee_gst" v-model="setting.processing_fee_gst" class="form-control" rules="required:processing fee gst"
                                            :validateOnInput="true" type="text" placeholder="GST for Processing Fee" autocapitalize="false" />
                                        <ErrorMessage name="processing_fee_gst" class="validation-msg" />
                                    </div> -->
                                    <div class="col-lg-4 mb-4">
                                        <label> CIBIL Member Number <span class="text-danger">*</span> </label>
                                        <Field name="current_new_member" v-model="setting.current_new_member" class="form-control" rules="required:CIBIL member number"
                                            :validateOnInput="true" type="text" placeholder="CIBIL Member Number" autocapitalize="false" />
                                        <ErrorMessage name="current_new_member" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> CIBIL Short Code <span class="text-danger">*</span> </label>
                                        <Field name="cibil_short_code" v-model="setting.cibil_short_code" class="form-control" rules="required:CIBIL short code"
                                            :validateOnInput="true" type="text" placeholder="CIBIL Short Code" autocapitalize="false" />
                                        <ErrorMessage name="cibil_short_code" class="validation-msg" />
                                    </div>
                                    <!-- <div class="col-lg-4 mb-4">
                                        <label> Company Royalty (IN Percent) <span class="text-danger">*</span> </label>
                                        <Field name="company_royalty_percent" v-model="setting.company_royalty_percent" class="form-control" rules="required:company royalty"
                                            :validateOnInput="true" type="text" placeholder="Company Royalty" autocapitalize="false" />
                                        <ErrorMessage name="company_royalty_percent" class="validation-msg" />
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5 mb-3">
                            <div class="col-12 text-center">
                                <button type="submit" id="save-btn" class="btn btn-brand-01  wb-save-btn">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>
<script>
// import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    name: "ManageSetting",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            id:"",
            setting: {
                case_handling_charge: "",
                documentation_charge: "",
                postal_charge: "",
                processing_fee_percent: "",
                processing_fee_gst: "",
                current_new_member: "",
                cibil_short_code: "",
                company_royalty_percent: ""
            },
            statusOption: [{
                "title": "Active", "value": "ACTIVE",
            }, {
                "title": "Inactive", "value": "INACTIVE",
            }]
        };
    },
    mounted() {
            this.getDetail();this.$storeService.commit("setTitle", "Manage Settings");
    },
    methods: {
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },
        getDetail() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "setting-info",
                })
                .then((res) => {
                    this.setting = res.info;
                    this.id = res?.info?.id;
                })
                .catch(() => { });
        },
        save() {
            let method = "PUT";
            var action = `/update-setting/${this.id}`;
            this.$api
                .webRequest({
                    _method: method,
                    _action: action,
                    _body: this.setting,
                    _buttonId: "save-btn",
                    _hide_loader: true,
                })
                .then((res) => {
                    this.$toast.success(res.message, { position: "top-right" });
                    this.getDetail();
                })
                .catch((e) => {
                    this.$toast.error(e.message, { position: "top-right" });
                });
        },
    },
};
</script>

<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>