<template>
  <div>
    <div class="content-body">
      <div class="container-fluid px-0 mt-4">
        <div class="row">
          <div class="col-lg-12 col-sm-12 mb-3"  @click="$router.push('/office')">
            <div class="dsh-box1 text-left" style="background-color: #FFF1C6;">
              <div class="row" >
                <div class="col-lg-6 col-sm-6">
                  <img
                    src="static/img/expense_1.svg"
                    width="50"
                    height="50"
                    class="float-left mr-3"
                  />
                  <p class="text-left">Branch Expense</p>
                </div>
                <div class="col-lg-6 col-sm-6 pt10 text-right">
                  <a href="#">
                    <img src="static/img/next_arrow.svg"
                    width="30"
                    height="30" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 mb-3"  @click="$router.push('/employee')">
            <div class="dsh-box2 text-left" style="background-color: #C6F8FF;">
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <img
                    src="static/img/employee.svg"
                    width="50"
                    height="50"
                    class="float-left mr-3"
                  />
                  <p class="text-left">Employee Expense</p>
                </div>
                <div class="col-lg-6 col-sm-6 pt10 text-right">
                  <a href="#">
                    <img src="static/img/next_arrow.svg"
                    width="30"
                    height="30" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 mb-3"  @click="$router.push('/employee-account')">
            <div class="dsh-box3 text-left" style="background-color: #FFD0C6;">
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <img
                    src="static/img/account_1.svg"
                    width="50"
                    height="50"
                    class="float-left mr-3"
                  />
                  <p class="text-left">Ledger</p>
                </div>
                <div class="col-lg-6 col-sm-6 pt10 text-right">
                  <a href="#">
                    <img src="static/img/next_arrow.svg"
                    width="30"
                    height="30" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExpenseManagement",
  mounted() {
        this.$storeService.commit("setTitle", "Expense Management");},
  data() {
    return {};
  },
  methods: {},
};
</script>
