<template>
  <div class="content-body">
    <div class="worker_serch_warp">
      <div class="row d-flex justify-content-between">
        <div class="col-md-2 pr-2" v-if="!this.id">
          <h2 class="content-title">
            <a @click="$router.go(-1)" style="cursor: pointer"><i class="fas fa-angle-left mr-2"></i>Add Roles</a>
          </h2>
        </div>
        <div class="col-md-2 pr-2" v-else>
          <h2 class="content-title">
            <a @click="$router.go(-1)" style="cursor: pointer"><i class="fas fa-angle-left mr-2"></i>Edit Roles</a>
          </h2>
        </div>
      </div>
    </div>
    <div class="component-section no-code">
      <Form @submit="save()" ref="addEditRole" class="columns column is-multiline is-12" data-vv-scope="fromRole">
        <div class="row row-sm wb-row-gap">
          <div class="col-lg-12">
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <div class="row">
                  <div class="col-lg-4 mb-4">
                    <label> Role Name<span class="text-danger">*</span> </label>
                    <Field v-model="role.name" name="role_name" rules="required" :validateOnInput="true" type="text"
                      placeholder="Role Name" class="form-control" />
                    <ErrorMessage name="role_name" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label> Role Type <span class="text-danger">*</span> </label>
                    <Field v-slot="{ role }" name="role_type" rules="required:role type,true" :validateOnInput="true"
                      v-model="role_type">
                      <Multiselect v-bind="role" ref="role_multiselect" mode="single" trackBy="type" rules="required"
                        label="title" valueProp="type" placeholder="select role type" class="form-control"
                        v-model="role_type" :options="roleTypes" :searchable="true" :createOption="true" />
                    </Field>
                    <ErrorMessage name="role_type" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label> Branch </label>
                    <Field v-slot="{ branch }" name="branch_id" :validateOnInput="true" v-model="branch_id">
                      <Multiselect v-bind="branch" ref="branch_multiselect" mode="single" trackBy="name" rules="required"
                        label="name" valueProp="uuid" placeholder="Select Branch" class="form-control" v-model="branch_id"
                        :options="branchList" searchable="true"  :close-on-select="true"  />
                    </Field>
                    <ErrorMessage name="branch_id" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <Field v-slot="{ final_approve }" name="can_final_approve" :validateOnInput="true" v-model="role.can_final_approve">
                      <input
                      class="mx-2"
                        id="flexCheckChecked"
                        type="checkbox"
                         v-bind="final_approve"
                        v-bind:true-value="1"
                        v-bind:false-value="0"
                        @change="getCheckedValue($event)"
                        v-model="role.can_final_approve"
                      />
                      <label class="form-check-label link-underline-primary" for="flexCheckChecked">
                       <u>Can Final Approve </u> 
                      </label>
                    </Field>
                    <ErrorMessage name="can_final_approve" class="validation-msg" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <h2 class="worker_table_title">Permissions</h2>
                <div class="role-form col">
                  <div class="row py-3" style="background: #37385799; color: #fff">
                    <div class="col-5 font-14 f-semi-bold" style="display: flex; align-items: center">
                      <!-- {{ $lang.messages.module_menu }} -->
                      Menu/Module
                    </div>
                    <div class="mx-auto font-14 f-semi-bold text-center" style="display: flex; align-items: center">
                      <!-- {{ $lang.messages.create }} -->
                      Create
                    </div>
                    <div class="mx-auto font-14 f-semi-bold text-center" style="display: flex; align-items: center">
                      <!-- {{ $lang.messages.read }} -->
                      Read
                    </div>
                    <div class="mx-auto font-14 f-semi-bold text-center" style="display: flex; align-items: center">
                      <!-- {{ $lang.messages.update }} -->
                      Update
                    </div>
                    <div class="mx-auto font-14 f-semi-bold text-center" style="display: flex; align-items: center">
                      <!-- {{ $lang.messages.delete }} -->
                      Delete
                    </div>
                    <div class="mx-auto font-14 f-semi-bold text-center" style="display: flex; align-items: center">
                      <!-- {{ $lang.messages.print }} -->
                      Print/Export
                    </div>
                  </div>
                  <!-- User Management -->

                  <template v-for="(item, i) in menuList" :key="'item_' + i">
                    <div class="row collapse-header pt-2" :class="item.submenus.length ? 'role-level-1' : 'role-level-2'">
                      <div class="col-5 wb-checkbox font-14 f-semi-bold cursor-pointer role-collapse"
                        data-toggle="collapse" :data-target="'#abc' + i" aria-expanded="false" :aria-controls="'abc' + i">
                        <img v-if="item.submenus.length" src="/static/img/add-square.svg" class="mr-2" alt="" />
                        <i class="mdi mdi-minus" v-if="item.submenus.length"></i>
                        {{ item.menu }}
                      </div>
                      <div class="wb-checkbox font-14 mx-auto text-center">
                        <div class="d-inline-block" v-if="item.can_create != null">
                          <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                            :class="'custom-control-input'" @change="checkPermission($event, item)" :id="'create_' + i"
                            :disabled="action == 'VIEW'" v-model="item.can_create" />
                          <label class="text-dark" :for="'create_' + i"></label>
                        </div>
                        <div class="d-inline-block" v-else>
                          <input type="checkbox" class="custom-control-input" :id="'create_' + i" disabled />
                          <label class="text-light" :for="'create_' + i"> </label>
                        </div>
                      </div>
                      <div class="wb-checkbox font-14 mx-auto text-center">
                        <div class="d-inline-block" v-if="item.can_read != null">
                          <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                            @change="checkPermission($event, item)" class="custom-control-input" :id="'read_' + i"
                            :disabled="action == 'VIEW'" v-model="item.can_read" />
                          <label class="text-dark" :for="'read_' + i"></label>
                        </div>
                        <div class="d-inline-block" v-else>
                          <input type="checkbox" class="custom-control-input" :id="'read_' + i" disabled />
                          <label class="text-light" :for="'read_' + i"> </label>
                        </div>
                      </div>
                      <div class="wb-checkbox font-14 mx-auto text-center">
                        <div class="d-inline-block" v-if="item.can_update != null">
                          <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                            @change="checkPermission($event, item)" class="custom-control-input" :id="'update_' + i"
                            :disabled="action == 'VIEW'" v-model="item.can_update" />
                          <label class="text-dark" :for="'update_' + i"></label>
                        </div>
                        <div class="d-inline-block" v-else>
                          <input type="checkbox" class="custom-control-input" :id="'update_' + i" disabled />
                          <label class="text-light" :for="'update_' + i"> </label>
                        </div>
                      </div>
                      <div class="wb-checkbox font-14 mx-auto text-center">
                        <div class="d-inline-block" v-if="item.can_delete != null">
                          <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                            @change="checkPermission($event, item)" class="custom-control-input" :id="'delete_' + i"
                            :disabled="action == 'VIEW'" v-model="item.can_delete" />
                          <label class="text-dark" :for="'delete_' + i"></label>
                        </div>
                        <div class="d-inline-block" v-else>
                          <input type="checkbox" class="custom-control-input" :id="'delete_' + i" disabled />
                          <label class="text-light" :for="'delete_' + i"> </label>
                        </div>
                      </div>
                      <div class="wb-checkbox font-14 mx-auto text-center">
                        <div class="d-inline-block" v-if="item.can_print != null">
                          <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                            @change="checkPermission($event, item)" class="custom-control-input" :id="'print_' + i"
                            :disabled="action == 'VIEW'" v-model="item.can_print" />
                          <label class="text-dark" :for="'print_' + i"></label>
                        </div>
                        <div class="d-inline-block" v-else>
                          <input type="checkbox" class="custom-control-input" :id="'print_' + i" disabled />
                          <label class="text-light" :for="'print_' + i"></label>
                        </div>
                      </div>
                    </div>

                    <!-- Collapse User Management -->
                    <div class="collapse" :id="'abc' + i" v-if="item.submenus.length">
                      <template v-for="(sub_menu, k) in item.submenus" :key="k">
                        <div class="row role-level-2" :key="sub_menu.id" v-if="sub_menu.submenus.length == 0">
                          <div class="col-5 wb-checkbox font-14 pl-4">
                            {{ sub_menu.menu }}
                          </div>
                          <div class="mx-auto wb-checkbox font-14 f-semi-bold text-center">
                            <div class="d-inline-block" v-if="sub_menu.can_create != null">
                              <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                @change="checkPermission($event, item)" class="custom-control-input"
                                :id="'create_' + i + '_' + k" v-model="sub_menu.can_create"
                                :disabled="action == 'VIEW'" />
                              <label class="text-dark" :for="'create_' + i + '_' + k"></label>
                            </div>
                            <div class="d-inline-block" v-else>
                              <input type="checkbox" class="custom-control-input" :id="'create_' + i + '_' + k"
                                disabled />
                              <label class="text-light" :id="'create_' + i + '_' + k">
                              </label>
                            </div>
                          </div>
                          <div class="mx-auto wb-checkbox font-14 f-semi-bold text-center">
                            <div class="d-inline-block" v-if="sub_menu.can_read != null">
                              <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                @change="checkPermission($event, item)" class="custom-control-input"
                                :id="'read_' + i + '_' + k" v-model="sub_menu.can_read" :disabled="action == 'VIEW'" />
                              <label class="text-dark" :for="'read_' + i + '_' + k"></label>
                            </div>
                            <div class="d-inline-block" v-else>
                              <input type="checkbox" class="custom-control-input" :id="'read_' + i + '_' + k" disabled />
                              <label class="text-light" :id="'read_' + i + '_' + k">
                              </label>
                            </div>
                          </div>
                          <div class="mx-auto wb-checkbox font-14 f-semi-bold text-center">
                            <div class="d-inline-block" v-if="sub_menu.can_update != null">
                              <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                @change="checkPermission($event, item)" class="custom-control-input"
                                :id="'update_' + i + '_' + k" v-model="sub_menu.can_update"
                                :disabled="action == 'VIEW'" />
                              <label class="text-dark" :for="'update_' + i + '_' + k"></label>
                            </div>
                            <div class="d-inline-block" v-else>
                              <input type="checkbox" class="custom-control-input" :id="'update_' + i + '_' + k"
                                disabled />
                              <label class="text-light" :id="'update_' + i + '_' + k">
                              </label>
                            </div>
                          </div>
                          <div class="mx-auto wb-checkbox font-14 f-semi-bold text-center">
                            <div class="d-inline-block" v-if="sub_menu.can_delete != null">
                              <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                @change="checkPermission($event, item)" class="custom-control-input"
                                :id="'delete_' + i + '_' + k" v-model="sub_menu.can_delete"
                                :disabled="action == 'VIEW'" />
                              <label class="text-dark" :for="'delete_' + i + '_' + k"></label>
                            </div>
                            <div class="d-inline-block" v-else>
                              <input type="checkbox" class="custom-control-input" :id="'delete_' + i + '_' + k"
                                disabled />
                              <label class="text-light" :id="'delete_' + i + '_' + k">
                              </label>
                            </div>
                          </div>
                          <div class="mx-auto wb-checkbox font-14 f-semi-bold text-center">
                            <div class="d-inline-block" v-if="sub_menu.can_print != null">
                              <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                @change="checkPermission($event, item)" class="custom-control-input"
                                :id="'print_' + i + '_' + k" v-model="sub_menu.can_print" :disabled="action == 'VIEW'" />
                              <label class="text-dark" :for="'print_' + i + '_' + k"></label>
                            </div>
                            <div class="d-inline-block" v-else>
                              <input type="checkbox" class="custom-control-input" :id="'print_' + i + '_' + k" disabled />
                              <label class="text-light" :id="'print_' + i + '_' + k">
                              </label>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div :key="'item_' + i + '_' + k" class="row role-level-2">
                            <div class="col-5 wb-checkbox font-14 f-semi-bold cursor-pointer role-collapse collapsed pl-4"
                              data-toggle="collapse" :data-target="'#abc_menu' + i + '_' + k" aria-expanded="false"
                              :aria-controls="'abc_menu' + i + '_' + k">
                              <i class="mdi mdi-plus" v-if="sub_menu.submenus.length"></i>
                              <i class="mdi mdi-minus" v-if="sub_menu.submenus.length"></i>
                              {{ sub_menu.menu }}
                            </div>
                            <div class="mx-auto wb-checkbox font-14 f-semi-bold text-center">
                              <div class="d-inline-block" v-if="sub_menu.can_create != null">
                                <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                  @change="checkPermission($event, item)" class="custom-control-input"
                                  :id="'create_' + i + '_' + k" :disabled="sub_menu.can_create == null || action == 'VIEW'
                                    " v-model="sub_menu.can_create" />
                                <label class="text-dark" :for="'create_' + i + '_' + k"></label>
                              </div>
                              <div class="d-inline-block" v-else>
                                <input type="checkbox" class="custom-control-input" :id="'create_' + i + '_' + k"
                                  disabled />
                                <label class="text-light" :id="'create_' + i + '_' + k">
                                </label>
                              </div>
                            </div>
                            <div class="mx-auto wb-checkbox font-14 f-semi-bold text-center">
                              <div class="d-inline-block" v-if="sub_menu.can_read != null">
                                <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                  @change="checkPermission($event, item)" class="custom-control-input"
                                  :id="'read_' + i + '_' + k" :disabled="sub_menu.can_read == null || action == 'VIEW'
                                    " v-model="sub_menu.can_read" />
                                <label class="text-dark" :for="'read_' + i + '_' + k"></label>
                              </div>
                              <div class="d-inline-block" v-else>
                                <input type="checkbox" class="custom-control-input" :id="'read_' + i + '_' + k"
                                  disabled />
                                <label class="text-light" :id="'read_' + i + '_' + k">
                                </label>
                              </div>
                            </div>
                            <div class="mx-auto wb-checkbox font-14 f-semi-bold text-center">
                              <div class="d-inline-block" v-if="sub_menu.can_update != null">
                                <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                  @change="checkPermission($event, item)" class="custom-control-input"
                                  :id="'update_' + i + '_' + k" :disabled="sub_menu.can_update == null || action == 'VIEW'
                                    " v-model="sub_menu.can_update" />
                                <label class="text-dark" :for="'update_' + i + '_' + k"></label>
                              </div>
                              <div class="d-inline-block" v-else>
                                <input type="checkbox" class="custom-control-input" :id="'update_' + i + '_' + k"
                                  disabled />
                                <label class="text-light" :id="'update_' + i + '_' + k">
                                </label>
                              </div>
                            </div>
                            <div class="mx-auto wb-checkbox font-14 f-semi-bold text-center">
                              <div class="d-inline-block" v-if="sub_menu.can_delete != null">
                                <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                  @change="checkPermission($event, item)" class="custom-control-input"
                                  :id="'delete_' + i + '_' + k" :disabled="sub_menu.can_delete == null || action == 'VIEW'
                                    " v-model="sub_menu.can_delete" />
                                <label class="text-dark" :for="'delete_' + i + '_' + k"></label>
                              </div>
                              <div class="d-inline-block" v-else>
                                <input type="checkbox" class="custom-control-input" :id="'delete_' + i + '_' + k"
                                  disabled />
                                <label class="text-light" :id="'delete_' + i + '_' + k">
                                </label>
                              </div>
                            </div>
                            <div class="mx-auto wb-checkbox font-14 f-semi-bold text-center">
                              <div class="d-inline-block" v-if="sub_menu.can_print != null">
                                <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                  @change="checkPermission($event, item)" class="custom-control-input"
                                  :id="'print_' + i + '_' + k" :disabled="sub_menu.can_print == null || action == 'VIEW'
                                    " v-model="sub_menu.can_print" />
                                <label class="text-dark" :for="'print_' + i + '_' + k"></label>
                              </div>
                              <div class="d-inline-block" v-else>
                                <input type="checkbox" class="custom-control-input" :id="'print_' + i + '_' + k"
                                  disabled />
                                <label class="text-light" :id="'print_' + i + '_' + k">
                                </label>
                              </div>
                            </div>
                          </div>

                          <!-- Collapse User Management -->
                          <div class="collapse" :id="'abc_menu' + i + '_' + k">
                            <div class="row role-level-3" v-for="(sub_menu1, j) in sub_menu.submenus" :key="sub_menu1.id">
                              <div class="col-3 font-14 pl-4">
                                {{ sub_menu1.menu }}
                              </div>
                              <div class="col-2 font-14 text-center">
                                <div class="wb-checkbox d-inline-block" v-if="sub_menu1.can_create != null">
                                  <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                    @change="checkPermission($event, item)" class="custom-control-input"
                                    :id="'create_' + i + '_' + k + '_' + j" v-model="sub_menu1.can_create" :disabled="sub_menu1.can_create == null || action == 'VIEW'
                                      " />
                                  <label class="text-dark" :for="'create_' + i + '_' + k + '_' + j"></label>
                                </div>
                                <div class="d-inline-block" v-else>
                                  <input type="checkbox" class="custom-control-input"
                                    :id="'create_' + i + '_' + k + '_' + j" disabled />
                                  <label class="text-light" :id="'create_' + i + '_' + k + '_' + j">
                                  </label>
                                </div>
                              </div>
                              <div class="col-1 font-14 text-center">
                                <div class="wb-checkbox d-inline-block" v-if="sub_menu1.can_read != null">
                                  <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                    @change="checkPermission($event, item)" class="custom-control-input"
                                    :id="'read_' + i + '_' + k + '_' + j" v-model="sub_menu1.can_read" :disabled="sub_menu1.can_read == null || action == 'VIEW'
                                      " />
                                  <label class="text-dark" :for="'read_' + i + '_' + k + '_' + j"></label>
                                </div>
                                <div class="d-inline-block" v-else>
                                  <input type="checkbox" class="custom-control-input"
                                    :id="'read_' + i + '_' + k + '_' + j" disabled />
                                  <label class="text-light" :id="'read_' + i + '_' + k + '_' + j">
                                  </label>
                                </div>
                              </div>
                              <div class="col-2 font-14 text-center">
                                <div class="wb-checkbox d-inline-block" v-if="sub_menu1.can_update != null">
                                  <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                    @change="checkPermission($event, item)" class="custom-control-input"
                                    :id="'update_' + i + '_' + k + '_' + j" v-model="sub_menu1.can_update" :disabled="sub_menu1.can_update == null || action == 'VIEW'
                                      " />
                                  <label class="text-dark" :for="'update_' + i + '_' + k + '_' + j"></label>
                                </div>
                                <div class="d-inline-block" v-else>
                                  <input type="checkbox" class="custom-control-input"
                                    :id="'update_' + i + '_' + k + '_' + j" disabled />
                                  <label class="text-light" :id="'update_' + i + '_' + k + '_' + j">
                                  </label>
                                </div>
                              </div>
                              <div class="col-2 font-14 text-center">
                                <div class="wb-checkbox d-inline-block" v-if="sub_menu1.can_delete != null">
                                  <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                    @change="checkPermission($event, item)" class="custom-control-input"
                                    :id="'delete_' + i + '_' + k + '_' + j" v-model="sub_menu1.can_delete" :disabled="sub_menu1.can_delete == null || action == 'VIEW'
                                      " />
                                  <label class="text-dark" :for="'delete_' + i + '_' + k + '_' + j"></label>
                                </div>
                                <div class="d-inline-block" v-else>
                                  <input type="checkbox" class="custom-control-input"
                                    :id="'delete_' + i + '_' + k + '_' + j" disabled />
                                  <label class="text-light" :id="'delete_' + i + '_' + k + '_' + j">
                                  </label>
                                </div>
                              </div>
                              <div class="col-2 font-14 text-center">
                                <div class="wb-checkbox d-inline-block" v-if="sub_menu1.can_print != null">
                                  <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0"
                                    @change="checkPermission($event, item)" class="custom-control-input"
                                    :id="'print_' + i + '_' + k + '_' + j" v-model="sub_menu1.can_print" :disabled="sub_menu1.can_delete == null || action == 'VIEW'
                                      " />
                                  <label class="text-dark" :for="'print_' + i + '_' + k + '_' + j"></label>
                                </div>
                                <div class="d-inline-block" v-else>
                                  <input type="checkbox" class="custom-control-input"
                                    :id="'print_' + i + '_' + k + '_' + j" disabled />
                                  <label class="text-light" :id="'print_' + i + '_' + k + '_' + j">
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                  <div class="row mt-4 d-flex">
                    <div class="col-lg-12 mx-auto" v-if="action != 'VIEW'">
                      <button type="button" class="btn btn-brand-01 mx-2" @click="$router.go(-1)">
                        <!-- {{ $lang.messages.cancel }} -->
                        Cancel
                      </button>
                      <button type="submit" class="btn btn-brand-01 mx-2" id="roll-btn " :disabled="action == 'VIEW'">
                        <!-- {{ $lang.messages.submit }} -->
                        Submit
                      </button>
                    </div>
                    <div class="col-12" v-else>
                      <button type="button" class="btn btn-brand-01 mx-2" @click="$router.go(-1)">
                        <!-- {{ $lang.messages.back }} -->
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<style>
.checkbox-background-color {
  background-color: #b9c8ed;
  position: relative;
}
</style>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";

import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
export default {
  name: "AddEdit",
  components: {
    Form,
    Field,
    ErrorMessage, Multiselect
  },
  data() {
    return {
      id: this.$route.params.id,
      // action: this.$route.params.view,
      action: "",
      list: [],
      name: "",
      menuList: [],
      role: {
        name: "",
        status: "ACTIVE",
        role_access: []
      },
      role_type: "",
      roleTypes: [],
      is_active: 1,
      user: {},
      branch_id: ""
    };
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getAllBranches;
    },
  },
  mounted() {
    console.log(this.branchList);
    this.getRoleType();
    if (this.$route.params.id) {
      this.role.id = this.$route.params.id;
      this.getRoleDetails();
    } else {
      this.role.id = "";
      this.getMenu();
    }
  },
  methods: {
    getCheckedValue(e){
      var value = e.target.checked;
      if (value == true) {
        this.role.can_final_approve = 1;
      } else {
        this.role.can_final_approve = 0;
      }
      console.log("getCheckedValue", this.role.can_final_approve);
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    save() {
      this.role.branch_id = this.branch_id;
      this.role.role_type = this.role_type;
      this.role.list = [];
      this.role.role_access = [];
      console.log(this.menuList);
      for (var i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].submenus.length) {
          for (var k = 0; k < this.menuList[i].submenus.length; k++) {
            if (this.menuList[i].submenus[k].submenus.length) {
              for (var j = 0; j < this.menuList[i].submenus[k].submenus.length; j++) {
                console.log(this.menuList[i].submenus[k].submenus[j].menu);
                this.role.role_access.push(this.menuList[i].submenus[k].submenus[j]);
              }
            }
            console.log(this.menuList[i].submenus[k].menu);
            this.role.role_access.push(this.menuList[i].submenus[k]);
          }
          console.log(this.menuList[i].menu);
          this.role.role_access.push(this.menuList[i]);
        } else {
          console.log(this.menuList[i].menu);
          this.role.role_access.push(this.menuList[i]);
        }
      }
      this.role.role_name = this.role.name;
      var noChecked = false;
      this.role.role_access.forEach((obj, i) => {
        if (!noChecked) {
          if (obj.can_create == 1 || obj.can_read == 1 || obj.can_update == 1 || obj.can_delete == 1 || obj.can_print == 1) {
            noChecked = true;
            console.log("object noChecked = false; data:", obj, "index of :", i);
          }
        }
      });

      if (!noChecked) {
        this.$toast.error("Please Select atleast one role", { position: "top-right" });
        return false;
      }
      if (this.is_active) {
        this.role.status = "ACTIVE";
      } else {
        this.role.status = "INACTIVE";
      }

      var method = "POST";
      var action = "role";
      if (this.role.id) {
        method = "PUT";
        action = "/role/" + this.role.id;
      }
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: this.role
        })
        .then((res) => {
          this.$router.go(-1);
          this.onClearForm("addEditRole");
          this.$toast.success(res.message, { position: "top-right" });
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });

    },
    checkPermission(e, obj) {
      console.log(e, obj);
      var id = e.target.id;
      var parts = id.split("_");
      var part_name = parts[0];
      if (
        obj.can_read == 0 &&
        (part_name == "create" ||
          part_name == "delete" ||
          part_name == "print" ||
          part_name == "update")
      ) {
        obj.can_read = 1;
      }

      if (part_name == 'read' && obj.can_read == 0) {
        if (obj.can_create == 1 || obj.can_delete == 1 || obj.can_print == 1 || obj.can_update == 1) {
          obj.can_read = 1;
        }
      }
    },
    getRoleDetails() {
      this.menuList = [];
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "role/" + this.role.id,
        })
        .then((res) => {
          this.role = res.info;
          this.menuList = res.info.list;
          if (this.role.is_editable == "N"){
            this.action = "VIEW";
          }
          if (res?.info?.branch_id == null) {
            this.branch_id = "";
          }
          if (res?.info?.branch) {
            this.branch_id = res?.info?.branch?.uuid;
          }
          this.role_type = res?.info?.role_type;
          if (this.role.status == "ACTIVE") {
            this.is_active = 1;
          } else {
            this.is_active = 0;
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    getMenu() {
      this.menuList = [];
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "menus",
        }).then((res) => {
          this.menuList = res.info.list;
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    getRoleType() {
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "role-type",
        }).then((res) => {
          this.roleTypes = res.list;
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
  },
};
</script>
  