<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: "App",
  mounted() {
    // Disable right-click
    // document.addEventListener('contextmenu', (e) => e.preventDefault());

    // // Function to check for specific key combinations
    // function ctrlShiftKey(e, keyCode) {
    //   return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    // }

    // // Disable specific key combinations
    // document.onkeydown = (e) => {
    //   if (
    //     e.keyCode === 123 || // F12
    //     ctrlShiftKey(e, 'I') || // Ctrl+Shift+I
    //     ctrlShiftKey(e, 'J') || // Ctrl+Shift+J
    //     ctrlShiftKey(e, 'C') || // Ctrl+Shift+C
    //     (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) // Ctrl+U
    //   ) {
    //     return false;
    //   }
    // };

  }
};
</script>