<template>
    <div class="content-body">
        <div class="worker_serch_warp">
            <div class="row d-flex justify-content-between">
                <div class="col-md-2 pr-2">
                    <h2 class="content-title">
                        <a @click="$router.go(-1)" style="cursor: pointer"><i class="fas fa-angle-left mr-2"></i><span
                                v-if="!this.id">Add BroadCast</span> <span v-else>Edit BroadCast</span> </a>
                    </h2>
                </div>
            </div>
        </div>
        <div class="component-section no-code">
            <div class="row-sm wb-row-gap">
                <Form @submit="save" class="columns column is-multiline is-12" data-vv-scope="formAddLoanType"
                    ref="addEditLoanType">
                    <div class="col-lg-12">
                        <div class="card rounded-5">
                            <div class="card-body p-3 p-lg-3">
                                <div class="row">
                                    <div class="col-lg-6 mb-4">
                                        <label> Title <span class="text-danger">*</span> </label>
                                        <Field name="title" v-model="brodcast.title" class="form-control"
                                            rules="required:title" :validateOnInput="true" type="text" placeholder="Title"
                                            autocapitalize="false" />
                                        <ErrorMessage name="title" class="validation-msg" />
                                    </div>
                                    
                                    <div class="col-lg-6 mb-4">
                                        <label> Select Branch <span class="text-danger"></span> </label>
                                        <div class="add_btn_wrapper d-flex flex-row justify-content-end">
                                            <Multiselect ref="branch_id_multiselect" name="branch_id" mode="single" trackBy="name"
                                            label="name" valueProp="branch_id" placeholder="Select Branch" class="form-control"
                                            v-model="brodcast.branch_id" :options="branchList" searchable="true" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-4">
                                        <label> Message <span class="text-danger">*</span> </label>
                                        <Field v-slot="{ field }" name="message" v-model="brodcast.message"
                                            rules="required:message" :validateOnInput="true" type="text"
                                            autocapitalize="false">
                                            <textarea v-bind="field" placeholder="Message" rows="5" cols="5" class="form-control"
                                                v-model="brodcast.message"></textarea>
                                        </Field>
                                        <ErrorMessage name="message" class="validation-msg" />
                                    </div>

                                    <div class="col-lg-4 mb-4">
                                        <label>Send To</label>
                                        <div class="d-md-flex mb-3">
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="sent_to_yes" name="sent_to"
                                                    class="custom-control-input" v-model="brodcast.sent_to" value="ALL" />
                                                <label class="custom-control-label" for="sent_to_yes">All</label>
                                            </div>
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="sent_to_no" name="sent_to"
                                                    class="custom-control-input" v-model="brodcast.sent_to"
                                                    value="SPECIFIC" />
                                                <label class="custom-control-label" for="sent_to_no">Specific</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 mb-4">
                                        <label>Type</label>
                                        <div class="d-md-flex mb-3">
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="type_yes" name="type" class="custom-control-input"
                                                    v-model="brodcast.type" value="EMPLOYEE" />
                                                <label class="custom-control-label" for="type_yes">Employee</label>
                                            </div>
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="type_no" name="type" class="custom-control-input"
                                                    v-model="brodcast.type" value="CUSTOMER" />
                                                <label class="custom-control-label" for="type_no">Customer</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-4"  v-if="brodcast.type == 'CUSTOMER' && (brodcast.sent_to == 'SPECIFIC' || brodcast.sent_to == 'ALL') ">
                                        <label>Customer Type</label>
                                        <div class="d-md-flex mb-3">
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="customer_type_all" name="customer_type_all" class="custom-control-input"
                                                    v-model="brodcast.customer_type" value="" />
                                                <label class="custom-control-label" for="customer_type_all">All</label>
                                            </div>
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="customer_type_yes" name="customer_type_yes" class="custom-control-input"
                                                    v-model="brodcast.customer_type" value="ACTIVE" />
                                                <label class="custom-control-label" for="customer_type_yes">Active</label>
                                            </div>
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="customer_type_no" name="customer_type_no" class="custom-control-input"
                                                    v-model="brodcast.customer_type" value="INACTIVE" />
                                                <label class="custom-control-label" for="customer_type_no">Inactive</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-4" v-if="brodcast.type == 'CUSTOMER' && (brodcast.sent_to == 'SPECIFIC' || brodcast.sent_to == 'ALL')">
                                        <label>Customer Level</label>
                                        <Field v-slot="{ field }" name="level" rules="required:level,true" :validateOnInput="true" v-model="brodcast.level">
                                            <select class="form-control" v-bind="field" v-model="brodcast.level">
                                                <option value="">All</option>
                                                <option value="SILVER" selected>Silver</option>
                                                <option value="GOLD">Gold</option>
                                                <option value="PLATINUM">Platinum</option>
                                            </select>
                                        </Field>
                                        <ErrorMessage name="level" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4"
                                        v-if="brodcast.sent_to == 'SPECIFIC' && brodcast.type == 'CUSTOMER' ">
                                        <label>Customers <span class="text-danger">*</span> </label>
                                        <Field v-slot="{ field }" name="consumer_id" rules="required:customers,true"
                                            :validateOnInput="true" v-model="user_id">
                                            <Multiselect v-bind="field" ref="consumer_id_multiselect" mode="tags"
                                                trackBy="name" label="name" valueProp="id" id="customer"
                                                placeholder="Select Customers" class="form-control"
                                                @search-change="getCustomerList" v-model="user_id"
                                                :close-on-select="true"
                                                :options="customerOption" :searchable="true" />
                                        </Field>
                                        <ErrorMessage name="consumer_id" class="validation-msg" />
                                    </div>

 
                                    <div class="col-lg-4 mb-4"
                                        v-if="brodcast.sent_to == 'SPECIFIC' && brodcast.type == 'EMPLOYEE'">
                                        <label>Employees <span class="text-danger">*</span> </label>
                                        <Field v-slot="{ field }" name="user_id" rules="required:employees,true"
                                            :validateOnInput="true" v-model="user_id">
                                            <Multiselect v-bind="field" ref="user_id_multiselect" mode="tags"
                                                trackBy="name" label="name" valueProp="id" id="employee"
                                                placeholder="Select Employees" class="form-control"
                                                :close-on-select="true" :clear-on-select="false"
                                                @search-change="getEmployeeList" v-model="user_id"
                                                :options="employeeOption" :searchable="true" />
                                        </Field>
                                        <ErrorMessage name="user_id" class="validation-msg" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 mb-3">
                        <div class="col-12 text-center">
                            <button type="button" class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                                @click="$router.go(-1)">
                                Cancel
                            </button>
                            <button type="submit" id="save-btn" class="btn btn-brand-01   wb-save-btn">
                                Save
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    name: "AddEditBroadCast",
    components: {
        Multiselect, Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            brodcast: {
                sent_to: "ALL",
                level:"",
                type: "EMPLOYEE",
                customer_type:"",     
                user_id: [],
                branch_id:'',
            },
            user_id:[],
            customerOption: [],
            employeeOption: [],
            keyword: ""
        };
    },
    computed: {
    branchList() {
      
    var list = this.$storeService.getters.getAllBranches;
      return list;
    },
  },
    mounted() {
    },
    methods: {
        getEmployeeList(keyword) {
            // console.log(document.getElementById('keyword').value);
            // this.keyword = document.getElementById('employee').value;
            if (keyword.length > 2) {
                this.$api
                    .webRequest({
                        _method: "GET",
                        _action: "search/employee",
                        _body: { keyword: keyword,branch_id:this.brodcast.branch_id}
                    })
                    .then((res) => {
                        this.employeeOption = res.list;
                    })
                    .catch(() => { });
            }
        },
        getCustomerList(keyword) {
            // console.log(document.getElementById('keyword').value);
            if (keyword.length > 2) {
                this.$api
                    .webRequest({
                        _method: "GET",
                        _action: "search/customer",
                        _body: { keyword:keyword,customer_type:this.brodcast.customer_type,level:this.brodcast.level,branch_id:this.brodcast.branch_id}
                    })
                    .then((res) => {
                        setTimeout(() => {
                            this.customerOption = res.list;
                        }, 500);
                    })
                    .catch(() => { });
            }
        },
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },


        save() {
            this.brodcast.user_id = this.user_id;
            // if(this.brodcast){
            //     console.log(this.brodcast);

            //     return true;
            // }
            this.brodcast.user_id = this.brodcast.user_id.toString();
            var method = "POST";
            var action = "/notifications";
            this.$api
                .webRequest({
                    _method: method,
                    _action: action,
                    _body: this.brodcast,
                    _buttonId: "save-btn",
                    _hide_loader: true,
                })
                .then((res) => {
                    this.onClearForm("addEditLoanType");
                    this.$toast.success(res.message, { position: "top-right" });
                    this.$router.push('/broadcast');
                })
                .catch((e) => {
                    this.$toast.error(e.message, { position: "top-right" });
                });

        },
    },
};
</script>

<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>