<template>
    <div class="content-body">
        <div class="worker_serch_warp">
            <div class="row d-flex justify-content-between">
                <div class="col-md-4 pr-2">
                    <h2 class="content-title">
                        <a @click="$router.go(-1)" style="cursor: pointer"><i class="fas fa-angle-left mr-2"></i><span
                                v-if="!this.id">Add Reference</span> <span v-else>Edit Reference</span> </a>
                    </h2>
                </div>
            </div>
        </div>
        <div class="component-section no-code">
            <div class="row-sm wb-row-gap">
                <Form @submit="save" class="columns column is-multiline is-12" data-vv-scope="formAddExpenseCategory"
                    ref="addEditOfficeExpense">
                    <div class="col-lg-12">
                        <div class="card rounded-5">
                            <div class="card-body p-3 p-lg-3">
                                <div class="row">
                                    <div class="col-lg-6 mb-4">
                                        <label> Name <span class="text-danger">*</span> </label>
                                        <Field name="name" v-model="reference.name" class="form-control"
                                            rules="required:name" :validateOnInput="true" type="text" placeholder="Name"
                                            autocapitalize="false" />
                                        <ErrorMessage name="name" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-6 mb-4">
                                        <label> Has Text Option <span class="text-danger">*</span> </label>
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <span class="mr-3">
                                                    <input class="" v-model="reference.has_other_info"
                                                        :checked="reference.has_other_info == '1' ? true : false"
                                                        type="radio" value="1" id="has_1"
                                                        style="width: 20px; height: auto" />
                                                    <label class="form-check-label" for="has_1"> Yes </label>
                                                </span>
                                            </div>
                                            <div class="col-lg-4">
                                                <span>
                                                    <input class="" v-model="reference.has_other_info"
                                                        :checked="reference.has_other_info == '0' ? true : false"
                                                        type="radio" value="0" id="has_0"
                                                        style="width: 20px; height: auto" />
                                                    <label class="form-check-label" for="has_0"> No </label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-4">
                                        <label>Status <span class="text-danger">*</span> </label>
                                        <Field v-slot="{ field }" name="status" rules="required:status,true"
                                            :validateOnInput="true" v-model="reference.status">
                                            <Multiselect v-bind="field" ref="status_multiselect" mode="single"
                                                trackBy="title" label="title" valueProp="value"
                                                placeholder="Select Status" class="form-control"
                                                v-model="reference.status" :options="statusOption" :searchable="true" />
                                        </Field>
                                        <ErrorMessage name="status" class="validation-msg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5 mb-3">
                            <div class="col-12 text-center">
                                <button type="button" class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                                    @click="$router.go(-1)">
                                    Cancel
                                </button>
                                <button type="submit" id="save-btn" class="btn btn-brand-01   wb-save-btn">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    name: "AddEditReference",
    components: {
        Multiselect, Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            id: this.$route.params.id,
            reference: {
                name: "",
                status: "",
                has_other_info: "0",
            },
            statusOption: [{
                "title": "Active", "value": "ACTIVE",
            }, {
                "title": "Inactive", "value": "INACTIVE",
            }],

            expenseCategoryOption: [],
            status: localStorage.getItem("expense_status"),
            file: null,
            employeeOption: [],
        };
    },
    mounted() {
        this.getExpenseCategoryList();
        if (this.id) {
            this.getDetail();
        }

        if (this.status == 'EMPLOYEE') {
            this.getEmployeeList();
        }
    },
    computed: {
        branchList() {
            return this.$storeService.getters.getOfficeExpenseBranch;
        },
        hasFullAccess() {
            return this.$storeService.getters.getHasFullAccess;
        }
    },
    methods: {
        getExpenseCategoryList() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "/expenses/categories",
                    _body: { "status": this.status },
                })
                .then((res) => {
                    this.expenseCategoryOption = res.list;
                })
                .catch(() => { });
        },
        callEmployeeList() {
            setTimeout(() => {
                this.getEmployeeList();
            }, 500)
        },
        getEmployeeList() {
            var branch_id = "";
            if (this.hasFullAccess == false) {
                branch_id = localStorage.getItem("branch_numeric_id");
            } else {
                branch_id = this.reference.branch_id;
            }
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "/select/expense-users",
                    _body: { branch_id: branch_id }
                })
                .then((res) => {
                    this.employeeOption = res.list;
                })
                .catch(() => { });
        },
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },
        getDetail() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "reference/" + this.id,
                })
                .then((res) => {
                    this.reference = res.info;
                })
                .catch(() => { });
        },


        save() {

            var method = "POST";
            var action = "reference";
            if (this.id) {
                method = "PUT";
                action = this.id + "/reference";
            }

            this.$api
                .webRequest({
                    _method: method,
                    _action: action,
                    _body: this.reference,
                    _buttonId: "save-btn",
                    _hide_loader: true,
                })
                .then((res) => {
                    this.onClearForm("addEditOfficeExpense");
                    this.$toast.success(res.message, { position: "top-right" });
                    this.$router.go(-1);
                })
                .catch((e) => {
                    this.$toast.error(e.message, { position: "top-right" });
                });

        },

        uploadFile(file, id) {
            var url = "";
            if (this.status == 'OFFICE') {
                url = "office/expense/" + id + "/bill";
            }
            if (this.status == 'EMPLOYEE') {
                url = "employee/expense/" + id + "/bill";
            }
            this.$api
                .uploadImageAPI({
                    _action: url,
                    _file: file,
                    _key: "image",
                    _body: {},
                    _buttonId: "save-btn",
                })
                .then((res) => {
                    this.$toast.success(res.message, { position: "top-right" });
                    this.$router.go(-1);
                });
        },
        handleImageUpload(e) {
            let files = e.target.files;
            let reader = new FileReader();

            reader.readAsDataURL(files[0]);
            reader.onload = (evt) => {
                let img = new Image();
                img.onload = () => {
                    if (
                        files[0].type.toLowerCase() != "image/jpeg" &&
                        files[0].type.toLowerCase() != "image/png"
                    ) {
                        alert("Invalid file formate, please use jpeg or png file.");
                        return;
                    }

                    this.file = files[0];

                    // var tmppath = URL.createObjectURL(files[0]);
                    // document.getElementById("preview").src = tmppath;
                };

                img.src = evt.target.result;
            };
        },
    },
};
</script>

<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>