import {
  createStore
} from "vuex";
const store = createStore({
  state() {
    return {
      userInfo: {},
      isShowSidebar: false,
      summary: {
        company: [],
        businessGroup: [],
        webinars: [],
        activity: []
      },
      keyword: "",
      notificationCount: 0,
      loanDisbursedOnject: null,
      collectionPlanObj: null,
      dashBoardBranch: [],
      title: "",
      branches: [],
      allBranches:[],
      paymentInfo:{},
      setOfficeExpenseBranch:[],
      has_full_access:false,
      userType:null
    };
  },
  mutations: {
    setUser(state, payload) {
      state.userInfo = payload;
    },
    setSummary(state, payload) {
      state.summary = payload;
    },
    showSidebar(state, payload) {
      state.isShowSidebar = payload;
    },
    keyword(state, payload) {
      state.keyword = payload;
    },
    setNotificationCount(state, payload) {
      state.notificationCount = payload;
    },
    setTitle(state, payload) {
      state.title = payload;
    },
    setBranches(state, payload) {
      state.branches = payload;
    },
    setDashBoardBranches(state, payload) {
      state.dashBoardBranch = payload;
    },
    setAllBranches(state, payload) {
      state.allBranches = payload;
    },
    setOfficeExpensesBranch(state, payload) {
      state.setOfficeExpenseBranch = payload;
    },
    hasFullAccess(state, payload) {
      state.has_full_access = payload;
    },
    setLoanDisbursedObj(state, payload) {
      state.loanDisbursedOnject = payload;
    },
    setCollectionPlanObj(state, payload) {
      state.collectionPlanObj = payload;
    },
    setPaymentInfo(state,payload){
      state.paymentInfo = payload;
    },
    setUserType(state,payload){
      state.userType = payload;
    }
  },
  getters: {
    getUser(state) {
      return state.userInfo;
    },
    getShowSidebar(state) {
      return state.isShowSidebar;
    },
    getSummary(state) {
      return state.summary;
    },
    getNotificationCount(state) {
      return state.notificationCount;
    },
    getTitle(state) {
      return state.title;
    },
    getDashBoardBranches(state) {
      return state.dashBoardBranch;
    },
    getBranches(state) {
      return state.branches;
    },
    getAllBranches(state) {
      return  state.allBranches;
    },
    getOfficeExpenseBranch(state) {
      return  state.setOfficeExpenseBranch;
    },
    getHasFullAccess(state) {
     return  state.has_full_access 
    },
    getLoanDisbursedObj(state) {
     return  state.loanDisbursedOnject 
    },
    getCollectionPlanObj(state) {
     return  state.collectionPlanObj 
    },
    getPaymentInfo(state) {
     return  state.paymentInfo 
    },
    getUserType(state) {
     return  state.userType 
    },
  },
  actions: {},
});

export default store;