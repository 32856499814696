<template>
    <div class="content-body">
        <div class="worker_serch_warp">
            <div class="row d-flex justify-content-between">
                <div class="col-md-2 pr-2">
                    <h2 class="content-title">
                        <a @click="$router.go(-1)" style="cursor: pointer"><i class="fas fa-angle-left mr-2"></i><span
                                v-if="!this.id">Add Incentive</span> <span v-else>Edit Incentive</span> </a>
                    </h2>
                </div>
            </div>
        </div>
        <div class="component-section no-code">
            <div class="row-sm wb-row-gap">
                <Form @submit="save" class="columns column is-multiline is-12" data-vv-scope="formAddLoanType"
                    ref="addEditLoanType">
                    <div class="col-lg-12">
                        <div class="card rounded-5">
                            <div class="card-body p-3 p-lg-3">
                                <div class="row">
                                    
                                    <div class="col-lg-4 mb-4">
                                        <label>Percentage <span class="text-danger">*</span> </label>
                                        <Field name="percent" class="form-control"
                                            rules="required:Percentage|numeric" :validateOnInput="true"
                                            v-model="loanType.percent" type="text" placeholder="Percentage"
                                            autocapitalize="false" />
                                        <ErrorMessage name="percent" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label>Amount
                                            <span class="text-danger">*</span>
                                        </label>

                                        <Field name="amount" class="form-control" id="amount"
                                            rules="required:Amount|numeric" :validateOnInput="true"
                                            v-model="loanType.amount" type="text" placeholder="Amount"
                                            autocapitalize="false" />
                                        <ErrorMessage name="amount" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label>Task Force
                                            <span class="text-danger">*</span>
                                        </label>
                                        <Field v-slot="{ field }" name="collection_group" rules="required:task force,true"
                                            :validateOnInput="true" v-model="loanType.collection_group">
                                            <Multiselect v-bind="field" ref="collection_group_multiselect" mode="single"
                                                trackBy="title" label="title" valueProp="value" placeholder="Select Task Force"
                                                class="form-control" v-model="loanType.collection_group" :options="collectionGroupOption"
                                                :searchable="true" :createOption="true" />
                                        </Field>
                                        <ErrorMessage name="collection_group" class="validation-msg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5 mb-3">
                            <div class="col-12 text-center">
                                <button type="button" class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                                    @click="$router.go(-1)">
                                    Cancel
                                </button>
                                <button type="submit" id="save-btn" class="btn btn-brand-01   wb-save-btn">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    name: "AddEditLoanType",
    components: {
        Multiselect, Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            id: this.$route.params.id,
            loanType: {
                title: "",
                collection_group: "",
                status: "",
                amount: "",
                percent: "",
                late_payment_interest: "",
                has_secured: 0
            },
            collectionGroupOption: [{
                "title": "Task Force 1", "value": "TASK_FORCE_1",
            }, {
                "title": "Task Force 2", "value": "TASK_FORCE_2",
            }, {
                "title": "Task Force 3", "value": "TASK_FORCE_3",
            }
        ],
            statusOption: [{
                "title": "Active", "value": "ACTIVE",
            }, {
                "title": "Inactive", "value": "INACTIVE",
            }]
        };
    },
    mounted() {
        if (this.id) {
            this.getUserInfo();
        }
    },
    methods: {
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },
        getUserInfo() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "incentives/" + this.id,
                })
                .then((res) => {
                    this.loanType = res.info;


                })
                .catch(() => { });
        },


        save() {
            var method = "POST";
            var action = "/incentives";
            if (this.id) {
                method = "PUT";
                action = "incentives/" + this.id;
            }
            this.$api
                .webRequest({
                    _method: method,
                    _action: action,
                    _body: this.loanType,
                    _buttonId: "save-btn",
                    _hide_loader: true,
                })
                .then((res) => {
                    this.onClearForm("addEditLoanType");
                    this.$toast.success(res.message, { position: "top-right" });
                    this.$router.go(-1);
                })
                .catch((e) => {
                    this.$toast.error(e.message, { position: "top-right" });
                });

        },
    },
};
</script>

<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>