<template>
    <div>
        <div class="content-body">
            <div class="component-section no-code">
                <div class="row d-flex justify-content-between">
                    <div class="col-md-2 pr-2">
                        <h2 class="content-title">
                            <a @click="$router.go(-1)" style="cursor: pointer"><i
                                    class="fas fa-angle-left mr-2"></i><span>Dashboard</span> </a>
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="container-fluid">
                        <div class="worker_serch_warp">
                            <div class="row">
                                <div class="col-lg-12 pt-3">
                                    <div class="card rounded-2" style="border-radius: 10px;">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <h5>Total Target / Achived Target</h5>
                                                </div>
                                                <div class="col-lg-12">
                                                    <h5>{{ $helperService.getFormattedCurrency(targetInfo?.target) + " / " + $helperService.getFormattedCurrency(targetInfo?.achieved_target) }}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 pt-3">
                                    <div class="card rounded-2" style="border-radius: 10px;">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-3">
                                                    Total Application :
                                                </div>
                                                <div class="col-lg-9">
                                                    {{ targetInfo?.info?.total_loans?.no_of_loans }}
                                                </div>
                                                <div class="col-lg-3">
                                                    Amount :
                                                </div>
                                                <div class="col-lg-9">
                                                    {{ $helperService.getFormattedCurrency(targetInfo?.info?.total_loans?.amount) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 pt-3">
                                    <div class="card rounded-2" style="border-radius: 10px;">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-3">
                                                    Accepted Application :
                                                </div>
                                                <div class="col-lg-9">
                                                    {{ targetInfo?.info?.disbursed_loans?.no_of_loans }}
                                                </div>
                                                <div class="col-lg-3">
                                                    Amount :
                                                </div>
                                                <div class="col-lg-9">
                                                    {{ $helperService.getFormattedCurrency(targetInfo?.info?.disbursed_loans?.amount) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 pt-3">
                                    <div class="card rounded-2" style="border-radius: 10px;">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-3">
                                                    Rejected Application :
                                                </div>
                                                <div class="col-lg-9">
                                                    {{ targetInfo?.info?.rejected_loans?.no_of_loans }}
                                                </div>
                                                <div class="col-lg-3">
                                                    Amount :
                                                </div>
                                                <div class="col-lg-9">
                                                    {{ $helperService.getFormattedCurrency(targetInfo?.info?.rejected_loans?.amount) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 pt-3">
                                    <div class="card rounded-2" style="border-radius: 10px;">
                                        <div class="card-body">
                                                <div class="row">
                                                <div class="col-lg-3">
                                                    Pending Application :
                                                </div>
                                                <div class="col-lg-9">
                                                    {{ targetInfo?.info?.pending_loans?.no_of_loans }}
                                                </div>
                                                <div class="col-lg-3">
                                                    Amount :
                                                </div>
                                                <div class="col-lg-9">
                                                    {{ $helperService.getFormattedCurrency(targetInfo?.info?.pending_loans?.amount) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "BranchTragetInfo",
    components: {
    },

    data() {
        return {
            id: this.$route.params.branch_id,
            targetInfo: null
        };
    },
    mounted() {
        this.getTragetData();
    },
    methods: {
        getTragetData() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "branch/" + this.id + "/target-info",
                })
                .then((res) => {
                    this.targetInfo = res.info;
                })
                .catch(() => { });
        },
    },
};
</script>
  
  