<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="row">
          <div class="container-fluid">
            <div class="worker_serch_warp">
              <div class="row">
                <div class="col-lg-3 mb-2">
                  <div class="search-form position-relative">
                    <input type="text" class="form-control" v-model="filterObj.keyword"
                      v-on:keyup="getList(1)" placeholder="Search" />
                    <div class="input-group-prepend position-absolute">
                      <button class="" type="button" id="button-addon1">
                        <img src="/static/img/search.svg" width="15" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 mb-2" v-if="hasFullAccess">
                      <div class="add_btn_wrapper d-flex flex-row justify-content-end">
                        <Multiselect ref="branch_id_multiselect" name="branch_id" mode="single" trackBy="name"
                          label="name" valueProp="branch_id" placeholder="Select Branch" class="form-control"
                          v-model="filterObj.branch_ids" :options="branchList" searchable="true" />
                      </div>
                </div>
                <div class="col-lg-4 mb-2 ">
                  <a @click="getList(1)" class="btn btn-brand-01 mr-2">Search
                  </a>
                  <a @click="reset()" class="btn btn-brand-03 mr-2">Reset
                  </a>
                </div>
                <div class="col-lg-2 mb-2 text-right">
                  <a @click="addIncenTive()" class="btn btn-brand-02" v-if="access.can_create == 1">+ Add
                  </a>
                </div>
              </div>
            </div>
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <div class="table-responsive">
                  <table class="table table-primary mg-b-0">
                    <thead>
                      <tr>
                        <th scope="col" class="sorting" @click="sorting($event, 'created_at')">
                          Date & Time
                        </th>
                        <th scope="col" class="sorting" @click="sorting($event, 'title')">
                          Title
                        </th>
                        <th scope="col" class="sorting" @click="sorting($event, 'message')">
                          Message
                        </th>
                        <th scope="col" class="sorting" @click="sorting($event, 'message')">
                          Type
                        </th>
                        <th scope="col" class="sorting" @click="sorting($event, 'message')">
                          Added By
                        </th>
                        <th scope="col" class="">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loader">
                        <td colspan="7" class="text-center">
                          <div class="spinner-border spinner-color" role="status">
                          </div>
                        </td>
                      </tr>
                      <tr v-for="item in list" :key="item.id">
                        <td>{{ $helperService.getFormattedDate(item.created_at) }}</td>
                        <td>{{ item.title }}</td>
                        <td>{{ item.message }}</td>
                        <td>{{ $helperService.getTitleCase(item.type) }}</td>
                        <td>{{ item?.added_by?.name }}</td>
                        <td>
                          <a title="Edit" class="mx-2" style="cursor: pointer" @click="editIncenTive(item)"
                            v-if="access.can_update == 1"><img src="/static/img/history.png" alt="" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ErrorComponent @retry="getList(1)" ref="errorComponent" />
                </div>
                <div class="row">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="rolePagination" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal @remove="onDeleteIncenTive" ref="deleteIncenTive" />
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import $ from "jquery";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
export default {
  name: "ManageIncenTive",
  components: {
    Pagination,
    ErrorComponent,
    ConfirmationModal,Multiselect
  },
  data() {
    return {
      loader: false,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      filterObj: {
        keyword: "",
        offset: 1,
        branch_ids:"",
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "",
      },
      delete_id: "",
      statusOption: [{
        "title": "Approved", "value": "APPROVED",
      }, {
        "title": "Pending", "value": "PENDING",
      }, {
        "title": "Rejected", "value": "REJECTED",
      }]
    };
  },
  mounted() {
    this.$storeService.commit("setTitle", "BroadCast");
    this.getList(1);
  },
   computed: {
    branchList() {
      var list = this.$storeService.getters.getAllBranches;
      return list;
    },
    hasFullAccess(){
      return this.$storeService.getters.getHasFullAccess;
    }
  },
  methods: {
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "",
        branch_ids: ""
      }
    },
    onDeleteEvent(item) {
      this.delete_id = item.uuid;
      this.$refs.deleteIncenTive.showModal("Confirmation", "Are you sure you want to delete?", item);
    },
    addIncenTive() {
      this.$router.push("/add-broadcast");
    },
    editIncenTive(item) {
      this.$router.push("/history/" + item.id);
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.rolePagination.itemsPerPage;
      this.getList(1);
    },

    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }

      this.list = [];
      this.loader = true;
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "/notifications",
          _body: this.filterObj,
        })
        .then((res) => {
          if(res.access.can_read == 0){
            return this.$router.go(-1);
          }
          this.loader = false;
          this.list = res.list;
          this.access = res.access;
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(res.count);
              }
            } else {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(0);
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle("Sorry, There is no record found.",true);
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle();
              }
            }
          }
          if (this.$refs.rolePagination) {
            this.$refs.rolePagination.currentPage = this.currentPage;
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";

      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },

    onDeleteIncenTive() {
      var method = "DELETE";
      this.$api
        .webRequest({
          _method: method,
          _action: "incentives/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteIncenTive.closeModal();
            this.getList(1);
            this.$toast.success(res.message, {
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          this.$refs.deleteIncenTive.removeLoader();
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },

  },
};
</script>

