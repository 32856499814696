<template>
  <div class="content-body">
    <div class="worker_serch_warp">
      <div class="row d-flex justify-content-between">
        <div class="col-md-2 pr-2">
          <h2 class="content-title">
            <a @click="$router.go(-1)" style="cursor: pointer"
              ><i class="fas fa-angle-left mr-2"></i
              ><span v-if="!this.id">Add User</span>
              <span v-else>Edit User</span>
            </a>
          </h2>
        </div>
      </div>
    </div>
    <div class="component-section no-code">
      <div class="row-sm wb-row-gap">
        <Form
          @submit="save"
          class="columns column is-multiline is-12"
          data-vv-scope="frmAddUser"
          ref="addEditUser"
        >
          <div class="col-lg-12">
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <div class="row">
                  <div class="col-lg-4 mb-4">
                    <label> Name <span class="text-danger">*</span> </label>
                    <Field
                      name="name"
                      v-model="user.name"
                      class="form-control"
                      rules="required"
                      :validateOnInput="true"
                      type="text"
                      placeholder="Name"
                      autocapitalize="false"
                    />

                    <ErrorMessage name="name" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label> Branch <span class="text-danger">*</span> </label>
                    <Field
                      v-slot="{ field }"
                      name="branch_id"
                      rules="required:branch,true"
                      :validateOnInput="true"
                      v-model="user.branch_id"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="branch_id_multiselect"
                        trackBy="name"
                        rules="required"
                        label="name"
                        valueProp="branch_id"
                        placeholder="Select branch"
                        class="form-control"
                        v-model="user.branch_id"
                        :options="branchList"
                        :close-on-select="true"
                        :createOption="trFue"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="branch_id" class="validation-msg" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-3">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                  @click="$router.go(-1)"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  id="save-btn"
                  class="btn btn-brand-01 wb-save-btn"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div class="row">
        <div class="col-lg-12 mb-2"></div>
      </div>
    </div>
  </div>
</template>
<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Form, Field, ErrorMessage } from "vee-validate";
// import VueDatePicker from '@vuepic/vue-datepicker';
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "LedgerAddUser",
  components: {
    Multiselect,
    Form,
    Field,
    ErrorMessage,
    // VueDatePicker
  },
  data() {
    return {
      id: this.$route.params.id,
      iti_1: undefined,
      user: {
        name: "",
        branch_id: "",
      },
      accesses: [],

      my_user_id: "",
      selectedBranch: null,
      disabled: 1,
    };
  },

  computed: {
    branchList() {
      let list = this.$storeService.getters.getBranches;
      console.log("branch list", list);
      return list;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
  },
  mounted() {
    if(this.id){
        this.getUserInfo();
    }
  },
  methods: {
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    getUserInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "ledger-user/" + this.id,
        })
        .then((res) => {
          this.user = res.info;

          if (res.info.branches) {
            for (var i = 0; i < res.info.branches.length; i++) {
              if (
                this.user_type == "SUPER_ADMIN" ||
                this.user_type == "BRANCH_MANAGER" ||
                this.user_type == "PARTNER" ||
                this.user_type == "BRANCH_STAFF"
              ) {
                this.getRoleList(res.info.branches[i].branch_id);
              }
              console.log("branch_id " + res.info.branches[i].branch_id);
              this.user.branch_id = res.info.branches[i].branch_id;
              if (res.info.branches[i].role_id) {
                console.log("role_id " + res.info.branches[i].role_id);
                this.user.role_id = res.info.branches[i].role_id;
              }
            }
          }
        })
        .catch(() => {});
    },
    
    

    save() {
     
      var obj = {
        name: this.user.name,
        branch_id: this.user.branch_id,
      };

      var method = "POST";
      var action = "/ledger-user";
      if (this.id) {
        method = "PUT";
        action = "ledger-user/" + this.id;
      }
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: obj,
          _buttonId: "save-btn",
          _hide_loader: true,
        })
        .then((res) => {
          this.onClearForm("addEditUser");
          this.$toast.success(res.message, { position: "top-right" });
          this.$router.go(-1);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
  },
};
</script>

<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
