<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="row">
          <div class="container-fluid">
            <ul id="myTab" role="tablist" class="nav nav-tabs worker_tabs">
              <li class="nav-item">
                <a
                  id="tab-1"
                  @click="changeTab('SUMMARY')"
                  data-toggle="tab"
                  href="#tab_1"
                  role="tab"
                  aria-controls="tab_1"
                  aria-selected="true"
                  class="nav-link active"
                  >Summary</a
                >
              </li>
              <li class="nav-item">
                <a
                  id="tab-2"
                  @click="changeTab('DETAILS')"
                  data-toggle="tab"
                  href="#tab_2"
                  role="tab"
                  aria-controls="tab_2"
                  aria-selected="false"
                  class="nav-link"
                  >Details</a
                >
              </li>
            </ul>
            <div id="myTabContent" class="tab-content">
              <div class="worker_serch_warp">
                <div class="row">
                  <div class="col-lg-3 mb-2">
                    <div class="search-form position-relative">
                      <input
                        type="text"
                        class="form-control"
                        v-model="filterObj.keyword"
                        v-on:keyup="getList(1)"
                        placeholder="Search"
                      />
                      <div class="input-group-prepend position-absolute">
                        <button class="" type="button" id="button-addon1">
                          <img src="/static/img/search.svg" width="15" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 mb-2" v-if="hasFullAccess">
                    <div
                      class="add_btn_wrapper d-flex flex-row justify-content-end"
                    >
                      <Multiselect
                        ref="branch_id_multiselect"
                        name="branch_id"
                      @select="afterChangeBranch()"
                        mode="single"
                        trackBy="name"
                        label="name"
                        valueProp="branch_id"
                        placeholder="Select Branch"
                        class="form-control"
                        v-model="filterObj.branch_id"
                        :options="branchList"
                        searchable="true"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3 mb-2">
                    <div
                      class="add_btn_wrapper d-flex flex-row justify-content-end"
                    >
                      <Multiselect
                        ref="verification_user_multiselect"
                        name="verification_user"
                        mode="single"
                        trackBy="name"
                        label="name"
                        valueProp="uuid"
                        placeholder="Select Verification Officer"
                        class="form-control"
                        v-model="verification_user_id"
                        :options="verificationUserList"
                        searchable="true"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3 mb-2">
                  <VueDatePicker
                    placeholder="Select Verification Completed Date Range"
                    :format="'dd/MM/yyyy'"
                    style="height: 46px !important"
                    v-model="verification_completed_at"
                    range
                    :type="boolean"
                    :default="true"
                    :enable-time-picker="false"
                  >
                  </VueDatePicker>
                </div>
                <div class="col-lg-3 mb-2">
                  <VueDatePicker
                    placeholder="Select Cycle Date"
                    :format="'dd/MM/yyyy'"
                    style="height: 46px !important"
                    v-model="cycle_date"
                    :type="boolean"
                    :default="true"
                    :enable-time-picker="false"
                  >
                  </VueDatePicker>
                </div>
                  <div class="col-lg-3 mb-2">
                    <a @click="getList(1)" class="btn btn-brand-01 mr-2"
                      >Search
                    </a>
                    <a @click="reset()" class="btn btn-brand-03 mr-2">Reset </a>
                  </div>
                </div>
              </div>
              <div
                id="tab_1"
                role="tabpanel"
                aria-labelledby="tab-1"
                class="tab-pane fade active show"
              >
                <div class="card rounded-5">
                  
                  <div class="card-body p-3 p-lg-3">
                    <div class="table-responsive">
                      <table class="table table-primary mg-b-0">
                        <thead>
                          <tr>
                            <th scope="col">Branch</th>
                            <th scope="col">Verification Officer</th>
                            <th scope="col">Total Applications</th>
                            <th scope="col">Pending Applications</th>
                            <th scope="col">Completed Applications</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="loader">
                            <td colspan="5" class="text-center">
                              <div
                                class="spinner-border spinner-color"
                                role="status"
                              ></div>
                            </td>
                          </tr>
                          <tr v-for="item in list" :key="item.id">
                            <td>
                              <span v-if="item.branch">{{
                                item?.branch?.name
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>{{ item?.name }}</td>
                            <td>{{ item?.total_applications }}</td>
                            <td>
                              {{ item?.pending_applications }}
                            </td>
                            <td>
                              {{
                                  item?.completed_applications
                              }}
                            </td>
                          
                          </tr>
                        </tbody>
                      </table>
                      <ErrorComponent
                        @retry="changeTab(this.verification_plan_tab)"
                        ref="SUMMARY_ERROR_COMPONENT"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 mt-5 text-center">
                      <div class="page-bottom-pagination text-right">
                        <Pagination
                          @page-change="pageChange"
                          @items-per-page-change="itemsPerPageChange"
                          ref="SUMMARY_PAGINATION"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="tab_2"
                role="tabpanel"
                aria-labelledby="tab-2"
                class="tab-pane fade"
              >
                <div class="card rounded-5">
                  <div class="row">
                    <div class="col-lg-12 text-right pt-3">
                      <a
                      id="export-expenses-id"
                        @click="onExportCollectionPlan()"
                        class="btn btn-brand-01 mr-2"
                        >+ Export
                      </a>
                    </div>
                  </div>
                  <div class="card-body p-3 p-lg-3">
                    <div class="table-responsive">
                      <table class="table table-primary mg-b-0">
                        <thead>
                          <tr>
                            <th scope="col"   class="sorting"
                                @click="
                                  sorting(
                                    $event,
                                    'branches.name'
                                  )
                                ">Branch</th>
                            <th scope="col"   class="sorting"
                                @click="
                                  sorting(
                                    $event,
                                    'loan_applications.application_id'
                                  )
                                ">Loan ID</th>
                            <th scope="col"   class="sorting"
                                @click="
                                  sorting(
                                    $event,
                                    'admin_users.name'
                                  )
                                ">Verification Officer</th>
                            <th scope="col"   class="sorting"
                                @click="
                                  sorting(
                                    $event,
                                    'loan_applications.applicant_name'
                                  )
                                ">Applicant Name</th>
                            <th scope="col"   class="sorting"
                                @click="
                                  sorting(
                                    $event,
                                    'loan_applications.apply_at'
                                  )
                                ">Apply Date</th>
                            <th scope="col"   class="sorting"
                                @click="
                                  sorting(
                                    $event,
                                    'loan_applications.verification_completed_at'
                                  )
                                ">Verification Date</th>
                            <th scope="col">Comment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="loader">
                            <td colspan="7" class="text-center">
                              <div
                                class="spinner-border spinner-color"
                                role="status"
                              ></div>
                            </td>
                          </tr>
                          <tr v-for="item in list" :key="item.id">
                            <td>
                              <span v-if="item.branch">{{
                                item?.branch?.name
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                             {{ item?.application_id }}
                            </td>
                            <td>{{ item?.verification_officer_name }}</td>
                            <td>{{ item?.applicant_name }}</td>
                           <td>{{  $helperService.getFormattedDateOnly(item?.apply_at)}}</td>
                            <td v-if="item?.verification_completed_at != null">
                                {{  $helperService.getFormattedDateOnly(item?.verification_completed_at)}}
                            </td>
                            <td v-else>
                             -
                            </td>
                            <td>{{ item?.narration }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <ErrorComponent
                        @retry="changeTab(this.verification_plan_tab)"
                        ref="DETAILS_ERROR_COMPONENT"
                      />
                    </div>
                    <div class="row">
                      <div class="col-lg-12 mt-5 text-center">
                        <div class="page-bottom-pagination text-right">
                          <Pagination
                            @page-change="pageChange"
                            @items-per-page-change="itemsPerPageChange"
                            ref="DETAILS_PAGINATION"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade wb-modal-wrapper"
      id="onExportModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="onExportModal"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="onCloseExportModal()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>Filter</span>
            </h5>
          </div>
          <Form
            @submit="onExportCollectionPlan()"
            class="columns column is-multiline is-12"
            ref="filterValues"
          >
            <div class="modal-body form-style pb-0 px-lg-5">
              <div class="signin-form">
                <!-- <div class="col-lg-12 mb-4">
                  <div class="form-group">
                    <label>Disbursement Date
                    </label>
                    <Field name="disbursement_id" v-slot="{ feild }" v-model="title" class="form-control">
                      <VueDatePicker placeholder="Select Date Range" v-bind="feild" v-model="disbursement_date" range
                        :type="boolean" :default="true" :format="'dd/MM/yyyy'" :enable-time-picker="false">
                      </VueDatePicker>
                    </Field>
                    <ErrorMessage name="disbursement_id" class="validation-msg" />
                  </div>
                </div> -->
                <div class="col-lg-12 mb-4">
                  <div class="form-group">
                    <label>Due Date </label>
                    <Field
                      name="due_date"
                      v-slot="{ field }"
                      rules="required:cycle date"
                      v-model="due_date"
                      class="form-control"
                    >
                      <VueDatePicker
                        placeholder="Select Due Date"
                        v-bind="field"
                        v-model="due_date"
                        :type="boolean"
                        :default="true"
                        :format="'dd/MM/yyyy'"
                        :enable-time-picker="false"
                      >
                      </VueDatePicker>
                    </Field>
                    <ErrorMessage name="due_date" class="validation-msg" />
                  </div>
                </div>
                <div class="col-lg-12 mb-4">
                  <label> Branch </label>
                  <Field
                    v-slot="{ field }"
                    name="branch_id"
                    :validateOnInput="true"
                    v-model="branch_ids"
                  >
                    <Multiselect
                      v-bind="field"
                      ref="branch_id_multiselect"
                      trackBy="name"
                      rules="required"
                      label="name"
                      valueProp="branch_id"
                      placeholder="select branch"
                      class="form-control"
                      searchable="true"
                      v-model="branch_ids"
                      :options="branchList"
                      :close-on-select="true"
                    />
                  </Field>
                  <ErrorMessage name="branch_id" class="validation-msg" />
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mb-3">
              <button
                type="submit"
                class="btn btn-brand-01 wb-save-btn"
                id="export-expenses"
              >
                Export
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <ConfirmationModal
      @remove="onDeleteAssetManagement"
      ref="deleteAssetManagement"
    />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import $ from "jquery";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "LoanDisbursed",
  components: {
    Pagination,
    ErrorComponent,
    ConfirmationModal,
    Multiselect,
    VueDatePicker,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loader: false,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      filterObj: {
        keyword: "",
        offset: 1,
        cycle_date: "",
        verification_completed_from_date: "",
        verification_completed_to_date: "",
        branch_id: "",
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
      },
      cycle_date : "",
      verification_completed_at:"",
      branch_ids: "",
      exportObj: {
        due_date: "",
      },
      due_date: "",
      verification_plan_tab: "SUMMARY",
      verificationUserList: [],
      verification_user_id: null,
    };
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getAllBranches;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
    hasFilterOption() {
      return this.$storeService.getters.getCollectionPlanObj;
    },
  },
  mounted() {
    this.verification_plan_tab = localStorage.getItem("verification_plan_tab")
      ? localStorage.getItem("verification_plan_tab")
      : "SUMMARY";
    this.$storeService.commit("setTitle", "Verification Plan");
    this.changeTab(this.verification_plan_tab);
    this.getVerificationUserList();

    if (this.hasFilterOption) {
      console.log("The log Value is ", this.hasFilterOption);
      this.filterObj.task_force_level = this.hasFilterOption?.task_force_level
        ? this.hasFilterOption?.task_force_level
        : "";
    }
  },
  methods: {
    changeTab(tab) {
      this.verification_plan_tab = tab;
      localStorage.setItem("verification_plan_tab", tab);
      if (tab == "SUMMARY") {
        window.$("#tab-1").click();
        this.getList(1);
      } else if (tab == "DETAILS") {
        window.$("#tab-2").click();
        this.getList(1);
      } else {
        window.$("#tab-1").click();
        this.getList(1);
      }
    },

    reset() {
      this.filterObj = {
        keyword: "",
        task_force_level: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        branch_id: "",
      };
      this.verification_user_id = null;
      this.getList(1);
    },
    view(item) {
      this.$router.push(
        "/view-emi_payment/" + item.uuid + "/" + item.loan.uuid
      );
    },
    pageChange(page) {
      this.getList(page);
    },
  
    itemsPerPageChange() {
      var tab = this.verification_plan_tab;
      if (tab == "SUMMARY") {
        this.itemsPerPage = this.$refs.SUMMARY_PAGINATION.itemsPerPage;
      }
      if (tab == "DETAILS") {
        this.itemsPerPage = this.$refs.DETAILS_PAGINATION.itemsPerPage;
      }
      this.getList(1);
    },

    setPagination(page, count, listLength, paginationRef, errorCompRef) {
      if (page == 1) {
        if (count !== null && count > 0) {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(count);
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle(
              "Sorry, There is no record found.",
              false
            );
          }
        } else {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(0);
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle(
              "Sorry, There is no record found.",
              true
            );
          }
        }
      }

      if (this.$refs[paginationRef]) {
        this.$refs[paginationRef].currentPage = this.currentPage;
      }
      // var checkValue = true;
      // if (listLength > 0) {
      //   checkValue = false;
      // }
      // if (checkValue) {
      //   if (this.$refs[errorCompRef]) {
      //     this.$refs[errorCompRef].setTitle("Sorry, There is no record found.");
      //   }
      // }

      // console.log("setPagination", listLength, count, checkValue);
    },
    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }
      
      if (this.verification_completed_at) {
        this.filterObj.verification_completed_from_date = moment(String(this.verification_completed_at[0])).format(
          "Y-MM-DD"
        )
        this.filterObj.verification_completed_to_date = moment(String(this.verification_completed_at[1])).format("Y-MM-DD")
      } else {
        this.filterObj.verification_completed_from_date = ""
        this.filterObj.verification_completed_to_date = ""
      }
     
      if (this.cycle_date) {
        this.filterObj.cycle_date =  moment(String(this.cycle_date)).format("Y-MM-DD") ;
      } else {
        this.filterObj.cycle_date = ""
      }

      this.list = [];
      this.loader = true;
      var url = "";
      if (this.verification_plan_tab == "SUMMARY") {
        this.filterObj.verification_user_id = this.verification_user_id;
        url = "verification/summary";
      }
      if (this.verification_plan_tab == "DETAILS") {
        if (this.verification_user_id != null) {
          url = "verifications/" + this.verification_user_id;
        } else {
          url = "verifications";
        }
      }
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: url,
          _body: this.filterObj,
        })
        .then((res) => {
          // if(res.access.can_read == 0){
          //   return this.$router.go(-1);
          // }
          // this.access = res?.access;
          this.loader = false;
          this.list = res.list;
          var tab = this.verification_plan_tab;
          if (tab == "SUMMARY") {
            this.setPagination(
              page,
              res.list.length,
              res.count,
              "SUMMARY_PAGINATION",
              "SUMMARY_ERROR_COMPONENT"
            );
          } else if (tab == "DETAILS") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "DETAILS_PAGINATION",
              "DETAILS_ERROR_COMPONENT"
            );
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";

      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },
    afterChangeBranch() {
      this.getVerificationUserList();
      this.verification_user_id = null;
      this.getList(1);
    },
    getVerificationUserList() {
    

      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/verification-officers",
          _body: { branch_id: this.filterObj.branch_id },
        })
        .then((res) => {
          this.verificationUserList = res.list;
        })
        .catch(() => {});
    },
    onExportModalOption() {
      window.$("#onExportModal").modal("show");
    },
    onCloseExportModal() {
      window.$("#onExportModal").modal("hide");
      this.exportObj.due_date = "";
      this.exportObj.branch_ids = "";
    },

    onExportCollectionPlan() {
      // this.exportObj.branch_ids = this.branch_ids;
      // if (this.due_date) {
      //   this.exportObj.due_date = moment(String(this.due_date)).format(
      //     "Y-MM-DD"
      //   );
      // }
      let url = "export/verifications";
      if (this.verification_plan_tab == "DETAILS") {
        if (this.verification_user_id != null) {
          url = "export/verifications/" + this.verification_user_id;
        }
      }

      this.$api
        .webRequest({
          _method: "GET",
          _action:url,
          _body: this.filterObj,
          _buttonId: "export-expenses-id",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.file_url, res?.file_name);
            this.onCloseExportModal();
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    downloadPdf(url, filename) {
      console.log(url, filename);
      var anchorElement = document.createElement("a");
      anchorElement.href = url;
      anchorElement.download = filename;
      anchorElement.target = "_blank";
      document.body.appendChild(anchorElement);
      console.log(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    },
  },
};
</script>
