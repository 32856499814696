import moment from "moment";
import WebService from "./WebService";
import Store from "./StoreService";

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

class HelperService {
  sorting(sortBy, id) {
    var orderBy = "ASC";
    var className = "sorting_asc";
    var temp = document.getElementById(id);
    if (temp) {
      if (temp.className.includes("sorting_asc")) {
        className = "sorting_desc";
      }
      if (className == "sorting_asc") {
        temp.classList.add("sorting_asc");
        temp.classList.remove("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        temp.classList.add("sorting_desc");
        temp.classList.remove("sorting_asc");
        orderBy = "DESC";
      }
    }
    var obj = {
      order_by: orderBy,
      sort_by: sortBy,
    };
    return obj;
  }

  // getFormattedDate(d) {
  //     return moment(d).format("MMM DD, YYYY  hh:mm A");
  // }

  getFormattedTime(t) {
    return moment(t).format("hh:mm A");
  }

  getFormattedDateOnly(dt) {
    return moment.utc(dt).local().format("MMM DD, YYYY");
    // return moment(dt).format("DD/MM/YYYY hh:mm A");
  }

  getFormattedDate(dt) {
    if (dt == null || dt == "" || dt == undefined) {
      return "";
    }
    return moment.utc(dt).local().format("MMM DD, YYYY  hh:mm A");
    // return moment(dt).format("DD/MM/YYYY hh:mm A");
  }

  sendFormattedDate(d, type) {
    if (type == "date") {
      return moment(d).format("YYYY-MM-DD");
    } else if (type == "month_year") {
      return moment(d).format("YYYY-MM");
    } else if (type == "year") {
      return moment(d).format("YYYY");
    } else {
      return moment(d).format("YYYY-MM-DD");
    }
  }

  allowOnlyNumericValue(e) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }
  }

  convertUnderscore(value) {
    if (!value) {
      return "";
    }

    let pattern = /_/gi;
    let replacement = " ";
    let result = value.replaceAll(pattern, replacement);
    let res = result.toProperCase();

    pattern = / /gi;
    replacement = "-";
    return res.replaceAll(pattern, replacement);
  }

  getTitleCase(value) {
    if (!value) {
      return "";
    }

    const pattern = /_/gi;
    const replacement = " ";
    const result = value.replaceAll(pattern, replacement);
    return result.toProperCase();
  }

  formatNumber(number) {
    if (number == null || number == undefined || number == "") {
      return 0;
    }
    let suffix = "";
    if (number >= 10000000) {
      number /= 10000000;
      suffix = " Cr";
    } else if (number >= 100000) {
      number /= 100000;
      suffix = " Lac";
    } else if (number >= 1000) {
      number /= 1000;
      suffix = "K";
    }

    return number.toFixed(2) + suffix;
  }

  getSummary() {
    WebService.getAPI({
      _action: "summary",
    })
      .then((res) => {
        Store.commit("setSummary", res);
      })
      .catch(() => { });
  }

  getFormattedCurrency(amount) {
    var fractionDigit = 2;
    // if (amount % 1 > 0) {
    //   fractionDigit = 2;
    // }

    var inr = Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: fractionDigit,
    }).format(amount);
    return inr;
  }

  checkValueEmptyOrNot(value) {
    if (value == null) {
      return value ? "" : value;
    }
  }

 maskNumber(number,notes, shouldMask = false) {
    if (shouldMask) {
        if (notes === null || notes === "" ) {
            return number.toString().replace(/\d/g, 'x');
        } else {
            return number;
        }
    } else {
        return number;
    }
}

  DateWithTimeAgo(value) {
    console.log("stage 1 :", value);
    if (value && value != null && value != "") {
      var testDateUtc = moment.utc(value);
      console.log("stage 2 :", testDateUtc);
      var localDate = moment(testDateUtc).local();
      console.log("stage 3 :", localDate);

      var datediff = Math.floor((Date.now() - localDate) / 1000 / 86400);
      let dif = Math.abs(datediff);

      const date1 = new Date(value);
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      console.log(
        "stage -4 :",
        dif,
        diffDays,
        date2.getDate(),
        "date 2 is :",
        date2
      );
      if (dif < diffDays) {
        var date = new Date(localDate),
          diff = (new Date().getTime() - date.getTime()) / 1000,
          daysDiff = Math.floor(diff / 86400);

        let daydiff = Math.abs(daysDiff);

        console.log("stage 5 :", daydiff);
        if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31) return "";
        return (
          (daydiff == 0 &&
            ((diff < 60 && "Just now") ||
              (diff < 120 && "1 minute remaining") ||
              (diff < 3600 && Math.floor(diff / 60) + " minutes remaining") ||
              (diff < 7200 && "1 hour remaining") ||
              (diff < 86400 &&
                Math.floor(diff / 3600) + " hours remaining"))) ||
          (daydiff == 1 && "Yesterday") ||
          (daydiff < 7 && daydiff + " days remaining") ||
          (daydiff < 31 && Math.ceil(daydiff / 7) + " weeks remaining")
        );
      } else {
        console.log("else case");
        return diffDays + "Dasy passed";
      }
    }
    return localDate.format("DD MMM YYYY");
  }

  checkCanApprovePermission(branchId = 0, has_loan_type_changed) {
    if (branchId > 0) {
      var roles = localStorage.getItem("roles");
      roles = JSON.parse(roles);
      if (roles.length > 0) {
        return roles.some((element) => {
          if (element.branch_id === branchId) {
            console.log(element);
            if (
              (element.role_type === "SUPER_ADMIN" ||
                element.role_type === "PARTNER") && element.can_final_approve === 1
            ) {
              console.log(element.role_type);
              return true;
            } else {
              if (has_loan_type_changed === 1 && element.can_final_approve === 1) {
                return false;
              } else {
                return true;
              }
            }
          }
          return false;
        });
      }
    }
    return false;
  }

  getFileExtension(filename) {
    filename = filename || "";
    var file = filename.split("?");
    var ext = /^.+\.([^.]+)$/.exec(file[0]);
    return ext == null ? "" : ext[1];
  }

}

export default new HelperService({});
