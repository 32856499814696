<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="container-fluid px-0">
          <div class="row">
            <div class="col-lg-12 d-flex flex-row justify-content-end">
              <h5
                @click="openFilterOption()"
                style="cursor: pointer"
                title="Filter"
                class="mx-4"
              >
                <img src="static/img/filter.png" width="20" height="20" />
              </h5>
              <h5
                @click="resetFilter()"
                style="cursor: pointer"
                title="Reset Filter"
              >
                <img src="static/img/reset.png" width="20" height="20" />
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-sm-3">
              <div
                class="dsh-box1 text-center"
                style="cursor: pointer"
                @click="$router.push('/loan_disbursed')"
              >
                <img src="static/img/loan.svg" width="50" height="50" />
                <p>Total Sanctioned Amount</p>
                <div class="value">
                  {{ $helperService.getFormattedCurrency(total_disbursed) }}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-3">
              <div
                class="dsh-box2 text-center"
                style="cursor: pointer"
                @click="goToLoanApplication()"
              >
                <img
                  src="static/img/loan-application.svg"
                  width="50"
                  height="50"
                />
                <p>Loan Application</p>
                <div class="value">{{ total_loan_applications }}</div>
              </div>
            </div>
            <!-- <div class="col-lg-3 col-sm-3">
              <div class="dsh-box3 text-center">
                <img src="static/img/schemes.svg" width="50" height="50" />
                <p>Total Loan Due</p>
                <div class="value">
                  {{ $helperService.getFormattedCurrency(total_due_amount) }}
                </div>
              </div>
            </div> -->
            <!-- <div class="col-lg-3 col-sm-3">
              <div class="dsh-box4 text-center" style="cursor:pointer" @click="$router.push('/loan_type')">
                <img src="static/img/networking.svg" width="50" height="50" />
                <p>Loan Type</p>
                <div class="value">{{ total_loan_types }}</div>
              </div>
            </div> -->
            <div class="col-lg-4 col-sm-3">
              <div class="dsh-box4 text-center" style="cursor: pointer">
                <img src="static/img/schemes.svg" width="50" height="50" />
                <p>EMI Collection</p>
                <div class="value">
                  {{ $helperService.getFormattedCurrency(emi_collection) }}
                </div>
              </div>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-lg-12 col-sm-12 mb-2">
              <h3>Analytics</h3>
            </div>

            <div class="col-lg-4 col-sm-4">
              <div class="white-box">
                <h5>Top Branch</h5>
                <!-- <div class="text-center">
                  <img src="static/img/graph1.png" class="img-fluid" />
                </div> -->
                <div class="card-body" id="graph-container">
                  <canvas
                    id="loanBranch"
                    style="cursor: pointer"
                    class="chartjs-render-monitor"
                  ></canvas>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div class="white-box">
                <h5>Loan Category</h5>
                <!-- <div class="text-center">
                  <img src="static/img/graph2.png" class="img-fluid" />
                </div> -->
                <div class="card-body" id="graph-container">
                  <canvas
                    id="loanCategory"
                    style="cursor: pointer"
                    class="chartjs-render-monitor"
                  ></canvas>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div class="white-box">
                <h5>Loan Types</h5>
                <!-- <div class="text-center">
                  <img src="static/img/graph3.png" class="img-fluid" />
                </div> -->
                <div class="card-body" id="graph-container">
                  <canvas
                    id="loanType"
                    style="cursor: pointer"
                    class="chartjs-render-monitor"
                  ></canvas>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-4">
            <div class="col-lg-6 col-sm-6">
              <div class="white-box">
                <h5 class="mb-3">Top Branches</h5>
                <table class="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Loan Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in branchTarget" :key="item.id">
                      <td
                        style="cursor: pointer"
                        @click="moveToTargetInfo(item)"
                        :title="item?.name"
                      >
                        {{ item?.name }} <br />
                        <small># of Loans - {{ item?.no_of_loans }}</small>
                      </td>
                      <td
                        :title="item?.name"
                        class="text-right"
                        style="cursor: pointer"
                        @click="moveToTargetInfo(item)"
                      >
                        {{
                          $helperService.getFormattedCurrency(item?.target) +
                          " / " +
                          $helperService.getFormattedCurrency(
                            item?.achieved_target
                          ) +
                          " (" +
                          item?.collection_percent +
                          "%)"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="white-box collection-perform">
                <div class="d-flex flex-row justify-content-between">
                  <h5 class="mb-3">Collection Performance</h5>
                  <p class="mb-3">
                    {{
                      $helperService.getFormattedDateOnly(collection?.from_date)
                    }}
                    -
                    {{
                      $helperService.getFormattedDateOnly(collection?.end_date)
                    }}
                  </p>
                </div>
                <table class="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Loan Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in collectionPerformances" :key="item.id">
                      <td
                        style="cursor: pointer"
                        :title="item?.task_force_level"
                      >
                        Task Force - {{ item?.task_force_level }} <br />
                        <small
                          ># of Clients - {{ item?.total_applications }}</small
                        >
                      </td>
                      <td
                        :title="item?.name"
                        class="text-right"
                        style="cursor: pointer"
                      >
                        <div class="row">
                          <div class="col">
                            {{
                              $helperService.getFormattedCurrency(
                                item?.total_target_value
                              ) +
                              " / " +
                              $helperService.getFormattedCurrency(
                                item?.total_recovery
                              ) +
                              " (" +
                              item?.recovery_percent +
                              "%)"
                            }}

                            <a href="javascript:void(0)"
                              ><img
                                src="static/img/next-arrow.svg"
                                alt=""
                                @click="moveToCollectionInfo(item)"
                            /></a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade wb-modal-wrapper"
      id="openFilter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="openFilter"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="onCloseFilterModal()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>Filter</span>
            </h5>
          </div>
          <Form
            @submit="getDetail()"
            class="columns column is-multiline is-12"
            ref="filterValues"
          >
            <div class="modal-body form-style pb-0 px-lg-5">
              <div class="signin-form">
                <div class="col-lg-12 mb-4">
                  <div class="form-group">
                    <label>Disbursement Date </label>
                    <Field
                      name="disbursement_id"
                      v-slot="{ feild }"
                      v-model="title"
                      class="form-control"
                    >
                      <VueDatePicker
                        placeholder="Select Date Range"
                        v-bind="feild"
                        v-model="disbursement_date"
                        range
                        :type="boolean"
                        :alt-position="customPosition"
                        :default="true"
                        :format="'dd/MM/yyyy'"
                        :enable-time-picker="false"
                      >
                      </VueDatePicker>
                    </Field>
                    <ErrorMessage
                      name="disbursement_id"
                      class="validation-msg"
                    />
                  </div>
                </div>
                <div class="col-lg-12 mb-4">
                  <div class="form-group">
                    <label>Cycle Date </label>
                    <Field
                      name="cycle_date"
                      v-slot="{ data }"
                      v-model="title"
                      class="form-control"
                    >
                      <VueDatePicker
                        placeholder="Select Date Range"
                        v-bind="data"
                        v-model="cycle_date"
                        range
                        :type="boolean"
                        :alt-position="customPosition"
                        :default="true"
                        :format="'dd/MM/yyyy'"
                        :enable-time-picker="false"
                      >
                      </VueDatePicker>
                    </Field>
                    <ErrorMessage name="cycle_date" class="validation-msg" />
                  </div>
                </div>
                <div class="col-lg-12 mb-4">
                  <label> Branch </label>
                  <Field
                    v-slot="{ field }"
                    name="branch_id"
                    :validateOnInput="true"
                    v-model="branch_ids"
                  >
                    <Multiselect
                      v-bind="field"
                      ref="branch_id_multiselect"
                      trackBy="name"
                      rules="required"
                      label="name"
                      valueProp="branch_id"
                      placeholder="select branch"
                      class="form-control"
                      v-model="branch_ids"
                      :options="branchList"
                      searchable="true"
                      :close-on-select="true"
                    />
                  </Field>
                  <ErrorMessage name="branch_id" class="validation-msg" />
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mb-3">
              <button
                id="cancel-btn"
                @click="resetFilter()"
                type="button"
                class="btn btn-danger btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
              >
                Reset
              </button>
              <button
                type="submit"
                class="btn btn-brand-01 wb-save-btn"
                id="filter-btn"
              >
                Apply
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import redirectUrl from '@/components/Menu.vue'
import Chart from "chart.js/auto";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { Form, Field, ErrorMessage } from "vee-validate";
import router from "../../router";
import moment from "moment";
Chart.register("pie");
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    VueDatePicker,
    Multiselect,
  },
  name: "DashBoard",
  data() {
    return {
      total_loan_types: 0,
      emi_collection: 0,
      total_due_amount: 0,
      total_loan_applications: 0,
      total_disbursed: 0,
      top_branches: [],
      top_loan_categories: [],
      top_loan_types: [],
      loanType: "loanType",
      loanCategory: "loanCategory",
      loanBranch: "loanBranch",
      total_branch_count: [],
      total_branch_title: [],
      total_type_title: [],
      total_type_count: [],
      total_category_count: [],
      total_category_title: [],
      branchChart: null,
      categoryChart: null,
      typeChart: null,
      disbursement_date: "",
      cycle_date: "",
      branch_ids: "",
      filterObj: {
        disbursement_from_date: "",
        disbursement_to_date: "",
        cycle_from_date: "",
        cycle_to_date: "",
        branch_ids: "",
      },
      branchTarget: [],
      collectionPerformances: [],
      collection: {
        from_date: "",
        end_date: "",
      },
      fromFilter: "NONE",
      total_loan_type_amount: [],
      total_category_amount: [],
      total_branch_amount: [],
      access: {
          can_read: 1,
          can_create: 1,
          can_update: 1,
          can_print: 1,
          can_delete: 1,
        },
    };
  },
  mounted() {
    this.$storeService.commit("setTitle", "Dashboard");
    this.getDetail();
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getDashBoardBranches;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
  },
  methods: {
    moveToCollectionInfo(item) {
      let data = {
        task_force_level: item.task_force_level,
      };
      console.log("moveToCollectionInfo  obj", data);
      this.$storeService.commit("setCollectionPlanObj", data);
      this.$router.push("/collection_plan");
    },
    customPosition() {
      return { top: 50, left: 0 };
    },
    goToLoanApplication() {
      this.$router.push("/loan_applications");
      localStorage.setItem("loan_application_active_tab", "ALL");
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    openFilterOption() {
      window.$("#openFilter").modal("show");
    },
    resetFilter() {
      this.branch_ids = "";
      window.$("#openFilter").modal("hide");
      this.filterObj.disbursement_from_date = "";
      this.filterObj.disbursement_to_date = "";
      this.filterObj.cycle_from_date = "";
      this.filterObj.cycle_to_date = "";
      this.filterObj.branch_ids = "";
      this.disbursement_date = "";
      this.cycle_date = "";
      this.onClearForm("filterValues");
      this.getDetail();
    },
    onCloseFilterModal() {
      window.$("#openFilter").modal("hide");
    },
    getDetail() {
      this.branchTarget = [];
      this.collectionPerformances = [];

      this.collection.from_date = "";
      this.collection.end_date = "";

      if (this.disbursement_date) {
        this.filterObj.disbursement_from_date = moment(
          String(this.disbursement_date[0])
        ).format("Y-MM-DD");
        this.filterObj.disbursement_to_date = moment(
          String(this.disbursement_date[1])
        ).format("Y-MM-DD");
      } else {
        this.filterObj.disbursement_to_date = "";
        this.filterObj.disbursement_to_date = "";
      }

      if (this.cycle_date) {
        this.filterObj.cycle_from_date = moment(
          String(this.cycle_date[0])
        ).format("Y-MM-DD");
        this.filterObj.cycle_to_date = moment(
          String(this.cycle_date[1])
        ).format("Y-MM-DD");
      } else {
        this.filterObj.cycle_from_date = "";
        this.filterObj.cycle_to_date = "";
      }
      this.filterObj.branch_ids = this.branch_ids;
      this.$api
        .webRequest({
          _method: "GET",
          _action: "dashboard",
          _body: this.filterObj,
          _buttonId: "filter-btn",
        })
        .then((res) => {
          if(res?.access?.can_read == 0){
            return router.back();
          }
          if(res?.access){
            this.access = res?.access;
          }
          this.branchTarget = res?.data?.branch_targets;
          this.collectionPerformances = res?.data?.collection_performance?.list;
          this.collection.from_date =
            res?.data?.collection_performance?.period_from;
          this.collection.end_date =
            res?.data?.collection_performance?.period_end;
          this.total_loan_types = res?.data?.total_loan_types;
          this.total_due_amount = res?.data?.total_due_amount;
          this.total_loan_applications = res?.data?.total_loan_applications;
          this.emi_collection = res?.data?.emi_collection;
          this.total_disbursed = res?.data?.total_disbursed;
          var loan_types = res?.data?.top_loan_types;
          // console.log("loan_types length >> " + loan_types.length, res?.data?.top_loan_types)
          this.loanTypeList(loan_types);

          var branches = res?.data?.top_branches;
          // console.log("top_branches length >> " + loan_types.length, res?.data?.top_branches)
          this.loanTopBranch(branches);

          var ctagories = res?.data?.top_loan_categories;
          // console.log("top_loan_categories length >> " + loan_types.length, res?.data?.top_loan_categories)
          this.loanCategories(ctagories);

          this.onCloseFilterModal();
        })
        .catch(() => {});
    },
    loanTopBranch(list) {
      this.total_branch_count = [];
      this.total_branch_title = [];
      this.total_branch_percent = [];
      this.total_branch_amount = [];

      let chartStatus = Chart.getChart(this.branchChart);
      console.log("branchChart chartStatus", chartStatus);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      for (var i = 0; i < list.length; i++) {
        let value = {
          value: list[i].count,
          branch_id: list[i].id,
        };
        this.total_branch_count.push(value);
        this.total_branch_title.push(`${list[i].title} (# ${list[i].count} ; ${this.$helperService.getFormattedCurrency(list[i].amount)})`);
        this.total_branch_amount.push(list[i].amount);
      }

      if (document.getElementById(this.loanBranch)) {
        var ctx = document.getElementById(this.loanBranch).getContext("2d");

        this.branchChart = new Chart(ctx, {
          type: "doughnut",
          data: {
            datasets: [
              {
                label: "# of Application",
                data: this.total_branch_count,
                fill: true,
                backgroundColor: [
                  "#bfe6f3",
                  "#4077b3",
                  "#62b2d6",
                  "#7dc7e2",
                  "#aadeee",
                  "#fffaec",
                  "#4077b3",
                  "#ffecfd",
                ],
                hoverOffset: 4,
              },
            ],
            labels: this.total_branch_title,
          },
          options: {
            events: [
              "mousemove",
              "mouseout",
              "click",
              "touchstart",
              "touchmove",
            ],
            onClick: (event, elements) => {
              let obj = elements[0].element.$context.raw;
              let data = {
                branch_id: obj.branch_id,
                application_type: "ALL",
                loan_type_id: "",
              };
              console.log("selected obj", data);
              this.$storeService.commit("setLoanDisbursedObj", data);
              this.$router.push("/loan_disbursed");
            },
            plugins: {
              tooltip: {
                enabled: false, // Disable the default tooltip
                external: function (context) {
                  // Tooltip Element
                  let tooltipEl = document.getElementById("chartjs-tooltip");

                  // Create element on first render
                  if (!tooltipEl) {
                    tooltipEl = document.createElement("div");
                    tooltipEl.id = "chartjs-tooltip";
                    tooltipEl.innerHTML = '<div class="tooltip-content"></div>';
                    document.body.appendChild(tooltipEl);
                  }

                  // Hide if no tooltip
                  const tooltipModel = context.tooltip;
                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                  }

                  // Set caret Position
                  tooltipEl.classList.remove("above", "below", "no-transform");
                  if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                    tooltipEl.classList.add("no-transform");
                  }

                  function getBody(bodyItem) {
                    return bodyItem.lines;
                  }

                  // Set Text
                  if (tooltipModel.body) {
                    const titleLines = tooltipModel.title || [];
                    const bodyLines = tooltipModel.body.map(getBody);

                    let innerHtml = '<div class="tooltip-header">';

                    titleLines.forEach(function (title) {
                      innerHtml += "<div>" + title + "</div>";
                    });
                    innerHtml += '</div><div class="tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                      const colors = tooltipModel.labelColors[i];
                      let style = "background:" + colors.backgroundColor;
                      style += "; border-color:" + colors.borderColor;
                      style += "; border-width: 2px";
                      const span = '<span style="' + style + '"></span>';
                      innerHtml += "<div>" + span + body + "</div>";
                    });
                    innerHtml += "</div>";

                    const tooltipContent =
                      tooltipEl.querySelector(".tooltip-content");
                    tooltipContent.innerHTML = innerHtml;
                  }

                  const position = context.chart.canvas.getBoundingClientRect();
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.position = "absolute";
                  tooltipEl.style.left =
                    position.left +
                    window.pageXOffset +
                    tooltipModel.caretX +
                    "px";
                  tooltipEl.style.top =
                    position.top +
                    window.pageYOffset +
                    tooltipModel.caretY +
                    "px";
                  tooltipEl.style.font = tooltipModel.options.bodyFont.string;
                  tooltipEl.style.padding =
                    tooltipModel.options.padding +
                    "px " +
                    tooltipModel.options.padding +
                    "px";
                  tooltipEl.style.pointerEvents = "none";
                  tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
                  tooltipEl.style.color = "white";
                  tooltipEl.style.borderRadius = "8px";
                  tooltipEl.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.5)";
                  tooltipEl.style.transition = "opacity 0.3s ease";
                },
                callbacks: {
                  label: (tooltipItem) => {
                    let index = tooltipItem.dataIndex;
                    let amount = this.total_branch_amount[index];
                    let count = this.total_branch_count[index].value;
                    return `<div>
                      # of Applications: ${count}<br>
                      Amount:<span class=""> ${this.$helperService.getFormattedCurrency(amount)}</span><br>
                        </div>`;
                  },
                },
              },
            },
          },
        });
      }
    },
    loanCategories(list) {
      this.total_category_count = [];
      this.total_category_title = [];
      this.total_category_amount = [];
      let chartStatus = Chart.getChart(this.categoryChart);
      console.log("categoryChart chartStatus", chartStatus);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      for (var i = 0; i < list.length; i++) {
        var value = {
          value: list[i].count,
          application_type: list[i].type,
        };
        this.total_category_count.push(value);
        this.total_category_title.push(`${list[i].title} (# ${list[i].count} ; ${this.$helperService.getFormattedCurrency(list[i].amount)})`);
        this.total_category_amount.push(list[i].amount);
      }

      if (document.getElementById(this.loanCategory)) {
        var ctx = document.getElementById(this.loanCategory).getContext("2d");

        this.categoryChart = new Chart(ctx, {
          type: "doughnut",
          data: {
            datasets: [
              {
                label: "# of Application",
                data: this.total_category_count,
                fill: true,
                backgroundColor: ["#bfe6f3", "#4077b3"],
                hoverOffset: 4,
              },
            ],
            labels: this.total_category_title,
          },
          options: {
            events: [
              "mousemove",
              "mouseout",
              "click",
              "touchstart",
              "touchmove",
            ],
            onClick: (event, elements) => {
              let obj = elements[0].element.$context.raw;
              let data = {
                branch_id: "",
                application_type: obj.application_type,
                loan_type_id: "",
              };
              console.log("selected obj", data);
              this.$storeService.commit("setLoanDisbursedObj", data);
              this.$router.push("/loan_disbursed");
            },
            plugins: {
              tooltip: {
                enabled: false, // Disable the default tooltip
                external: function (context) {
                  // Tooltip Element
                  let tooltipEl = document.getElementById("chartjs-tooltip");

                  // Create element on first render
                  if (!tooltipEl) {
                    tooltipEl = document.createElement("div");
                    tooltipEl.id = "chartjs-tooltip";
                    tooltipEl.innerHTML = '<div class="tooltip-content"></div>';
                    document.body.appendChild(tooltipEl);
                  }

                  // Hide if no tooltip
                  const tooltipModel = context.tooltip;
                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                  }

                  // Set caret Position
                  tooltipEl.classList.remove("above", "below", "no-transform");
                  if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                    tooltipEl.classList.add("no-transform");
                  }

                  function getBody(bodyItem) {
                    return bodyItem.lines;
                  }

                  // Set Text
                  if (tooltipModel.body) {
                    const titleLines = tooltipModel.title || [];
                    const bodyLines = tooltipModel.body.map(getBody);

                    let innerHtml = '<div class="tooltip-header">';

                    titleLines.forEach(function (title) {
                      innerHtml += "<div>" + title + "</div>";
                    });
                    innerHtml += '</div><div class="tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                      const colors = tooltipModel.labelColors[i];
                      let style = "background:" + colors.backgroundColor;
                      style += "; border-color:" + colors.borderColor;
                      style += "; border-width: 2px";
                      const span = '<span style="' + style + '"></span>';
                      innerHtml += "<div>" + span + body + "</div>";
                    });
                    innerHtml += "</div>";

                    const tooltipContent =
                      tooltipEl.querySelector(".tooltip-content");
                    tooltipContent.innerHTML = innerHtml;
                  }

                  const position = context.chart.canvas.getBoundingClientRect();
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.position = "absolute";
                  tooltipEl.style.left =
                    position.left +
                    window.pageXOffset +
                    tooltipModel.caretX +
                    "px";
                  tooltipEl.style.top =
                    position.top +
                    window.pageYOffset +
                    tooltipModel.caretY +
                    "px";
                  tooltipEl.style.font = tooltipModel.options.bodyFont.string;
                  tooltipEl.style.padding =
                    tooltipModel.options.padding +
                    "px " +
                    tooltipModel.options.padding +
                    "px";
                  tooltipEl.style.pointerEvents = "none";
                  tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
                  tooltipEl.style.color = "white";
                  tooltipEl.style.borderRadius = "8px";
                  tooltipEl.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.5)";
                  tooltipEl.style.transition = "opacity 0.3s ease";
                },
                callbacks: {
                  label: (tooltipItem) => {
                    let index = tooltipItem.dataIndex;
                    let amount = this.total_category_amount[index];
                    let count = this.total_category_count[index].value;
                    return `<div>
                  # of Applications: ${count}<br>
                  Amount:<span class=""> ${this.$helperService.getFormattedCurrency(amount)}</span><br>
                        </div>`;
                  },
                },
              },
            },
          },
        });
      }
    },

    loanTypeList(list) {
      this.total_type_count = [];
      this.total_type_title = [];
      this.total_loan_type_amount = [];

      let chartStatus = Chart.getChart(this.typeChart);
      console.log("loanTypeList chartStatus", chartStatus);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }
      for (var i = 0; i < list.length; i++) {
        var value = {
          value: list[i].count,
          loan_type_id: list[i].id,
        };
        this.total_type_count.push(value);
        
        this.total_type_title.push(`${list[i].title} (# ${list[i].count} ; ${this.$helperService.getFormattedCurrency(list[i].amount)})`);
        this.total_loan_type_amount.push(list[i].amount);
      }

      if (document.getElementById(this.loanType)) {
        var ctx = document.getElementById(this.loanType).getContext("2d");

        this.typeChart = new Chart(ctx, {
          type: "doughnut",
          data: {
            datasets: [
              {
                label: "# of Application",
                data: this.total_type_count,
                fill: true,
                backgroundColor: [
                  "#bfe6f3",
                  "#4077b3",
                  "#62b2d6",
                  "#7dc7e2",
                  "#aadeee",
                  "#fffaec",
                  "#4077b3",
                  "#ffecfd",
                ],
                hoverOffset: 4,
              },
            ],
            labels: this.total_type_title,
          },
          options: {
            events: [
              "mousemove",
              "mouseout",
              "click",
              "touchstart",
              "touchmove",
            ],
            onClick: (event, elements) => {
              let obj = elements[0].element.$context.raw;
              let data = {
                branch_id: "",
                application_type: "ALL",
                loan_type_id: obj.loan_type_id,
              };
              console.log("selected obj", data);
              this.$storeService.commit("setLoanDisbursedObj", data);
              this.$router.push("/loan_disbursed");
            },
            plugins: {
              tooltip: {
                enabled: false, // Disable the default tooltip
                external: function (context) {
                  // Tooltip Element
                  let tooltipEl = document.getElementById("chartjs-tooltip");

                  // Create element on first render
                  if (!tooltipEl) {
                    tooltipEl = document.createElement("div");
                    tooltipEl.id = "chartjs-tooltip";
                    tooltipEl.innerHTML = '<div class="tooltip-content"></div>';
                    document.body.appendChild(tooltipEl);
                  }

                  // Hide if no tooltip
                  const tooltipModel = context.tooltip;
                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                  }

                  // Set caret Position
                  tooltipEl.classList.remove("above", "below", "no-transform");
                  if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                    tooltipEl.classList.add("no-transform");
                  }

                  function getBody(bodyItem) {
                    return bodyItem.lines;
                  }

                  // Set Text
                  if (tooltipModel.body) {
                    const titleLines = tooltipModel.title || [];
                    const bodyLines = tooltipModel.body.map(getBody);

                    let innerHtml = '<div class="tooltip-header">';

                    titleLines.forEach(function (title) {
                      innerHtml += "<div>" + title + "</div>";
                    });
                    innerHtml += '</div><div class="tooltip-body">';

                    bodyLines.forEach(function (body, i) {
                      const colors = tooltipModel.labelColors[i];
                      let style = "background:" + colors.backgroundColor;
                      style += "; border-color:" + colors.borderColor;
                      style += "; border-width: 2px";
                      const span = '<span style="' + style + '"></span>';
                      innerHtml += "<div>" + span + body + "</div>";
                    });
                    innerHtml += "</div>";

                    const tooltipContent =
                      tooltipEl.querySelector(".tooltip-content");
                    tooltipContent.innerHTML = innerHtml;
                  }

                  const position = context.chart.canvas.getBoundingClientRect();
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.position = "absolute";
                  tooltipEl.style.left =
                    position.left +
                    window.pageXOffset +
                    tooltipModel.caretX +
                    "px";
                  tooltipEl.style.top =
                    position.top +
                    window.pageYOffset +
                    tooltipModel.caretY +
                    "px";
                  tooltipEl.style.font = tooltipModel.options.bodyFont.string;
                  tooltipEl.style.padding =
                    tooltipModel.options.padding +
                    "px " +
                    tooltipModel.options.padding +
                    "px";
                  tooltipEl.style.pointerEvents = "none";
                  tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
                  tooltipEl.style.color = "white";
                  tooltipEl.style.borderRadius = "8px";
                  tooltipEl.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.5)";
                  tooltipEl.style.transition = "opacity 0.3s ease";
                },
                callbacks: {
                  label: (tooltipItem) => {
                    let index = tooltipItem.dataIndex;
                    // let title = this.total_type_title[index];
                    let amount = this.total_loan_type_amount[index];
                    let count = this.total_type_count[index].value;
                    return `<div>
                      # of Applications: ${count}<br>
                       Amount:<span class=""> ${this.$helperService.getFormattedCurrency(amount)}</span><br>
                        </div>`;
                  },
                },
              },
            },
          },
        });
      }
    },
    moveToTargetInfo(item) {
      this.$storeService.commit("setTitle", item.name);
      this.$router.push("/traget-info/" + item.uuid);
    },
  },
};
</script>
